import { useEffect, useState } from "react";
import Table from "../../../UI/CommonTable/Table";
// import InputField from "./InternalNote";
import { useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";


const CMSTabs = ({ mid, ChargerHistoryData, chargerinformation ,RevenueData}) => {
  const [activeTab, setActiveTab] = useState(1);

  const SearchBarShow = true;

  const handleTabClick = (tabNumber) => {
    setActiveTab(tabNumber);
  };

  const ProfilePhoto = ({ picUrl }) => {
    return (
      <div>
        <img className="w-12 h-12 rounded-full" src={picUrl} alt="photo" />
      </div>
    );
  };


  const columns_Charger_information = [
   
    {
      header: "S.No.",
      accessor: "serialno",
    },
    {
      header: "Charger Name",
      accessor: "chargername",
    },
    {
      header: "Status",
      accessor: "chargerstatus",
    },
    {
      header: "Used by",
      accessor: "usedby",
    },
    {
      header: "User Address",
      accessor: "useraddress",
    },
    {
      header: "Vehicle",
      accessor: "vehicle",
    },
    {
      header: "Remarks",
      accessor: "remark",
    },
    {
      header: "Action",
      accessor: "action",
    },
  ];
  const pageSize = 5;

  const columns_Revenue = [
    {
      header: "S.No.",
      accessor: "serialno",
    },
    {
      header: "Date",
      accessor: "date",
    },
    {
      header: "Payment ID",
      accessor: "paymentid",
    },
    {
      header: "Charger Name",
      accessor: "chargername",
    },
    {
      header: "User Name",
      accessor: "username",
    },
    {
      header: "Input Amount",
      accessor: "inputamount",
      unit: "₹",
    },
    {
      header: "Charging Time",
      accessor: "chargingtime",
    },
    {
      header: " Used Charging Cost",
      accessor: "chargingCost",
      unit: "₹",
    },
  ];

  const columns_History = [
    {
      header: "S.No.",
      accessor: "serialno",
    },
    {
      header: "Date",
      accessor: "Date",
    },
    {
      header: "Charger Name",
      accessor: "chargername",
    },
    {
      header: "Used by",
      accessor: "usedby",
    },
    {
      header: "Start time",
      accessor: "starttime",
    },
    {
      header: "End time",
      accessor: "endtime",
    },
    {
      header: "Energy used",
      accessor: "enegryused",
      unit: "Wh",
    },
    {
      header: " Used Charging Cost",
      accessor: "chargingCost",
      unit: "₹",
    },
    {
      header: "Input Amount",
      accessor: "inputamount",
      unit: "₹",
    },
  ];

  const blankRowsCount = 3;

  const dataToDisplay = Array.from({ length: blankRowsCount }, (_, index) => {
    return Object.fromEntries(
      columns_History.map(({ accessor }) => [accessor, ""])
    );
  });

  

  const navigate = useNavigate();
  const dispatch = useDispatch();


  const OrderDetails = ({
    oid,
    mid,
    payment_status,
    delivery_status,
    tracking_id,
  }) => {
    const data = {
      payment_status: payment_status,
      oid: oid,
      mid: mid,
      delivery_status: delivery_status,
      tracking_id: tracking_id,
      tab: "crm",
    };
    const viewOrder = () => {
      console.log(oid);
      // dispatch(VeiwOrder(oid))
      navigate("/home/viewOrder", { state: data });
    };
    // console.log(oid)
    return (
      <button
        className="bg-blue-500 px-2 py-2 rounded-l text-white coursor-pointer hover:bg-blue-600"
        onClick={viewOrder}
      >
        View Order
      </button>
    );
  };

  const order_history1 = useSelector(
    (state) => state.userManagement.Order_Table
  );
  const reward_history1 = useSelector(
    (state) => state.userManagement.get_transaction_history_wallet
  );
  // const order_history1 = useSelector((state) => state.userManagement.Reward_Table)

  const data_order = order_history1.map((user) => ({
    pic_url: <ProfilePhoto picUrl={user.pic_url} />,
    product_name: user.oid,
    amount: `S$ ${user.amount}`,
    payment_method: user.payment_mode,
    status: user.payment_status,
    date: user.date,
    action: (
      <OrderDetails
        mid={mid}
        oid={user._id}
        payment_status={user.payment_status}
        delivery_status={user.delivery_status}
        tracking_id={user.tracking_id}
        products={user.products}
      />
    ),
  }));

  const data_reward = reward_history1.map((user) => ({
    oid: user.oid,
    amount: user.amt,
    statusp: user.status,
    date: user.date,
  }));

  const pageSize_order = 4;
  const pageSize_reward = 4;

  return (
    <div className="">
      <div className="mt-4 mb-2">
        <div className="">
          {chargerinformation.length !== 0 ? (
            <>
              <Table
                columns={columns_Charger_information}
                data={chargerinformation}
                pageSize={pageSize}
                SearchBarShow={SearchBarShow}
                tabaleHeading={"Charger Information"}
              />
            </>
          ) : (
            <Table
              columns={columns_Charger_information}
              data={dataToDisplay}
              pageSize={pageSize}
              SearchBarShow={SearchBarShow}
              tabaleHeading={"Charger Information"}
            />
          )}
        </div>
      </div>

      <div className="mt-2 mb-2 ">
        <div className="">
          {RevenueData.length !== 0 ? (
            <>
              <Table
                columns={columns_Revenue}
                data={RevenueData}
                pageSize={pageSize}
                SearchBarShow={SearchBarShow}
                tabaleHeading={"Revenue Management"}
              />
            </>
          ) : (
            <Table
              columns={columns_Revenue}
              data={dataToDisplay}
              pageSize={pageSize}
              SearchBarShow={SearchBarShow}
              tabaleHeading={"Revenue Management"}
            />
          )}
        </div>
      </div>

      {/* )} */}

      {/* {activeTab === 2 && ( */}
      <div className="mt-2 mb-2 ">
        <div className="">
          {ChargerHistoryData.length !== 0 ? (
            <>
              <Table
                columns={columns_History}
                data={ChargerHistoryData}
                pageSize={pageSize}
                SearchBarShow={SearchBarShow}
                tabaleHeading={"Charger History"}
              />
            </>
          ) : (
            <Table
              columns={columns_History}
              data={dataToDisplay}
              pageSize={pageSize}
              SearchBarShow={SearchBarShow}
              tabaleHeading={"Charger History"}
            />
          )}
        </div>
      </div>

      {/* )} */}
      {activeTab === 3 && (
        <div className="mt-2">{/* <InputField mid={mid} /> */}</div>
      )}
    </div>
  );
};

export default CMSTabs;
