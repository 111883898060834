import React, { useEffect, useState } from "react";
import { useNavigate } from 'react-router-dom';
import Card from "../DashBoard_Screen/Component/Card";
import {
  Active,
  Inactive,
  Charger,
  Usersicon,
} from "../DashBoard_Screen/Assets";
import Chart from "../DashBoard_Screen/Component/Chart";
import TopHeader from "../../UI/TopHeader/TopHeader";
import { Dashmap } from "../DashBoard_Screen/Component/Dashmap";
import SearchIcon from "@mui/icons-material/Search";
import Navclose_Header from "../DashBoard_Screen/Component/Navclose_Header";

import { useDispatch, useSelector } from "react-redux";
import {
  GetAllOrderProduct,
  updateCheckMessageReceived,
  // updateData,
} from "../User_Management/features/userSlice";
import mqtt from "mqtt";

const ProductDashboard = ({ setActiveTab, setShowMenuHide, showMenuHide }) => {
  const head = "Product Dashboard";
  setActiveTab("productDashboard");
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [pidDetails, setPidDetails] = useState(null);
  const [newDetails, setNewDetails] = useState({});
  const [mqttClient, setMqttClient] = useState(null);
  const [alldataArraySetOrderPids, setAlldataArrayOrderPids] = useState(true);
  const [CheckMessageReceive, setCheckMessageReceive] = useState(false);
  const [messageReceivedMap, setMessageReceivedMap] = useState({});

  const brokerUrl = "wss://mqtt.jouls.co.in/mqtt";
  const data = [
    { id: "02158", status: "Completed", remarks: "Pending" },
    { id: "02159", status: "In Progress", remarks: "Not Started" },
    { id: "02160", status: "Pending", remarks: "Delayed" },
    { id: "02161", status: "Completed", remarks: "In Review" },
    { id: "02162", status: "In Progress", remarks: "On Track" },
    { id: "02163", status: "Pending", remarks: "Awaiting Approval" },
    { id: "02164", status: "Completed", remarks: "Pending" },
    { id: "02165", status: "In Progress", remarks: "Not Started" },
    { id: "02166", status: "Pending", remarks: "Delayed" },
    { id: "02167", status: "Completed", remarks: "In Review" },
    { id: "02168", status: "Completed", remarks: "Pending" },
    { id: "02169", status: "In Progress", remarks: "On Track" },
    { id: "02170", status: "Pending", remarks: "Awaiting Approval" },
    { id: "02171", status: "Completed", remarks: "Pending" },
    { id: "02172", status: "In Progress", remarks: "Not Started" },
    { id: "02173", status: "Pending", remarks: "Delayed" },
    { id: "02174", status: "Completed", remarks: "In Review" },
    { id: "02175", status: "Completed", remarks: "Pending" },
    { id: "02176", status: "In Progress", remarks: "On Track" },
  ];

  const [search, setserach] = useState();

  useEffect(() => {
    const fetchUserData = async () => {
      await dispatch(GetAllOrderProduct());
    };
    fetchUserData();
  }, [dispatch]);

  const userData = useSelector(
    (state) => state.userManagement.GetAllOrderProduct
  );
  let AllPidData = useSelector(
    (state) => state.userManagement.allMqttMessagesPids
  );
  const CheckMqttMessageReceived = useSelector(
    (state) => state.userManagement.CheckMessageReceived
  );
  const [alldata, setalldata] = useState([
    { pid: "PES-A1B2C3D4E5F6G7H8I9", message: "" },
  ]);
  const [status, setStatus] = useState("");
  // useEffect(() => {
  //   if (search) {
  //     const user = data.find((element) => element.id === search);
  //     if (user) {
  //       console.log(user);
  //       setalldata([user]);
  //     }
  //   } else {
  //     setalldata(data);
  //   }
  // }, [search]);

  console.log(CheckMqttMessageReceived, "CheckMqttMessageReceived");
  const handaleSearch = () => {
    console.log(search);
    const user = data.find((element) => element.id === search);
    if (user === undefined) {
      console.log(user, "no data found");
    } else {
      console.log(user);
      setalldata([user]);
    }
  };

  // useEffect(() => {
  //   if (!search) {
  //     // setalldata(data);
  //     setalldata(OrderPIds)
  //   }
  // }, [search]);

  const handleConnect = () => {
    const client = mqtt.connect(brokerUrl);
    // Connection successful
    client.on("connect", () => {
      console.log("Connected to MQTT broker");

      // Subscribe to PID topic
      if (AllPidData.length > 0) {
        AllPidData.forEach((e) => {
          client.subscribe(e.pid, (err) => {
            if (!err) {
              console.log("radhehello", `Subscribed to ${e.pid}`);
            }
          });
        });
      } else {
        console.log("data nhi hai alldata me ");
      }
      setMqttClient(client); // Save the client in state
    });
  };

  useEffect(() => {
    handleConnect(); // Automatically connect on component mount
  }, [userData]);

  useEffect(() => {
    //
    const applyMqttLogic = (topic, message) => {
      let updatedData = AllPidData.map((obj) => {
        if (obj.pid === topic) {
          // Create a new object with the same properties as obj, but with the updated message
          return {
            ...obj,
            message: message.toString(),
            wifistatus: true,
          };
        }
        return obj;
      });

      // Apply your additional logic if needed

      return updatedData;
    };

    if (mqttClient) {
      let messageReceived = false; // Flag to track if a message is received

      const handleMessage = (topic, message) => {
        console.log("Received message on topic:", topic);
        console.log("Message content:", message.toString());

        try {
          const mqttUpdatedProducts = applyMqttLogic(topic, message);
          console.log(mqttUpdatedProducts, "mqttUpdatedProducts");
          messageReceived = true; // Set the flag to true when a message is received
          // dispatch(updateData(mqttUpdatedProducts));
          dispatch(updateCheckMessageReceived(true));
          setMessageReceivedMap((prevMap) => ({ ...prevMap, [topic]: true }));
          if (!CheckMessageReceive) {
            setCheckMessageReceive(true);
          }
        } catch (error) {
          console.error("Error parsing received message:", error);
        }
      };

      // Remove existing listeners before adding a new one
      // mqttClient.removeListener("message", handleMessage);

      mqttClient.on("message", handleMessage);

      const timeoutId = setTimeout(() => {
        dispatch(updateCheckMessageReceived(false));
      }, 10000);

      return () => {
        clearTimeout(timeoutId); // Clear the timeout to prevent it from triggering after unmount
      };
    }
  }, [mqttClient, status, AllPidData, CheckMqttMessageReceived]);

  useEffect(() => {
    const applyApiLogic = (allProducts) => {
      const ordersWithStatus = [];

      allProducts.forEach((product) => {
        if (
          Array.isArray(product.status) &&
          product.status.length > 0 &&
          product.status.every(
            (item) => typeof item === "object" && "status" in item
          )
        ) {
          product?.products?.forEach((order) => {
            if (order.OrderPids) {
              order.OrderPids.forEach((pid) => {
                // Get the status corresponding to the current product
                const status = product.status[product.status.length - 1].status;
                // Push each pid along with its corresponding status into the array
                ordersWithStatus.push({
                  pid,
                  status,
                  message: status == "commissioning" ? "Wifi Disconnected" : "",
                  wifistatus: status == "commissioning" ? true : false,
                }); // Add an empty message key
              });
            }
          });
        } else {
          product?.products?.forEach((order) => {
            if (order.OrderPids) {
              order.OrderPids.forEach((pid) => {
                // If status is not set, push each pid with no status and an empty message
                ordersWithStatus.push({
                  pid,
                  status: "No Status",
                  message: "",
                  wifistatus: false,
                });
              });
            }
          });
        }
      });

      return ordersWithStatus;
    };
    // Apply API logic on products from the API
    const apiUpdatedProducts = applyApiLogic(userData);
    console.log("apiUpdatedProducts", apiUpdatedProducts);
    // Dispatch the updated products to the Redux store
    // dispatch(updateData(apiUpdatedProducts));
  }, [userData, dispatch]);

  console.log("AllPidData", AllPidData);

  const HandleSearch = (e) => {
    const value = e.target.value;
    console.log("e.target.value", AllPidData);
    const FilterData = AllPidData.filter((e) => e.pid.includes(value));
    console.log("filterdata", FilterData);
  };
  console.log(showMenuHide);
  useEffect(() => {
    if (!CheckMqttMessageReceived) {
      const applyMqttLogic = () => {
        let updatedData = AllPidData.map((obj) => {
          if (obj.wifistatus === true) {
            // Create a new object with the same properties as obj, but with the updated message
            return {
              ...obj,
              message: "wifi Disconnected",
            };
          }
          return obj;
        });

        // Apply your additional logic if needed

        return updatedData;
      };
      const updatedata = applyMqttLogic();
      // dispatch(updateData(updatedata));
    }
  },[CheckMqttMessageReceived])


const handleProductHistory=(pid)=>{
  if(!pid){
    alert("please select pid")
    return
  }
  console.log(pid,"pid123");
  navigate('/home/producthistory', { state: { data: pid} });
}

  return (
    <div
      className="w-full mx-5"
      onClick={() => {
        setShowMenuHide(false);
      }}
    >
      <div className="flex">
        <TopHeader className="" head={head} />
      </div>
      <div className="mt-10 w-full">
        {showMenuHide && <Navclose_Header head={head} />}

        <div className="flex flex-wrap gap-4 justify-center md:justify-between">
          {/* grid md:grid-cols-2 2xl:grid-cols-4 justify-items-center */}
          <div className="mb-4">
            <Card
              title={"123"}
              subtitle={"Total Users"}
              icon={Usersicon}
              color={"bg-[#1807ad]"}
            />
          </div>
          <div className="mb-4">
            <Card
              title={AllPidData.length}
              subtitle={"Total Chargers"}
              icon={Charger}
              color={"bg-[#7307ad]"}
            />
          </div>
          <div className="mb-4">
            <Card
              title={"88"}
              subtitle={"Active Chargers"}
              icon={Active}
              color={"bg-[#7d7880]"}
            />
          </div>
          <div className="mb-4">
            <Card
              title={"74"}
              subtitle={"Inactive Chargers"}
              icon={Inactive}
              color={"bg-[#e0142f]"}
            />
          </div>
        </div>
        <div className="md:flex w-full mt-5 md:gap-8">
          <div className="md:w-full rounded-3xl border border-1 border-[#118615]  bg-[#FFF]">
            <div className="flex justify-between items-center h-16 relative ">
              <div className="ml-20 text-2xl w text-[#118615] font-bold ">
                <p>Overall Positions</p>
              </div>
              <div
                style={{ marginRight: -5, marginTop: -5 }}
                className="flex absolute right-0 top-0 h-12 rounded-2xl py-1 w-40 lg:w-64   bg-[#fff]  border-t-1 border-t-[#fff] border-r-1 border-r-[#fff] border border-1 border-[#118615]"
              >
                <div className="ml-3 h-8 rounded-xl overflow-hidden bg-[#118615]">
                  <input
                    className="pl-3 h-full w-20 lg:w-auto text-sm bg-[#C1E0C2]   outline-none"
                    type="text"
                    // maxLength={5}
                    value={search}
                    onChange={HandleSearch}
                    placeholder="Search Particular"
                  />
                  <span
                    onClick={handaleSearch}
                    className="bg-[#118615] rounded-lg lg:w-10"
                  >
                    <SearchIcon className="text-[#fff] " />
                  </span>
                </div>
              </div>
            </div>

            <div className="max-h-96 border-2 rounded-2xl  overflow-hidden shadow-xl m-5  ">
              <div className="grid grid-cols-3 border-b-2 border-[#C8BFBF] text-center rounded-t-2xl text-[#118615] text-xl bg-[#fff]">
                <span className="p-2">ID</span>
                <span className="p-2 border-x-2 border-[#C8BFBF]">Status</span>
                <span className="p-2">Remarks</span>
              </div>
              <div className="h-80 overflow-auto ">
                {AllPidData.map((item, index) => (
                  <div
                    key={index}
                    className="grid grid-cols-3 text-center bg-[#C1E0C2] text-[#575757] h-8 rounded-xl my-3 mx-5"
                  >
                    <span className="flex justify-center items-center" style={{cursor:"pointer"}} onClick={()=>{handleProductHistory(item.pid)}}>
                      {item.pid}
                    </span>
                    <span className="flex justify-center items-center">
                      {item.wifistatus
                        ? item.message || "comminsiioning"
                        : item.status}
                    </span>
                    <span className="flex justify-center items-center">
                      {item.message}
                    </span>
                  </div>
                ))}
              </div>
            </div>
          </div>
          {/* <div className="md:w-1/2 mt-5 md:mt-0  rounded-xl border border-1 border-[#118615] bg-[#FFF]">
            <div className="w-full py-2 text-[#118615] font-bold text-2xl text-center">
              <h1>Distribution Across India</h1>
              <div className="m-5 border border-1 border-[#EBEBEB] shadow-lg rounded-3xl">
                <Dashmap />
              </div>
            </div>
          </div> */}
        </div>
      </div>
    </div>
  );
};

export default ProductDashboard;

{
  /* <Chart
            heading="Total Active Users"
            fields={[
              {
                name: "Today's active users",
                value: 60,
              },
              {
                name: "Yesterday's active users",
                value: 25,
              },
              {
                name: "Tomorrow's active users",
                value: 10,
              },
              {
                name: "Last Month's active users",
                value: 10,
              },
            ]}
          />
          <Chart
            heading="Total Active bookings"
            fields={[
              {
                name: "Today's active bookings",
                value: 60,
              },
              {
                name: "Yesterday's active bookings",
                value: 25,
              },
              {
                name: "Tomorrow's active bookings",
                value: 10,
              },
              {
                name: "Last Month's active bookings",
                value: 10,
              },
            ]}
          /> */
}
