import React, { useState } from 'react';
import jsPDF from 'jspdf';

const YourComponent = () => {
  const [inputData, setInputData] = useState('');

  const handleInputChange = (e) => {
    setInputData(e.target.value);
  };

  const generatePDF = () => {
    const doc = new jsPDF();

    // Populate the PDF with input data
    doc.text(`Input Data: ${inputData}`, 10, 10);
    doc.text("radhe: kingAjmer", 100, 10);

    // Save the PDF and trigger download
    doc.save('your_document.pdf');
  };

  return (
    <div>
      <label>
        Input Data:
        <input type="text" value={inputData} onChange={handleInputChange} />
      </label>
      <button onClick={generatePDF} disabled={!inputData}>
        Generate PDF
      </button>
    </div>
  );
};

export default YourComponent;
