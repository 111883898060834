import React, { useEffect, useState } from "react";
import { useLocation } from "react-router-dom";
import LineChart from "./linechart"; // Import the LineChart component
import GraphComponent from "./chart"; // Import the GraphComponent component
import TopHeader from "../../UI/TopHeader/TopHeader";
import { Line, Bar } from "react-chartjs-2";
import SearchIcon from "@mui/icons-material/Search";
// import searchicon from "./Assets/search.png";
import Navclose_Header from "../DashBoard_Screen/Component/Navclose_Header";

import {
  FindChargerHistoryByPid,
  FindOrderByPid,
  UpdatePidDataUsedBy,
} from "../User_Management/features/userSlice";
import { useDispatch, useSelector } from "react-redux";
import { RadioButtonChecked } from "@material-ui/icons";
import Table from "../../UI/CommonTable/Table";

const Producthistory = ({
  setActiveTab,
  setShowMenuHide,
  showMenuHide,
  setExpand,
}) => {
  const location = useLocation();
  const dispatch = useDispatch();
  console.log("datainproducthistory", location);
  const head = "product History";
  setActiveTab("productHistory");
  setExpand("Livedashboard");
  const [search, setSearch] = useState("");
  console.log("serach", search);
  const [inputValue, setInputValue] = useState("");
  useEffect(() => {
    const fetchUserData = async () => {
      if (search) {
        console.log("serach", search);
        await dispatch(FindOrderByPid(search));
        await dispatch(FindChargerHistoryByPid(search));
      }
    };
    fetchUserData();
  }, [dispatch, search]);
  useEffect(() => {
    const fetchUserData = async () => {
      if (location.state && !search) {
        console.log("location.state", location.state);
        setSearch(location.state.data);
      }
    };
    fetchUserData();
  }, [location]);

  const FindOrderByPidData = useSelector(
    (state) => state.userManagement.FindOrderByPid
  );
  const FindChargerHistoryByPidData = useSelector(
    (state) => state.userManagement.FindChargerHistoryByPid
  );
  const AllPidWithUsedByData = useSelector(
    (state) => state.userManagement.AllPidDataWithUserBy
  );
  console.log(
    "FindOrderByPidData",
    FindOrderByPidData,
    FindChargerHistoryByPidData
  );
  console.log("AllPidWithUsedByData", AllPidWithUsedByData);
  const handleSearchChange = (event) => {
    setInputValue(event.target.value);
  };
  const [products, setProducts] = useState({});

  console.log("products", products);
  useEffect(() => {
    const fetchProducts = async () => {
      console.log("FindOrderByPidDatain elsee", typeof FindOrderByPidData);
      if (Array.isArray(FindOrderByPidData)) {
        // `FindOrderByPidData` is an array
        console.log("no order found in history");
        setProducts({});
        return;
      }
      if (
        typeof FindOrderByPidData === "object" &&
        FindOrderByPidData !== null
      ) {
        if (FindOrderByPidData.OrderData) {
          if (search) {
            const Data = findProductById(FindOrderByPidData.OrderData, search);
            console.log("datainusereffectfetch", Data);
            setProducts(Data);
          }
        }
        // `FindOrderByPidData` ek object hai aur null nahi hai
      }
    };

    fetchProducts();
  }, [FindOrderByPidData, search]);

  useEffect(() => {
    if (Array.isArray(FindChargerHistoryByPidData)) {
      dispatch(UpdatePidDataUsedBy([]));
      return;
    }
    if (
      typeof FindChargerHistoryByPidData === "object" &&
      FindChargerHistoryByPidData !== null
    ) {
      if (FindChargerHistoryByPidData.data) {
        dispatch(UpdatePidDataUsedBy(FindChargerHistoryByPidData.data));
      }
    }
    // `FindOrderByPidData` ek object hai aur null nahi hai
  }, [FindChargerHistoryByPidData, search]);

  const findProductById = (data, pid) => {
    const result = { ...data };
    result.productname = "Not available";

    if (data.products) {
      const matchingProduct = data.products.find(
        (item) => item.OrderPids && item.OrderPids.includes(pid)
      );
      if (matchingProduct) {
        result.productname = matchingProduct.name;
      }
    }

    if (Array.isArray(data.status)) {
      const matchingStatus = data.status.find(
        (item) => item.status && item.status.toLowerCase() === "commissioning"
      );
      console.log("matchingStatus", data);
      result.date_of_commissioning = matchingStatus
        ? matchingStatus.date
        : "xx/xx/xx";
    }

    return result;
  };
  const lineChartData = {
    labels: ["January", "February", "March", "April", "May", "June", "July"],
    datasets: [
      {
        label: "Sales",
        data: [65, 59, 80, 81, 56, 55, 40],
        fill: false,
        backgroundColor: "rgba(75,192,192,0.2)",
        borderColor: "rgba(75,192,192,1)",
      },
    ],
  };

  // Define chart options for Line Chart
  const lineChartOptions = {
    responsive: true,
    maintainAspectRatio: false,
    scales: {
      x: {
        grid: {
          display: false,
        },
      },
      y: {
        grid: {
          display: false,
        },
      },
    },
  };

  // Define chart data for Bar Chart
  const barChartData = {
    labels: ["Red", "Blue", "Yellow", "Green", "Purple", "Orange"],
    datasets: [
      {
        label: "Quantity",
        data: [12, 19, 3, 5, 2, 3],
        backgroundColor: [
          "rgba(255, 99, 132, 0.2)",
          "rgba(54, 162, 235, 0.2)",
          "rgba(255, 206, 86, 0.2)",
          "rgba(75, 192, 192, 0.2)",
          "rgba(153, 102, 255, 0.2)",
          "rgba(255, 159, 64, 0.2)",
        ],
        borderColor: [
          "rgba(255, 99, 132, 1)",
          "rgba(54, 162, 235, 1)",
          "rgba(255, 206, 86, 1)",
          "rgba(75, 192, 192, 1)",
          "rgba(153, 102, 255, 1)",
          "rgba(255, 159, 64, 1)",
        ],
        borderWidth: 1,
      },
    ],
  };

  // Define chart options for Bar Chart
  const barChartOptions = {
    indexAxis: "y",
    responsive: true,
    maintainAspectRatio: false,
  };
  const handleSearchClick = () => {
    if (!inputValue) {
      alert("please input search first");
      return;
    }
    setSearch(inputValue);
    console.log("click hua search icon");
  };

  const columns = [
    {
      header: "S.No.",
      accessor: "serialno",
    },
    {
      header: "Date",
      accessor: "date",
    },
    {
      header: "Charger Name",
      accessor: "chargername",
    },
    {
      header: "Used by",
      accessor: "usedby",
    },
    {
      header: "Start time",
      accessor: "starttime",
    },
    {
      header: "End time",
      accessor: "endtime",
    },
    {
      header: "Energy used",
      accessor: "enegryused",
    },
    {
      header: "Payment",
      accessor: "payment",
    },
  ];

  const SearchBarShow = true;
  const blankRowsCount = 3;

  const dataToDisplay = Array.from({ length: blankRowsCount }, (_, index) => {
    return Object.fromEntries(columns.map(({ accessor }) => [accessor, ""]));
  });

  let count = 1;
  const Tabledata = AllPidWithUsedByData.map((user) => {
    return {
      serialno: count++,
      date: user.Date,
      chargername: user.ChargerName,
      usedby: user.UsedBy, // Assigning the array of matched values to 'usedby'
      starttime: user.StartTime || "Not Mentioned",
      endtime: user.EndTime || "Not Mentioned",
      enegryused: user.EnergyUsed || "Not Mentioned",
      payment: user.payment || "Not Mentioned",
    };
  });
  console.log(Tabledata, "Tabledata");
  return (
    <div
      className="w-full px-5"
      onClick={() => {
        setShowMenuHide(false);
      }}
    >
      <div className="flex">
        <TopHeader className="" head={head} />
      </div>
      <div className="w-full mt-10 ">
        {showMenuHide && <Navclose_Header head={head} />}
      </div>
      <div className="flex justify-between items-center w-1/3 mt-9 mb-9">
        <input
          type="text"
          placeholder="Search"
          className="shadow-md border-gray-100 border-2 rounded-md py-3 pl-5 pr-10 w-full"
          value={inputValue}
          onChange={handleSearchChange}
        />
        <span className="relative text-gray-600 right-10 pointer-events-auto">
          <SearchIcon
            color="disabled"
            fontSize="large"
            onClick={() => {
              handleSearchClick();
            }}
          />
        </span>
      </div>
      {/* <div className="flex items-center mt-9 mb-9">
        <input
          type="text"
          placeholder="Search..."
          value={inputValue}
          onChange={handleSearchChange}
          className="px-9 py-1 border border-gray-300 rounded-md focus:outline-none focus:border-blue-500"
        />
        <div>
          <SearchIcon
            onClick={() => {
              handleSearchClick();
            }}
          />
        </div>
      </div> */}
      <div className="">
        <div className="grid grid-cols gap-1">
          {products && ( // Null check
            <>
              {/* <div className="p-5 bg-stone-200">
                <p className="text-xl text-gray-900 mb-1">
                  Product Information
                </p>
                <hr className="bg-gray-400 rounded-sm h-0.5 mb-2" />
                <div className="my-2">
                  <div className=" gap-2 ">
                    <div className="flex flex-row items-center justify-between mb-2">
                      <label className="mb-2">Product name:</label>
                      <input
                        className="px-4 py-2 drop-shadow-md rounded-md w-[75%]"
                        type="text"
                        placeholder="Enter Product name"
                      />
                    </div>
                    <div className="flex flex-row items-center justify-between mb-2">
                      <label className="me-1 flex-shrink-0">Category:</label>
                      <input
                        className="px-4 py-2 drop-shadow-md rounded-md w-[75%]"
                        type="text"
                        placeholder="Enter Category"
                      />
                    </div>
                    <div className="flex flex-row items-center justify-between mb-2">
                      <label className="me-1 flex-shrink-0">Subcategory:</label>
                      <input
                        className="px-4 py-2 drop-shadow-md rounded-md w-[75%]"
                        type="text"
                        placeholder="Enter Subcategory"
                      />
                    </div>
                    <div className="flex flex-row items-center justify-between mb-2">
                      <label className="me-1 flex-shrink-0">
                        Serial Number:
                      </label>
                      <input
                        className="px-4 py-2 drop-shadow-md rounded-md w-[75%]"
                        type="text"
                        placeholder="Enter Serial Number"
                      />
                    </div>

                    <div className="flex flex-row items-center justify-between mb-2">
                      <label className="me-1 flex-shrink-0">Sold To:</label>
                      <input
                        className="px-4 py-2 drop-shadow-md rounded-md w-[75%]"
                        type="text"
                        placeholder="Enter Sold To"
                      />
                    </div>
                    <div className="flex flex-row items-center justify-between mb-2">
                      <label className="me-1 flex-shrink-0">Sold Date:</label>
                      <input
                        className="px-4 py-2 drop-shadow-md rounded-md w-[75%]"
                        type="tel"
                        placeholder="Enter Sold Date"
                      />
                    </div>
                    <div className="flex flex-row items-center justify-between mb-2">
                      <label className="me-1 flex-shrink-0">
                        Installed At:
                      </label>
                      <input
                        className="px-4 py-2 drop-shadow-md rounded-md w-[75%]"
                        type="tel"
                        placeholder="Enter Installed At"
                      />
                    </div>
                    <div className="flex flex-row items-center justify-between mb-2">
                      <label className="me-1 flex-shrink-0">
                        Date of Commissioning:
                      </label>
                      <input
                        className="px-4 py-2 drop-shadow-md rounded-md w-[75%]"
                        type="tel"
                        placeholder="Enter Commissioning Date"
                      />
                    </div>
                    <div className="flex flex-row items-center justify-between mb-2">
                      <label className="me-1 flex-shrink-0">
                        Commissioned By:
                      </label>
                      <input
                        className="px-4 py-2 drop-shadow-md rounded-md w-[75%]"
                        type="tel"
                        placeholder="Enter Commissioned Byr"
                      />
                    </div>
                  </div>
                </div>
              </div> */}

              <div className="p-5 bg-stone-200">
                <p className="text-xl text-gray-900 mb-1">
                  Product Information
                </p>
                <hr className="bg-gray-400 rounded-sm h-0.5 mb-2" />
                <div className="my-2 text-[14px]">
                  <div className="grid grid-cols-1 md:grid-cols-2 gap-4">
                    <div className="flex flex-row items-center justify-between mb-2">
                      <label className="mb-2 md:mb-0">Product name:</label>
                      <input
                        className="px-4 py-2 drop-shadow-md rounded-md w-full md:w-[75%]"
                        type="text"
                        placeholder="Enter Product name"
                      />
                    </div>
                    <div className="flex flex-row items-center justify-between mb-2">
                      <label className="mb-2 md:mb-0">Category:</label>
                      <input
                        className="px-4 py-2 drop-shadow-md rounded-md w-full md:w-[75%]"
                        type="text"
                        placeholder="Enter Category"
                      />
                    </div>
                    <div className="flex flex-row items-center justify-between mb-2">
                      <label className="mb-2 md:mb-0">Subcategory:</label>
                      <input
                        className="px-4 py-2 drop-shadow-md rounded-md w-full md:w-[75%]"
                        type="text"
                        placeholder="Enter Subcategory"
                      />
                    </div>
                    <div className="flex flex-row items-center justify-between mb-2">
                      <label className="mb-2 md:mb-0">Serial Number:</label>
                      <input
                        className="px-4 py-2 drop-shadow-md rounded-md w-full md:w-[75%]"
                        type="text"
                        placeholder="Enter Serial Number"
                      />
                    </div>
                    <div className="flex flex-row items-center justify-between mb-2">
                      <label className="mb-2 md:mb-0">Sold To:</label>
                      <input
                        className="px-4 py-2 drop-shadow-md rounded-md w-full md:w-[75%]"
                        type="text"
                        placeholder="Enter Sold To"
                      />
                    </div>
                    <div className="flex flex-row items-center justify-between mb-2">
                      <label className="mb-2 md:mb-0">Sold Date:</label>
                      <input
                        className="px-4 py-2 drop-shadow-md rounded-md w-full md:w-[75%]"
                        type="tel"
                        placeholder="Enter Sold Date"
                      />
                    </div>
                    <div className="flex flex-row items-center justify-between mb-2">
                      <label className="mb-2 md:mb-0">Installed At:</label>
                      <input
                        className="px-4 py-2 drop-shadow-md rounded-md w-full md:w-[75%]"
                        type="tel"
                        placeholder="Enter Installed At"
                      />
                    </div>
                    <div className="flex flex-row items-center justify-between mb-2">
                      <label className="mb-2 md:mb-0">
                        Date of Commissioning:
                      </label>
                      <input
                        className="px-4 py-2 drop-shadow-md rounded-md w-full md:w-[75%]"
                        type="tel"
                        placeholder="Enter Commissioning Date"
                      />
                    </div>
                    <div className="flex flex-row items-center justify-between mb-2">
                      <label className="mb-2 md:mb-0">Commissioned By:</label>
                      <input
                        className="px-4 py-2 drop-shadow-md rounded-md w-full md:w-[75%]"
                        type="tel"
                        placeholder="Enter Commissioned By"
                      />
                    </div>
                  </div>
                </div>
              </div>

              {/* ///////////////////// */}
              {/* <div key={products.id} className="border p-4 rounded shadow">
                <p>
                  <strong>Product Name:</strong>{" "}
                  {products.productname || "Not available"}
                </p>
                <p>
                  <strong>Category:</strong>{" "}
                  {products.category || "Not available"}
                </p>
                <p>
                  <strong>Subcategory:</strong>
                  {products.productname === "ecospark"
                    ? "Four Wheeler"
                    : products.productname
                    ? "Two wheeler"
                    : "Not available"}
                </p>
                <p>
                  <strong>Serial Number:</strong>{" "}
                  {products.serial_number || "Not available"}
                </p>
                <p>
                  <strong>Sold To:</strong>{" "}
                  {products.buyerName || "Not available"}
                </p>
                <p>
                  <strong>Sold Date:</strong> {products.date || "Not available"}
                </p>
                <p>
                  <strong>Installed At:</strong>{" "}
                  {products.address || "Not available"}
                </p>
                <p>
                  <strong>Date of Commissioning:</strong>{" "}
                  {products.date_of_commissioning || "Not available"}
                </p>
                <p>
                  <strong>Commissioned By:</strong>{" "}
                  {products.commissioned_by || "Not available"}
                </p>
              </div> */}
            </>
          )}
        </div>
      </div>

      <div className="mt-3">
        {Tabledata.length !== 0 ? (
          <>
            <Table
              columns={columns}
              data={Tabledata}
              pageSize={5}
              SearchBarShow={SearchBarShow}
              tabaleHeading={"History"}
            />
          </>
        ) : (
          <Table
            columns={columns}
            data={dataToDisplay}
            // pageSize={5}
            SearchBarShow={SearchBarShow}
            tabaleHeading={"History"}
          />
        )}
      </div>

      <div
        className={`${
          showMenuHide && "hidden"
        } rounded-xl border border-1 border-[#118615] bg-[#FFF] my-5 overflow-hidden`}
      >
        <div className="flex flex-col lg:flex-row items-center p-2">
          {/* lg:flex-row */}
          <div className="w-full lg:w-1/2">
            <LineChart />
          </div>
          <div className="lg:w-1/2">
            <GraphComponent />
          </div>
        </div>
      </div>
    </div>
  );
};

export default Producthistory;
