import { useEffect, useState } from "react";
import Table from "../../../UI/CommonTable/Table";

const Tabs = ({ mid ,AppUserData}) => {
  const [activeTab, setActiveTab] = useState(1);

 
  const columns_order = [
    {
      header: "S.No.",
      accessor: "serialno",
    },
    {
      header: "Date",
      accessor: "date",
    },
    {
      header: "Payment Id",
      accessor: "paymentid",
    },
    {
      header: "Charger Name",
      accessor: "chargername",
    },
    {
      header: "input amount",
      accessor: "inputamount",
    },
    {
      header: "Start Time",
      accessor: "starttime",
    },
    {
      header: "End Time",
      accessor: "endtime",
    },
    {
      header: "Charging Time",
      accessor: "chargingtime",
    },
    {
      header: "Used Charging Cost",
      accessor: "charingcost",
    },
    {
      header: "Used Energy",
      accessor: "energy",
    },
  ];
  const pageSize = 5;
 

  const data_order = []




  return (
    <div className="">
        <div className="mt-4 mb-10 ">
          <div>
            <Table
              columns={columns_order}
              data={AppUserData}
              pageSize={pageSize}
            />
          </div>
        </div>
      
    
    </div>
  );
};

export default Tabs;
