import React, { useEffect, useState } from "react";
import FavoriteRoundedIcon from "@mui/icons-material/FavoriteRounded";
import StarBorderRoundedIcon from "@mui/icons-material/StarBorderRounded";
import TopHeader from "../../../UI/TopHeader/TopHeader";
import { useDispatch, useSelector } from "react-redux";
import { useLoaderData, useLocation, useParams } from "react-router-dom";
// import Tabs from "./Tabs";
import Modal from "@mui/material/Modal";
import { useNavigate } from "react-router-dom";
import {
  VeiwMember,
  Order_Table,
  Internal_Notes,
  Reward_Table,
  getUserDetails,
  UpdateUserPidsMessagesData,
} from "../../User_Management/features/userSlice";
import { TextField, Button } from "@mui/material";
import { topUp } from "../../User_Management/features/userSlice";
import { getUserLogin } from "../../User_Management/features/userSlice";
import { tssurl, url1 } from "../../../UI/port";
import axios from "axios";
import { Grid } from "react-loader-spinner";
import { url3 } from "../../../UI/port";
import CMSTabs from "./CMSTabs";
import CMSDetailsCard from "./CMSDetailsCard";
import Navclose_Header from "../../DashBoard_Screen/Component/Navclose_Header";
import {
  applyApiLogic,
  applyMqttLogic,
  formatToThreeDecimalPlaces,
  getLastAppUsernameByPid,
  handleConnect,
} from "../../../utility";
import {
  calculateAmountSum,
  calculateEnergySum,
  calculateTimeDifference,
  calculateTimeDifferenceStartTimeEndTime,
} from "../../calculations/Totalenergy";

const CMSUserDetails = ({
  setActiveTab,
  setExpand,
  setsubMenu,
  setShowMenuHide,
  showMenuHide,
}) => {
  setExpand("customerRelationship");
  setActiveTab("CMSUsers");
  setsubMenu("/User Details");
  const head = "User Details";
  let userDetailsData = useSelector(
    (state) => state.userManagement.getUserDetails
  );
  const getAllChargerHistoryData = useSelector(
    (state) => state.userManagement.getUserDetails?.ChargerHistory
  );
  // const userBankDetailsData = useSelector((state) => state.userManagement.getAllUserBankDetails);
  const userChargerHistoryData = useSelector(
    (state) => state.userManagement.getAllUserChargerHistory
  );
  const userPidsData = useSelector(
    (state) => state.userManagement.getAllUserPids
  );
  let userAllPidDataWithMessages = useSelector(
    (state) => state.userManagement.UserallPidsMessages
  );
  console.log("userPidsData", userPidsData);
  const { mid } = useParams();
  const location = useLocation();
  const state = location?.state;
  console.log("state", state);
  const dispatch = useDispatch();
  const [isLoading, setIsLoading] = useState(true);
  const [userInformation, setUserInformation] = useState({
    uid: "",
    name: "",
    username: "",
    phoneNumber: "",
    address: "",
  });
  const [userBankDetails, setUserBankDetails] = useState({
    HolderName: "",
    bankName: "",
    AcNumber: "",
    IfscCode: "",
    phoneNumber: "",
    settlementTerms: "",
  });
  const [ChangeData, setChangeData] = useState([]);
  const [RevenueData, setRevenueData] = useState([]);

  const [mqttClient, setMqttClient] = useState(null);

  const [ShowActiveDevices, setShowActiveDevices] = useState(0);
  const [ShowIdleDevices, setShowIdleDevices] = useState(0);
  console.log("ShowIdleDevicesShowIdleDevices", ShowIdleDevices);
  const [ShowTotalEnergy, setShowTotalEnergy] = useState(0);
  console.log(ShowTotalEnergy, "sdljdvksjvn");
  const [ShowTotalUtilization, setShowTotalUtilization] = useState(0);
  const [ShowTotalRevenue, setShowTotalRevenue] = useState(0);
  const [CheckmqttMessagesReceived, setCheckmqttMessagesReceived] = useState(
    {}
  );

  useEffect(() => {
    if (!getAllChargerHistoryData || !getAllChargerHistoryData.length) {
      return;
    }
    const data = calculateEnergySum(getAllChargerHistoryData);
    const dataUtiliazation = calculateTimeDifference(getAllChargerHistoryData);
    const dataRevenue = calculateAmountSum(getAllChargerHistoryData);
    setShowTotalRevenue(dataRevenue);
    setShowTotalEnergy(data);
    setShowTotalUtilization(dataUtiliazation);
    getRevenue();
  }, [getAllChargerHistoryData]);
  const [
    CheckmqttMessagesReceivedCounter,
    setCheckmqttMessagesReceivedCounter,
  ] = useState({});

  useEffect(() => {
    fetchData();
  }, [dispatch, state]);
  const fetchData = async (pid) => {
    setIsLoading(true);
    try {
      const response = await dispatch(
        getUserDetails({
          uid: state,
          populateBankDetails: true,
          populateChargerHistoryData: true,
          populatePidsDetails: true,
        })
      );
      console.log(response, "responseresponse");
      setIsLoading(false);
      if (response.payload && pid) {
        return getLastAppUsernameByPid(response.payload.ChargerHistory, pid);
      }
    } catch (error) {
      console.error("Error fetching data:", error);
      setIsLoading(false);
    }
  };

  const handleInputChange = (event) => {
    const { name, value } = event.target;
    setUserInformation({
      ...userInformation,
      [name]: value,
    });
  };

  const handleBankDetailsChange = (event) => {
    const { name, value } = event.target;
    setUserBankDetails({
      ...userBankDetails,
      [name]: value,
    });
  };

  useEffect(() => {
    if (
      !isLoading &&
      userDetailsData &&
      Object.values(userDetailsData).some((value) => value !== "")
    ) {
      setUserInformation((prev) => ({
        ...prev,
        uid: userDetailsData.uid || prev.uid,
        name: userDetailsData.name || prev.name,
        username: userDetailsData.username || prev.username,
        phoneNumber: userDetailsData.phoneNumber || prev.phoneNumber,
        address: userDetailsData.address || prev.address,
      }));
    }
    if (
      !isLoading &&
      userDetailsData &&
      userDetailsData.bankDetails &&
      userDetailsData.bankDetails.length > 0
    ) {
      const Bankdetail = userDetailsData.bankDetails[0];
      setUserBankDetails((prev) => ({
        ...prev,
        HolderName: Bankdetail.accountHolderName || prev.HolderName,
        bankName: Bankdetail.bankName || prev.bankName,
        AcNumber: Bankdetail.accountNumber || prev.AcNumber,
        IfscCode: Bankdetail.ifscCode || prev.IfscCode,
        phoneNumber: Bankdetail.phoneNumber || prev.phoneNumber,
        settlementTerms: Bankdetail.settlementTerms || prev.settlementTerms,
      }));
    }

    let datafortable = userChargerHistoryData.map((user, index) => ({
      serialno: userChargerHistoryData.length - index,
      Date: user?.Date,
      chargername: user.ChargerName,
      usedby: user?.UsedBy,
      starttime: user?.StartTime,
      endtime: user?.EndTime,
      enegryused: user?.EnergyUsed,
      payment: user.payment,
      chargingCost: formatToThreeDecimalPlaces(user?.chargingCost),
      inputamount: user?.inputCost,
    }));
    datafortable = datafortable.reverse();
    setChangeData(datafortable);
  }, [userDetailsData, isLoading]);

  useEffect(() => {
    if (!Array.isArray(userPidsData)) {
      console.error("pidsData is not an array");
      return;
    }
    // Apply API logic only if pidsData is an array
    const apiUpdatedProducts = applyApiLogic(userPidsData);
    const clientData = handleConnect(userPidsData);
    console.log("apiUpdatedProducts", apiUpdatedProducts);
    // Dispatch the updated products to the Redux store
    dispatch(UpdateUserPidsMessagesData(apiUpdatedProducts));
    setMqttClient(clientData);
  }, [userPidsData, dispatch]);

  useEffect(() => {
    if (!Array.isArray(userAllPidDataWithMessages)) {
      console.error("AllPidDataWithMessages is not an array");
      return;
    }
    const filterData = userAllPidDataWithMessages.filter(
      (e) => e && e.status && e.status.toLowerCase() == "idle"
    );
    console.log("filterDatafilterData", filterData);
    const filterData2 = userAllPidDataWithMessages.filter(
      (e) =>
        e && // Check if e exists
        e.status && // Check if e.status exists
        (e.status.toLowerCase() === "charging started" ||
          e.status.toLowerCase() === "charger connected" ||
          e.status.toLowerCase() === "charging completed")
    );
    console.log(filterData2, "filterData2");
    if (filterData.length !== ShowIdleDevices) {
      setShowIdleDevices(filterData.length);
    }
    // Check if the length of filterData2 has changed before updating state
    if (filterData2.length !== ShowActiveDevices) {
      setShowActiveDevices(filterData2.length);
    }
  }, [userAllPidDataWithMessages, ShowIdleDevices]);

  let count = 1;
  const data = userAllPidDataWithMessages.map((user) => {
    return {
      serialno: count++,
      chargername: user?.chargername,
      chargerid: user?.pid,
      chargerstatus: user?.status,
      // usedby: user.Usedbymessage, // Assigning the array of matched values to 'usedby'
      usedby: user?.AppUsername, // Assigning the array of matched values to 'usedby'
      useraddress: user?.Address || "Not Mentioned",
      vehicle: user?.vehicle || "Not Mentioned",
      remark: user?.remark || "No Remarks",
    };
  });

  const handleMessageLogic = async (topic, message) => {
    try {
      if (
        CheckmqttMessagesReceived.hasOwnProperty(topic) &&
        CheckmqttMessagesReceived[topic] === "Charging_Data"
      ) {
        // If the topic is "Charging_Data" and the message is "charging_data", check if it has been set twice
        if (
          CheckmqttMessagesReceivedCounter.hasOwnProperty(topic) &&
          CheckmqttMessagesReceivedCounter[topic] > 7
        ) {
          // If it has been set twice, return without updating the state
          return;
        }
      }

      if (
        CheckmqttMessagesReceived.hasOwnProperty(topic) &&
        CheckmqttMessagesReceived[topic] === message.toString()
      ) {
        return;
      }

      let mqttUpdatedProducts;
      if (topic.includes("Charging_Data")) {
        console.log(
          "CheckmqttMessagesReceivedCounter",
          CheckmqttMessagesReceivedCounter
        );
        if ((CheckmqttMessagesReceivedCounter[topic] || 0) === 5) {
          const mqttUpdatedProducts = await applyMqttLogic(
            userAllPidDataWithMessages,
            topic,
            message,
            setCheckmqttMessagesReceivedCounter,
            fetchData
          );
          console.log("mqttUpdatedProducts radhe", mqttUpdatedProducts);
          dispatch(UpdateUserPidsMessagesData(mqttUpdatedProducts));
        }
        setCheckmqttMessagesReceivedCounter((prevCounter) => ({
          ...prevCounter,
          [topic]: (prevCounter[topic] || 0) + 1,
        }));
      } else {
        console.log("Charging_DatCharging_Data", topic);

        mqttUpdatedProducts = await applyMqttLogic(
          userAllPidDataWithMessages,
          topic,
          message,
          setCheckmqttMessagesReceivedCounter,
          fetchData
        );
        console.log("mqttUpdatedProducts", mqttUpdatedProducts);
        dispatch(UpdateUserPidsMessagesData(mqttUpdatedProducts));
      }

      const updatedMessage = topic.includes("Charging_Data")
        ? "Charging_Data"
        : message.toString();
      setCheckmqttMessagesReceived((prevMessages) => ({
        ...prevMessages,
        [topic]: updatedMessage,
      }));
    } catch (error) {
      console.error("Error parsing received message:", error);
    }
  };

  useEffect(() => {
    if (mqttClient) {
      mqttClient.removeAllListeners("message");
      mqttClient.on("message", handleMessageLogic);

      return () => {
        mqttClient.removeAllListeners("message");
      };
    }
  }, [
    mqttClient,
    userAllPidDataWithMessages,
    CheckmqttMessagesReceivedCounter,
  ]);

  const getRevenue = () => {
    let Data = getAllChargerHistoryData?.map((user, index) => ({
      serialno: getAllChargerHistoryData?.length - index,
      date: user?.Date,
      paymentid: user?.paymentId,
      username: user?.UsedBy,
      inputamount: user?.inputCost,
      chargername: user?.ChargerName,
      // chargingtime: user.time,
      chargingtime: calculateTimeDifferenceStartTimeEndTime(
        user?.StartTime,
        user?.EndTime
      ),
      chargingCost: formatToThreeDecimalPlaces(user?.chargingCost),
    }));
    console.log(
      "RevenueDataRevenueData",
      RevenueData,
      getAllChargerHistoryData
    );
    Data = Data.reverse();
    setRevenueData(Data);
  };

  return (
    <>
      <div
        className="w-full px-5"
        onClick={() => {
          setShowMenuHide(false);
        }}
      >
        <div className="">
          <TopHeader className="" head={head} />
        </div>
        <div className=" mt-10 relative ">
          {showMenuHide && <Navclose_Header head={head} />}
          <div className="flex mt-4 ">
            <div className="w-2/3 p-5 bg-stone-200">
              <p className="text-xl text-gray-900 mb-1">User information</p>
              <hr className="bg-gray-400 rounded-sm h-0.5 mb-2" />
              <div className="my-2">
                <div className="gap-2">
                  <div className="flex flex-row items-center justify-between mb-2">
                    <label className="me-1 flex-shrink-0">User ID :</label>
                    <input
                      className="px-4 py-2 drop-shadow-md rounded-md w-[75%]"
                      type="text"
                      placeholder="Enter User ID"
                      required
                      name="uid"
                      value={userInformation.uid}
                      onChange={handleInputChange}
                    />
                  </div>
                  <div className="flex flex-row items-center justify-between mb-2">
                    <label className="me-1 flex-shrink-0">Name :</label>
                    <input
                      className="px-4 py-2 drop-shadow-md rounded-md w-[75%]"
                      type="text"
                      placeholder="Enter Name"
                      required
                      name="name"
                      value={userInformation.name}
                      onChange={handleInputChange}
                    />
                  </div>
                  <div className="flex flex-row items-center justify-between mb-2">
                    <label className="me-1 flex-shrink-0">Username :</label>
                    <input
                      className="px-4 py-2 drop-shadow-md rounded-md w-[75%]"
                      type="text"
                      placeholder="Enter Username"
                      required
                      name="username"
                      value={userInformation.username}
                      onChange={handleInputChange}
                    />
                  </div>
                  <div className="flex flex-row items-center justify-between mb-2">
                    <label className="me-1 flex-shrink-0">Phone Number :</label>
                    <input
                      className="px-4 py-2 drop-shadow-md rounded-md w-[75%]"
                      type="tel"
                      placeholder="Enter Phone Number"
                      required
                      name="phoneNumber"
                      value={userInformation.phoneNumber}
                      onChange={handleInputChange}
                    />
                  </div>
                  <div className="flex flex-row items-center justify-between mb-2">
                    <label className="me-1 flex-shrink-0">Address :</label>
                    <input
                      className="px-4 py-2 drop-shadow-md rounded-md w-[75%]"
                      type="text"
                      placeholder="Enter Address"
                      required
                      name="address"
                      value={userInformation.address}
                      onChange={handleInputChange}
                    />
                  </div>

                  {/* Bank Details */}
                  <p className="text-xl text-gray-900 mb-1 my-6">
                    Bank Details
                  </p>
                  <hr className="bg-gray-400 rounded-sm h-0.5 mb-2" />
                  <div className="grid grid-cols-2 gap-2 pb-3">
                    <label className="grid pr-6">
                      Account Holder Name
                      <input
                        type="text"
                        className="outline-none rounded-md"
                        style={{
                          height: "40px",
                          marginTop: "5px",
                          paddingLeft: "15px",
                        }}
                        placeholder="Enter Account Holder Name"
                        required
                        name="HolderName"
                        value={userBankDetails.HolderName}
                        onChange={handleBankDetailsChange}
                      />
                    </label>
                    <label className="grid pr-6">
                      Account Number
                      <input
                        type="text"
                        className="outline-none rounded-md"
                        style={{
                          height: "40px",
                          marginTop: "5px",
                          paddingLeft: "15px",
                        }}
                        placeholder="Enter Account Number"
                        required
                        name="AcNumber"
                        value={userBankDetails.AcNumber}
                        onChange={handleBankDetailsChange}
                      />
                    </label>

                    <label className="grid pr-6">
                      Bank Name
                      <input
                        type="text"
                        className="outline-none rounded-md"
                        style={{
                          height: "40px",
                          marginTop: "5px",
                          paddingLeft: "15px",
                        }}
                        placeholder="Enter Bank Name"
                        required
                        name="bankName"
                        value={userBankDetails.bankName}
                        onChange={handleBankDetailsChange}
                      />
                    </label>
                    <label className="grid pr-6">
                      IFSC Code
                      <input
                        type="text"
                        className="outline-none rounded-md"
                        style={{
                          height: "40px",
                          marginTop: "5px",
                          paddingLeft: "15px",
                        }}
                        placeholder="Enter IFSC Code"
                        required
                        name="IfscCode"
                        value={userBankDetails.IfscCode}
                        onChange={handleBankDetailsChange}
                      />
                    </label>
                    <label className="grid pr-6">
                      Phone Number
                      <input
                        type="tel"
                        className="outline-none rounded-md"
                        style={{
                          height: "40px",
                          marginTop: "5px",
                          paddingLeft: "15px",
                        }}
                        placeholder="Enter Phone Number"
                        required
                        name="phoneNumber"
                        value={userBankDetails.phoneNumber}
                        onChange={handleBankDetailsChange}
                      />
                    </label>
                  </div>
                  <div className="my-2">
                    <button className="w-full px-4 py-2 bg-blue-500 text-white rounded hover:bg-blue-700">
                      Update
                    </button>
                  </div>
                </div>
                {/* <div className="flex justify-between mt-2">
                  <div>
                    <Button
                      style={{ color: "white" }}
                      // onClick={handleSubmit}
                      variant="contained"
                      size="large"
                      color="primary"
                    >
                      submit
                    </Button>
                  </div>
                </div> */}
              </div>
            </div>

            <div className="w-1/3">
              {/* First Row */}
              <div className="h-full flex justify-evenly flex-wrap items-center">
                <CMSDetailsCard
                  title={"Total Revenue"}
                  data={ShowTotalRevenue}
                />
                <CMSDetailsCard
                  title={"Total Devices"}
                  data={userPidsData.length}
                />
                <CMSDetailsCard title={"Idle Devices"} data={ShowIdleDevices} />
                <CMSDetailsCard
                  title={"Active Devices"}
                  data={ShowActiveDevices}
                />
                <CMSDetailsCard
                  title={"Total Utilization"}
                  data={ShowTotalUtilization}
                />
                <CMSDetailsCard title={"Total Energy"} data={ShowTotalEnergy} />
              </div>
            </div>
          </div>
        </div>

        <div className="" style={{ marginTop: "20px" }}>
          <CMSTabs
            mid={mid}
            ChargerHistoryData={ChangeData}
            chargerinformation={data}
            RevenueData={RevenueData}
          />
        </div>
      </div>
    </>
  );
};

export default CMSUserDetails;
