import React, { useState, useEffect } from "react";
import search from "./Assets/search.png";
import OutlinedInput from "@mui/material/OutlinedInput";
import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import ListItemText from "@mui/material/ListItemText";
import Select from "@mui/material/Select";
import Checkbox from "@mui/material/Checkbox";
import FilterAltIcon from "@mui/icons-material/FilterAlt";
import KeyboardArrowLeftIcon from "@mui/icons-material/KeyboardArrowLeft";
import KeyboardArrowRightIcon from "@mui/icons-material/KeyboardArrowRight";
import { IconButton, Button } from "@mui/material";
import Modal from "@mui/material/Modal";
import { useSelector } from "react-redux";
import SwitchLeftIcon from "@mui/icons-material/SwitchLeft";
import { useNavigate, useLocation } from "react-router-dom";

function Table({
  columns,
  data,
  pageSize,
  greenButtonText,
  greenButtonText2,
  greenButtonCMSDepthText2,
  blackButtonText,
  addchargertext,
  addchargerClick,
  blackClicked,
  greenClicked,
  greenClickedSuspend,
  catgoryFilter,
  SubcatgoryFilter,
  productCategoryPubUnPub,
  productColors,
  SearchBarShow,
  tabaleHeading,
  // onClick,
  // dataOg,
}) {
  const navigate = useNavigate();
  console.log("greenButtonText", greenButtonText);
  const filterButtonShow =
    catgoryFilter ||
    SubcatgoryFilter ||
    productCategoryPubUnPub ||
    productColors;
  // const currentURL = window.location.href;
  console.log("data", data);
  const location = useLocation();
  // console.log(location.pathname);

  const equelAlluser = `/home/allUsers` === location.pathname;
  const equelDepartment = `/home/department` === location.pathname;
  const equelTransactionHistory =
    `/home/transactionHistory` === location.pathname;

  const [currentPage, setCurrentPage] = useState(0);
  const [searchTerm, setSearchTerm] = useState("");

  const [sortConfig, setSortConfig] = useState({
    key: null,
    direction: "ascending",
  });
  const handleHeaderClick = (key) => {
    let direction = "ascending";

    // Check if the same column header is clicked for the third time
    if (sortConfig.key === key) {
      if (sortConfig.direction === "ascending") {
        direction = "descending";
      } else if (sortConfig.direction === "descending") {
        // Reset sorting configuration to original state
        direction = "ascending";
        key = null;
      }
    }

    setSortConfig({ key, direction });
  };

  let fData = data?.filter((row) =>
    Object?.values(row)?.some((value) =>
      value?.toString()?.toLowerCase()?.includes(searchTerm?.toLowerCase())
    )
  );
  const [filteredData, setfilteredData] = useState(fData);

  useEffect(() => {
    console.log(filteredData);
    setfilteredData(fData);
  }, [searchTerm]);

  useEffect(() => {
    setfilteredData(fData);
  }, [data]);

  const ITEM_HEIGHT = 38;
  const ITEM_PADDING_TOP = 8;
  const MenuProps = {
    PaperProps: {
      style: {
        maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
        width: 150,
      },
    },
  };
  const [OnCheckFIlterCategory, setOnCheckFIlterCategory] = useState([]);

  // const handleChange = (event) => {
  //   const {
  //     target: { value },
  //   } = event;
  //   // console.log(OnCheckFIlterCategory);
  //   setOnCheckFIlterCategory(typeof value === "string" ? value.split(",") : value);
  // };
  const [personName, setPersonName] = useState([]);
  const [personName1, setPersonName1] = useState([]);
  const [personName2, setPersonName2] = useState([]);
  const [personName3, setPersonName3] = useState([]);
  console.log("personName2", personName2);
  useEffect(() => {
    // filterData();
  }, [personName, personName1, personName2, personName3]);
  const handleChange = (event) => {
    const {
      target: { value },
    } = event;
    // console.log(personName);
    setPersonName(typeof value === "string" ? value.split(",") : value);
  };
  const handleChange1 = (event) => {
    console.log("evnet click subcatehgory", event);
    const {
      target: { value },
    } = event;
    console.log(value, "value in subcateogyr");

    // setPersonName1(
    //   typeof value === 'string' ? value.split(',') : value,
    // );
    setPersonName1(value);
  };

  const handleChange2 = (event) => {
    const {
      target: { value },
    } = event;

    setPersonName2(typeof value === "string" ? value.split(",") : value);
  };
  const handleChange3 = (event) => {
    const {
      target: { value },
    } = event;
    // console.log(personName);
    setPersonName3(typeof value === "string" ? value.split(",") : value);
  };
  const handleCatApply = async () => {
    console.log("data in apply", data);
    let filteredData = data;

    // Filter based on personName
    // if (personName.length > 0) {
    //   filteredData = filteredData.filter(item => personName.includes(item.category));
    // }

    // Filter based on personName1
    if (personName1.length > 0) {
      filteredData = filteredData.filter((item) =>
        personName1.includes(item.sub_category)
      );

      console.log("filterdata in subcateogy", filteredData);
    }

    // Filter based on personName2
    if (personName3.length > 0) {
      filteredData = filteredData.filter((item) =>
        personName3.includes(item.category)
      );
    }

    // Filter based on personName3
    if (personName2.length > 0) {
      // filteredData = filteredData.filter(item => {
      //   for (let i = 0; i < personName2.length; i++) {
      //     if (item.colors?.includes(personName3[i])) {
      //       return true;
      //     }
      //   }
      //   return false;
      // });

      filteredData = filteredData.filter((e) => {
        return personName2.includes(e.color[0]);
      });

      console.log("filteredData in color", filteredData);
    }

    // Apply filter based on Published/Unpublished
    if (personName.includes("Published")) {
      filteredData = filteredData.filter((item) => item.draft === "false");
    } else if (personName.includes("Unpublished")) {
      filteredData = filteredData.filter((item) => item.draft === "true");
    }

    // Update filteredData state
    setfilteredData(filteredData);

    handleClose();
  };
  const hanggdleCatApply = async () => {
    console.log(OnCheckFIlterCategory, "personanem");
    console.log(data);
    if (OnCheckFIlterCategory.length > 0) {
      let nr = data.filter((data) =>
        OnCheckFIlterCategory.includes(data.category)
      );
      let sdata = nr.filter((row) =>
        Object.values(row).some((value) =>
          value?.toString().toLowerCase().includes(searchTerm.toLowerCase())
        )
      );
      console.log("sdata,nr", sdata);
      setfilteredData(sdata);
      console.log(nr);
    }
    handleClose();
    if (OnCheckFIlterCategory.length <= 0) {
      setfilteredData(data);
    }
  };

  const handleClearSelection = () => {
    setOnCheckFIlterCategory([]);
  };
  const paginatedData = pageSize
    ? filteredData.slice(currentPage * pageSize, (currentPage + 1) * pageSize)
    : filteredData;

  const pageCount = pageSize ? Math.ceil(filteredData.length / pageSize) : 1;

  // const navigate = useNavigate();

  const handleNextPage = () => {
    if (currentPage < pageCount - 1) {
      setCurrentPage(currentPage + 1);
      // navigate(`/home/customerRelationship/page=${currentPage + 1}`);
    }
  };

  const handlePreviousPage = () => {
    if (currentPage > 0) {
      setCurrentPage(currentPage - 1);
      // navigate(`/home/customerRelationship/page=${currentPage - 1}`);
    }
  };

  const handleSearchChange = (event) => {
    setSearchTerm(event.target.value);
    setCurrentPage(0);
  };

  const [open, setOpen] = React.useState(false);
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);
  const style = {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    width: "30rem",
    boxShadow: 24,
    borderRadius: "20px",
    backgroundColor: "white",
    padding: "15px 30px",
  };
  localStorage.setItem("autoClickApply", "true");

  useEffect(() => {
    const applyButton = document.getElementById("applycat");

    if (applyButton) {
      applyButton.click();
      console.log("apply button clicked");
    }
  }, []);

  const sortedData = [...paginatedData].sort((a, b) => {
    const valueA = a[sortConfig.key];
    const valueB = b[sortConfig.key];

    if (typeof valueA === "number" && typeof valueB === "number") {
      if (valueA < valueB) {
        return sortConfig.direction === "ascending" ? -1 : 1;
      }
      if (valueA > valueB) {
        return sortConfig.direction === "ascending" ? 1 : -1;
      }
      return 0;
    }

    // If not numbers, compare as strings
    if (valueA < valueB) {
      return sortConfig.direction === "ascending" ? -1 : 1;
    }
    if (valueA > valueB) {
      return sortConfig.direction === "ascending" ? 1 : -1;
    }
    return 0;
  });
  const data11 = location?.pathname;
  return (
    <>
      {filterButtonShow ? (
        <>
          <Modal
            open={open}
            onClose={handleClose}
            aria-labelledby="modal-modal-title"
            aria-describedby="modal-modal-description"
          >
            <div style={style}>
              <h2
                style={{
                  fontSize: "1.5rem",
                  fontWeight: "bold",
                  textAlign: "center",
                }}
                className="text-gray-900"
              >
                Filter
              </h2>
              {
                data11 == "/home/productList" ? (
                  <div className="my-3 ">
                    <FormControl className="w-full ">
                      <InputLabel id="demo-multiple-checkbox-label">
                        Category
                      </InputLabel>
                      <Select
                        labelId="demo-multiple-checkbox-label"
                        id="demo-multiple-checkbox"
                        multiple
                        value={personName3}
                        onChange={handleChange3}
                        input={<OutlinedInput label="Select category" />}
                        renderValue={(selected) => selected.join(", ")}
                        MenuProps={MenuProps}
                      >
                        {catgoryFilter?.map((name, index) => (
                          <MenuItem key={index} value={name}>
                            <Checkbox
                              checked={personName3.indexOf(name) > -1}
                            />
                            <ListItemText primary={name} />
                          </MenuItem>
                        ))}
                      </Select>
                    </FormControl>
                    <FormControl
                      className="w-full "
                      style={{ marginTop: "1.5rem" }}
                    >
                      <InputLabel id="demo-multiple-checkbox-label">
                        Colors
                      </InputLabel>
                      <Select
                        labelId="demo-multiple-checkbox-label"
                        id="demo-multiple-checkbox"
                        multiple
                        value={personName2}
                        onChange={handleChange2}
                        input={<OutlinedInput label="Select category" />}
                        renderValue={(selected) => selected.join(", ")}
                        MenuProps={MenuProps}
                      >
                        {productColors?.map((colorsArray, index) =>
                          colorsArray?.map((name, colorIndex) => (
                            <MenuItem
                              key={index + "-" + colorIndex}
                              value={name}
                            >
                              <Checkbox
                                checked={personName2.indexOf(name) > -1}
                              />
                              <ListItemText primary={name} />
                            </MenuItem>
                          ))
                        )}
                      </Select>
                    </FormControl>
                    <FormControl
                      className="w-full "
                      style={{ marginTop: "1.5rem" }}
                    >
                      <InputLabel id="demo-multiple-checkbox-label">
                        Sub Category
                      </InputLabel>
                      <Select
                        labelId="demo-multiple-checkbox-label"
                        id="demo-multiple-checkbox"
                        multiple
                        value={personName1}
                        onChange={handleChange1}
                        input={<OutlinedInput label="Select category" />}
                        renderValue={(selected) => selected.join(", ")}
                        MenuProps={MenuProps}
                      >
                        {SubcatgoryFilter?.map((name, index) => (
                          <MenuItem key={index} value={name}>
                            <Checkbox
                              checked={personName1.indexOf(name) > -1}
                            />
                            <ListItemText primary={name} />
                          </MenuItem>
                        ))}
                      </Select>
                    </FormControl>
                    <FormControl
                      className="w-full "
                      style={{ marginTop: "1.5rem" }}
                    >
                      <InputLabel id="demo-multiple-checkbox-label">
                        Publish
                      </InputLabel>
                      <Select
                        labelId="demo-multiple-checkbox-label"
                        id="demo-multiple-checkbox"
                        multiple
                        value={personName}
                        onChange={handleChange}
                        input={<OutlinedInput label="Select category" />}
                        renderValue={(selected) => selected.join(", ")}
                        MenuProps={MenuProps}
                      >
                        {productCategoryPubUnPub?.map((name, index) => (
                          <MenuItem key={index} value={name}>
                            <Checkbox checked={personName.indexOf(name) > -1} />
                            <ListItemText primary={name} />
                          </MenuItem>
                        ))}
                      </Select>
                    </FormControl>
                    <div className="checkbox-group mt-3">
                      <label>
                        <input
                          type="checkbox"
                          // checked={hasDiscount}
                          // onChange={() => setHasDiscount(!hasDiscount)}
                        />
                        Discount/Promo Code
                      </label>
                    </div>
                    <div className="flex justify-between mt-4">
                      <div>
                        <Button
                          onClick={() => {
                            handleClearSelection();
                            setfilteredData(data);
                            handleClose();
                          }}
                          variant="contained"
                          size="large"
                          color="error"
                        >
                          Clear
                        </Button>
                      </div>
                      <div>
                        <Button
                          id="applycat"
                          onClick={handleCatApply}
                          variant="contained"
                          size="large"
                          color="themeColor"
                        >
                          Apply
                        </Button>
                      </div>
                    </div>
                  </div>
                ) : null
                //               <div className="my-3 ">
                //                <DateFilter
                //       startDate={startDate}
                //       endDate={endDate}
                //       handleStartDateChange={handleStartDateChange}
                //       handleEndDateChange={handleEndDateChange}
                //     />

                //  <div className="flex justify-between mt-4">
                //                   <div>
                //                     <Button onClick={() => { handleClearSelection(); setfilteredData(data); handleClose() }} variant="contained" size="large" color="error">
                //                       Clear
                //                     </Button>
                //                   </div>
                //                   <div>
                //                     <Button id="applycat" onClick={handleDatApply} variant="contained" size="large" color="themeColor">
                //                       Apply
                //                     </Button>
                //                   </div>
                //                 </div>
                //                 </div>
              }
            </div>
          </Modal>
        </>
      ) : null}
      <div className="py-5 table-container">
        <div className="flex justify-between items-center mb-5">
          {SearchBarShow ? (
            <div className="flex justify-between items-center w-full">
              <label className="block text-xl font-semibold">
                {tabaleHeading}
              </label>
              <div className="flex justify-between items-center w-1/3">
                <input
                  type="text"
                  placeholder="Search"
                  className="shadow-md border-gray-100 border-2 rounded-md py-3 pl-5 pr-10 w-full"
                  onChange={handleSearchChange}
                />
                <img
                  src={search}
                  alt="search"
                  className="relative right-10 pointer-events-auto"
                />
              </div>
            </div>
          ) : (
            <div className="flex justify-between items-center w-1/3">
              <input
                type="text"
                placeholder="Search"
                className="shadow-md border-gray-100 border-2 rounded-md py-3 pl-5 pr-10 w-full"
                onChange={handleSearchChange}
              />
              <img
                src={search}
                alt="search"
                className="relative right-10 pointer-events-auto"
              />
            </div>
          )}
          <div className="flex flex-row gap-4 items-center">
            {/* ----------------Order Management button--------------- */}

            {equelTransactionHistory ? (
              <div>
                <button
                  onClick={() => navigate("/home/CreateOrder")}
                  className="bg-[#118615]  text-white w-auto font-bold py-3 px-8 rounded-md"
                >
                  Create Order
                </button>
              </div>
            ) : (
              <></>
            )}

            {/* filter  */}

            {filterButtonShow && (
              <div>
                <IconButton color="" onClick={handleOpen} size="large">
                  <FilterAltIcon />
                </IconButton>
              </div>
            )}

            {blackButtonText && (
              <>
                <div>
                  <button
                    onClick={blackClicked}
                    className="bg-[#118615] w-auto text-white font-bold py-3 px-8 rounded-md"
                  >
                    {blackButtonText}
                  </button>
                </div>
              </>
            )}

            {greenButtonText && (
              <>
                {/* {equelAlluser ? (
                  <div>
                    <button
                      onClick={greenClickedSuspend}
                      className="bg-[green] rounded hover:bg-[#c91b0e] text-white w-auto font-bold py-3 px-8 rounded-sm"
                    >
                      {greenButtonText2}
                    </button>
                  </div>
                ) : (
                  <></>
                )} */}
                {addchargertext && (
                  <div>
                    <button
                      onClick={addchargerClick}
                      className="bg-[#118615] rounded-md text-white w-auto font-bold py-3 px-8 "
                    >
                      {addchargertext}
                    </button>
                  </div>
                )}

                <div>
                  <button
                    onClick={greenClicked}
                    className="bg-[#118615] rounded-md text-white w-auto font-bold py-3 px-8 "
                  >
                    {greenButtonText}
                  </button>
                </div>
              </>
            )}
          </div>
        </div>
        <div
          className=" rounded-xl border border-[#CACACA] bg-[#FFFFFF] overflow-x-auto"
          style={{ paddingTop: 5 }}
        >
          <table className="w-full table-auto text-left">
            <thead className="h-10 text-center">
              <tr className="text-[#118615] border-b border-b-[#CACACA]">
                {columns?.map((column) => (
                  <th
                    key={column.accessor}
                    onClick={() => handleHeaderClick(column.accessor)}
                    className="px-3 py-2 cursor-pointer text-sm "
                  >
                    {column.header}
                    {sortConfig.key === column.accessor && (
                      <span className="ml-1 text-white">
                        {sortConfig.direction === "ascending" ? (
                          <SwitchLeftIcon className="rotate-90  text-green-300" />
                        ) : sortConfig.direction === "descending" ? (
                          <SwitchLeftIcon className="-rotate-90 text-green-300" />
                        ) : (
                          ""
                        )}
                      </span>
                    )}
                  </th>
                ))}
              </tr>
            </thead>
            <tbody className="text-center text-xs md:text-sm ">
              {/* text-xs md:text-sm */}
              {sortedData?.map((row, index) => (
                <tr
                  key={index}
                  className={`${index % 2 === 0 ? "bg-[#EBF5EB]" : ""}`}
                >
                  {columns?.map((column) => (
                    <td key={column.accessor} className="px-3 py-2">
                      {row[column.accessor]}
                    </td>
                  ))}
                </tr>
              ))}
            </tbody>
          </table>
        </div>

        {pageSize && (
          <div className="flex items-center justify-end my-4 gap-1 text-xs sm:text-lg">
            <Button
              className={`px-2 border-2 ${
                currentPage === 0 ? "bg-[#DDDEF9] cursor-default" : ""
              }`}
              color="secondary"
              variant="contained"
              sx={{
                backgroundColor: "#118615",
                color: "white",
                // padding: "0", // Set padding to 0
                minWidth: "20px", // Set minimum width to 20px
                borderRadius: "8px",
                "&:hover": {
                  backgroundColor: "#118615",
                  color: "white",
                },
              }}
              disabled={currentPage === 0}
              onClick={handlePreviousPage}
            >
              <KeyboardArrowLeftIcon sx={{ fontSize: 20 }} />
            </Button>
            <span className="px-1 py-2">{`${
              currentPage + 1
            } - ${pageCount}`}</span>
            <Button
              className={`px-2 border-2 ${
                currentPage === pageCount - 1
                  ? "bg-[#DDDEF9] cursor-default"
                  : ""
              }`}
              color="secondary"
              variant="contained"
              sx={{
                backgroundColor: "#118615",
                color: "white",
                borderRadius: "8px",
                // padding: "0", // Set padding to 0
                minWidth: "20px", // Set minimum width to 20px
                "&:hover": {
                  backgroundColor: "#118615",
                  color: "white",
                },
              }}
              disabled={currentPage === pageCount - 1}
              onClick={handleNextPage}
            >
              <KeyboardArrowRightIcon sx={{ fontSize: 20 }} />
            </Button>
          </div>
        )}
      </div>
    </>
  );
}

export default Table;
