// PlusContainer.js
import React, { useEffect, useState } from "react";
import "./PlusContainer.css"; // Import a CSS file for styling

const PlusContainer = ({ updateSubmittedCode, latestCode }) => {
  console.log(latestCode?.code, "asdkhjdbhjdvbsjvd");
  const [code, setCode] = useState(""); // State to hold the code entered by the user
  useEffect(() => {
    setCode(latestCode?.code);
  }, [latestCode]);
  // Handler function for input change
  const handleInputChange = (event) => {
    setCode(event.target.value);
  };

  // Call the updateSubmittedCode function to pass the entered code to the parent component
  // whenever there's a change in the input
  React.useEffect(() => {
    updateSubmittedCode(code);
  }, [code, updateSubmittedCode]);

  return (
    <div className="flex flex-col items-center justify-center rounded-xl my-5 h-96 bg-white drop-shadow-xl">
      <textarea
        value={code}
        onChange={handleInputChange}
        className="textarea"
        placeholder="Enter your Code here"
      />
    </div>
  );
};

export default PlusContainer;
