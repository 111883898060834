// Hardware.js
import React, { useState, useEffect } from "react";
import "./Hardware.css";
import TopHeader from "../../UI/TopHeader/TopHeader";
import PlusContainer from "./PlusContainer/PlusContainer";
import Card from "./Card/Card";
import { Snackbar, Alert } from "@mui/material";
import { useDispatch, useSelector } from "react-redux";
import Navclose_Header from "../DashBoard_Screen/Component/Navclose_Header";

const Hardware = ({ setActiveTab, showMenuHide }) => {
  setActiveTab("hardwareManagement");
  const head = "Hardware Management";
  const [latestCode, setLatestCode] = useState(null);
  const [submittedCode, setSubmittedCode] = useState(); // State to hold the submitted code
  const [logs, setLogs] = useState(null);
  const [data, setData] = useState(null);
  const [data1, setData1] = useState(null);
  const [data2, setData2] = useState(null);
  const [searchQuery, setSearchQuery] = useState("");
  const [openSnackbar, setOpenSnackbar] = useState(false);
  const [snackbarSeverity, setSnackbarSeverity] = useState("success");
  const [snackbarMessage, setSnackbarMessage] = useState("");
  // Function to handle form submission
  // const

  // const dispatch = useDispatch();
  const LuserData = useSelector((state) => state.userManagement.getUserLogin);
  useEffect(() => {
    setData(LuserData?.role);
  }, [LuserData]);
  const LuserData1 = useSelector((state) => state.userManagement.getUserLogin);
  useEffect(() => {
    setData1(LuserData1?.pic_url);
  }, [LuserData1]);
  const LuserData2 = useSelector((state) => state.userManagement.getUserLogin);
  useEffect(() => {
    setData2(LuserData2?.uname);
  }, [LuserData2]);

  // console.log(data,"skcjssdbvsjsvjb");
  const handleSubmit = () => {
    const data4 = { submittedCode, data, data1, data2 };
    console.log(submittedCode,"harsh khosasl");
    // Make a POST request to the API with the entered code
    // fetch("http://localhost:5200/hardware/code/send-code", {
      fetch("https://backend.jouls.co.in/hardware/code/send-code", {
      method: "PUT",
      headers: {
        "Content-Type": "text/plain", // Change content type to plain text
      },
      body: submittedCode, // Using submittedCode directly from state
    })
      .then((response) => {
        setSnackbarSeverity("success");
        setSnackbarMessage("Code submitted successfully");
        setOpenSnackbar(true);
        console.log("Code submitted successfully");
        // window.location.reload()
      })
      .catch((error) => {
        // Handle error here
        setSnackbarSeverity("error");
        setSnackbarMessage(
          `There was a problem with your fetch operation: ${error.message}`
        );
        setOpenSnackbar(true);
        console.error("There was a problem with your fetch operation:", error);
      });
  };

  useEffect(() => {
    fetch("https://backend.jouls.co.in/hardware/code/getcode")
      .then((response) => {
        if (!response.ok) {
          throw new Error("Network response was not ok");
        }
        return response.json();
      })
      .then((data) => {
        // Set the latest code in state
        setLatestCode(data.response);
        setLogs(data.response.logs);
        console.log(data.response, "dkshjsbdkvhjbvshj");
      })
      .catch((error) => {
        console.error("There was a problem fetching the latest code:", error);
      });
  }, []);
  // Function to update submittedCode state
  const updateSubmittedCode = (code) => {
    setSubmittedCode(code);
  };

  const handleSearchInputChange = (event) => {
    setSearchQuery(event.target.value);
  };

  // Filter the logs array based on the search query
  const filteredLogs = logs
    ? logs.filter((card) =>
        card?.code?.toLowerCase().includes(searchQuery.toLowerCase())
      )
    : [];

  return (
    <div className="w-full px-5">
      <div className="flex">
        <TopHeader className="" head={head} />
      </div>
      <div className="mt-10 relative">
        {showMenuHide && <Navclose_Header head={head} />}
        {/* Pass the updateSubmittedCode function to PlusContainer */}
        <PlusContainer
          updateSubmittedCode={updateSubmittedCode}
          latestCode={latestCode}
        />
        <button className="btn-primary right-align" onClick={handleSubmit}>
          Submit
        </button>

        <div className="flex items-center justify-center mt-4">
          <input
            type="text"
            placeholder="Search..."
            className="border border-gray-400 rounded-md p-2 mr-2"
            value={searchQuery}
            onChange={handleSearchInputChange}
          />
        </div>
        <div className="container mx-auto px-4 flex flex-wrap justify-center">
          {filteredLogs?.map((card, index) => (
            <div
              key={index}
              className={`w-full sm:w-1/2 md:w-1/3 lg:w-1/4 p-4 ${
                index > 3 ? "mt-4" : ""
              }`}
            >
              <Card card={card} index={index} />
            </div>
          ))}
        </div>
      </div>
    </div>
  );
};

export default Hardware;
