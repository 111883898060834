import React from "react";
import PhoneIcon from "@material-ui/icons/Phone";
import RoomIcon from "@material-ui/icons/Room";
import LanguageIcon from "@material-ui/icons/Language";
import EmailIcon from "@material-ui/icons/Email";

const Bill = () => {
  return (
    <div>
      <div className="fixed top-10 right-20">
        <button className="bg-blue-500 text-white font-bold py-2 px-4 rounded-lg shadow-md hover:bg-blue-700">
          Download bill
        </button>
      </div>
      <div
        className="w-3/5 mx-auto border border-gray-400 relative"
        style={{ fontFamily: "Times New Roman, Times, serif" }}
      >
        <img
          src="/images/headerDegine.png"
          alt="Company Logo"
          className="w-full h-24 absolute z-[-1]"
        />
        <div className="px-16 pt-16">
          <div className="text-center text-sm">
            <p
              className="font-algerian text-3xl font-600"
              style={{ fontFamily: "Algerian" }}
            >
              JOULS ECOTECH PRIVATE LIMITED
            </p>
            <p>
              <b>Reg. Office:</b> Floor no. 177, Second Floor, Block-C, South
              City II,
            </p>
            <p>Sector 49, Gurugram, Haryana, IN (122018)</p>
            <p>
              <b>CIN:</b> U31900HR2022PTC106081
            </p>
            <p>
              <b>MSME Udyam Reg. No.:</b> UDYAM-HR-05-0056075
            </p>
            <p>
              <b>Email:</b>
              <a href="mailto:amangoyal6377@gmail.com">
                amangoyal6377@gmail.com
              </a>
              <b> Mob. No.:</b> <a href="tel:+916377650430">+91-6377650430</a>
            </p>
            <p>
              <b>Website:</b>
              <a href="https://www.jouls.energy">www.jouls.energy</a>
            </p>
          </div>
          <div className="border-t-2 border-black mt-2"></div>
          <div className="text-center my-4">
            <h2 className="underline italic font-bold">BILL</h2>
          </div>
          <div className="flex justify-between">
            <p>Bill No. - 01</p>
            <p>Date: 01-04-2024</p>
          </div>
          <div className="my-4">
            <h2>Issued To:</h2>
            <address>
              <p>M/s ARG CG Developers LLP</p>
              <p>E-52, Chitranjan Marg, C-Scheme,</p>
              <p>Jaipur, Rajasthan, IN (302001)</p>
            </address>
            <p>PAN –</p>
            <p>GST (If Any) –</p>
          </div>
          <div>
            <table className="w-full border-collapse">
              <thead>
                <tr
                  className="border-t-2 border-dashed border-black border-b-2 border-solid"
                  style={{
                    borderTop: "2px dashed black",
                    borderBottom: "2px solid black",
                  }}
                >
                  <th className="p-2">S.No.</th>
                  <th className="p-2">Particulars</th>
                  <th className="p-2">Quantity</th>
                  <th className="p-2">Price</th>
                  <th className="p-2">Amount</th>
                </tr>
              </thead>
              <tbody className="text-center">
                <tr>
                  <td className="p-2">1.</td>
                  <td className="p-2">EcoLite (2w/3w EV Charging Station)</td>
                  <td className="p-2">2</td>
                  <td className="p-2">21,000</td>
                  <td className="p-2">42,000</td>
                </tr>
                <tr>
                  <td className="p-2">2.</td>
                  <td className="p-2">Installation & Commissioning</td>
                  <td className="p-2">-</td>
                  <td className="p-2">0</td>
                  <td className="p-2">0</td>
                </tr>
                <tr>
                  <td className="p-2">3.</td>
                  <td className="p-2">CMS Software (for 6 months)</td>
                  <td className="p-2">-</td>
                  <td className="p-2">0</td>
                  <td className="p-2">0</td>
                </tr>
                <tr className="border-t-2 border-dashed border-black">
                  <td colSpan="4" className="text-left p-2">
                    Total
                  </td>
                  <td className="p-2">Rs. 42,000</td>
                </tr>
              </tbody>
            </table>

            <p className="mt-12 mb-[-5]">(Rupees Twenty-One Thousand only)</p>
            <div className="border-t-2 border-dashed border-black mt-2"></div>
          </div>
          <div className="mt-12">
            <p>For Jouls Ecotech Pvt. Ltd.</p>
          </div>
          <div className="my-12">
            <p className="mt-12 mb-5 overline">Authorised Signatory</p>
            <p>Company PAN: AAFCJ7323K</p>
            <p>
              Bank Details:- HDFC Bank, Branch Baskushla, A/c No.:
              50200073027787, IFSC: HDFC0003444
            </p>
            <h3>Other Terms & Conditions:</h3>
            <ol className="list-decimal ml-5">
              <li>
                All payments should be made within 7 days of invoice date.
              </li>
              <li>
                Interest @ 18% p.a. will be charged for any delay in the
                payment.
              </li>
              <li>All disputes are subject to Gurugram (Haryana).</li>
            </ol>
          </div>
        </div>
        <footer className="flex justify-end items-start gap-20 pl-52 pr-6 mt-12 mb-10">
          <div className="flex flex-col items-end">
            <div className="flex items-center">
              U31900HR2022PTC106081
              <span className="font-bold ml-2 bg-[#88c38a] text-md text-white">
                CIN
              </span>
            </div>
            <div className="flex items-center mt-1 text-right">
              Jouls Ecotech Pvt. Ltd., Flat no. 177, Second Floor, Block-C,
              South City-II, Gurugram, Haryana, India, 122018
              <span className="font-bold ml-2 bg-[#88c38a] ">
                <RoomIcon style={{ color: "#fff", fontSize: 25 }} />
              </span>
            </div>
          </div>
          <div className="flex flex-col items-end">
            <div className="flex items-center">
              +91 6377650430
              <span className="font-bold ml-2 bg-[#88c38a]">
                <PhoneIcon style={{ color: "#fff", fontSize: 25 }} />
              </span>
            </div>
            <div className="flex items-center mt-1">
              www.jouls.energy
              <span className="font-bold ml-2 bg-[#88c38a]">
                <LanguageIcon style={{ color: "#fff", fontSize: 25 }} />
              </span>
            </div>
            <div className="flex items-center mt-1">
              amangoyal6377@gmail.com
              <span className="font-bold ml-2 bg-[#88c38a]">
                <EmailIcon style={{ color: "#fff", fontSize: 25 }} />
              </span>
            </div>
          </div>
        </footer>
        <img
          src="/images/footerDegine.png"
          alt="Company Logo"
          className="w-full h-24 absolute z-[-1] bottom-0"
        />
      </div>
    </div>
  );
};

export default Bill;
