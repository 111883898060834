import React, { useEffect, useState } from "react";
import TopHeader from "../../../../UI/TopHeader/TopHeader";
import TextInputs from "./TextInputs";
import { useDispatch, useSelector } from "react-redux";
import {
  HSM_allProduct,
  HSM_category,
  ProductCategories,
  getallScanProduct,
} from "../../../User_Management/features/userSlice";
import axios from "axios";
import { tssurl } from "../../../../UI/port";
import Navclose_Header from "../../../DashBoard_Screen/Component/Navclose_Header";

// get unique categorires
const getUniqueSubCategories = (subCategories) => {
  const uniqueAdditionalFields = new Set();
  const uniqueSubCategories = [];

  subCategories.forEach((item) => {
    if (!uniqueAdditionalFields.has(item.additionalField)) {
      uniqueAdditionalFields.add(item.additionalField);
      uniqueSubCategories.push(item);
    }
  });

  return uniqueSubCategories;
};

const CreateOrder = ({
  setExpand,
  setActiveTab,
  showMenuHide,
  setsubMenu,
  setShowMenuHide,
}) => {
  setExpand("orderManagement");
  setActiveTab("orderManagement");
  setsubMenu("/Create Order");

  const head = "Create Order";

  const dispatch = useDispatch();
  const [formData, setFormData] = useState({
    buyerName: "",
    designation: "",
    company: "",
    mobileNumber: "",
    email: "",
    address: "",
    gstNumber: "",
    leadSource: "",
    pdforderList: "",
    BuyerType: "",
    category: "",
    subcategory: "",
  });

  const [leadSourceOptions] = useState([
    { value: "", label: "Select Lead Source" },
    { value: "Website", label: "Website" },
    { value: "Word of mouth", label: "Word of mouth" },
    { value: "Social Media", label: "Social Media" },
    { value: "Newspaper", label: "Newspaper" },
    { value: "Other", label: "Other" },
  ]);
  useEffect(() => {
    const fetchUserData = async () => {
      await dispatch(ProductCategories());
      await dispatch(HSM_category());
      await dispatch(getallScanProduct());
      await dispatch(HSM_allProduct(true));
    };
    fetchUserData();
  }, [dispatch]);

  const CategoryData = useSelector(
    (state) => state.userManagement.hsm_category
  );
  const SubCategoryData = useSelector(
    (state) => state.userManagement.ProductCategories
  );

  const uniqueSubCategories = getUniqueSubCategories(SubCategoryData);
  console.log("uniqueSubCategories", uniqueSubCategories);
  const getallScanProductData = useSelector(
    (state) => state.userManagement.getallScanProduct
  );
  const AllProductData = useSelector(
    (state) => state?.userManagement?.hsm_allproducts
  );

  console.log("AllProductData", AllProductData);
  // const CategoryData=[
  //   {value:"Home Charger",label:"Home Charger"},
  // {value:"Public Charger",label:"Public Charger"}
  // ]
  // const SubCategoryData=[
  //   {value:"Two Wheeler",label:"Two Wheeler"},
  // {value:"Four Wheeler",label:"Four Wheeler"}
  // ]

  // const [formData,setformData]=useState({category:"",subcategory:""})
  const [checkAvailabilityData, setCheckAvailablity] = useState({
    EcoSpark: "",
    EcoLite: "",
  });

  const [SelectCategoryProducts, setSelectCategoryProducts] = useState([]);

  const handleInputchange = (fieldName, value) => {
    setFormData((prevData) => ({
      ...prevData,
      [fieldName]: value,
    }));
  };

  const [productName, setProductName] = useState("");
  const [productPrice, setProductPrice] = useState("");
  const [productQuantity, setProductQuantity] = useState("");
  const [orderSummary, setOrderSummary] = useState([]);
  const [AllaTotal, setAllaTotal] = useState("");
  const [ShowBill, setShowBill] = useState(false);

  const [products, setProducts] = useState([]);
  const [checkbox1, setCheckbox1] = useState(false);
  const [checkbox2, setCheckbox2] = useState(false);
  useEffect(() => {
    // setShowBill(false);
  }, [products]);

  const handleAddBuyerClick = () => {
    // Handle logic for adding buyer information
    console.log("Add Buyer button clicked", formData);
  };

  const handleQuantityChange = (productId, newQuantity) => {
    if (!formData.category || !formData.subcategory) {
      alert("quantity select first");
      return;
    }
    if (newQuantity > SelectCategoryProducts[0].Quantity_Scan_Product.length) {
      // alert("out of Stock Product");
      return;
    }

    const updatedProducts = [...products];
    updatedProducts[productId].quantity = newQuantity;
    console.log("updatedProducts", updatedProducts, products);
    setProducts(updatedProducts);
    const newTotal = products.reduce((acc, item) => {
      return acc + item.price * item.quantity;
      // return acc + Number(item.price) * Number(item.quantity);
    }, 0);
    setAllaTotal(newTotal);
    setProductName(products[productId].name);
    setProductQuantity(products[productId].quantity);
    setProductPrice(products[productId].price);
    console.log(products);
    UpdateBill();
  };

  const getPidFromScanProduct = () => {
    if (
      !Array.isArray(products) ||
      !products.length ||
      !Array.isArray(SelectCategoryProducts) ||
      !SelectCategoryProducts.length
    ) {
      console.log("Invalid or empty products/SelectCategoryProducts array.");
      return [];
    }

    let response = [];

    products.forEach(({ name, quantity }) => {
      if (!name || typeof quantity !== "number" || quantity <= 0) {
        console.log("Invalid product data:", { name, quantity });
        return;
      }

      const selectedProduct = SelectCategoryProducts.find(
        (p) => p.product_name === name
      );
      if (
        selectedProduct &&
        Array.isArray(selectedProduct.Quantity_Scan_Product)
      ) {
        response.push(
          ...selectedProduct.Quantity_Scan_Product.slice(0, quantity).map(
            (item) => item._id
          )
        );
      } else {
        console.log(
          "Product not found or invalid Quantity_Scan_Product array for:",
          name
        );
      }
    });

    console.log("Quantity_Scan_Product", response);
    return response;
  };

  const AddPidToSendFormdata = () => {
    let response = [];
    let ProductPidsEcoSpark = [];
    let ProductPidsEcoLite = [];
    for (let i = 0; i < products.length; i++) {
      if (products[i].quantity > 0) {
        for (let j = 0; j < AllProductData.length; j++) {
          if (
            products[i].name.trim().toLowerCase() ==
              AllProductData[j].product_name.trim().toLowerCase() &&
            formData.category.trim().toLowerCase() ==
              AllProductData[j].category.trim().toLowerCase()
          ) {
            response.push(AllProductData[j]);
          }
        }
      }
    }
    console.log(response, "response in AddPidToSendFormdata");
    if (response.length > 0) {
      for (let i = 0; i < products.length; i++) {
        if (products[i].quantity > 0) {
          for (var j = 0; j < response.length; j++) {
            if (
              response[j].product_name.trim().toLowerCase() == "ecospark" &&
              response[j].Quantity_Scan_Product.length >= products[0].quantity
            ) {
              var originalArray = response[j].Quantity_Scan_Product;
              console.log("originalArray", originalArray);
              for (var k = 0; k < products[0].quantity; k++) {
                ProductPidsEcoSpark.push(originalArray[k]);
              }
            } else if (
              response[j].product_name == "EcoLite" &&
              response[j].Quantity_Scan_Product.length >= products[1].quantity
            ) {
              var originalArray = response[j].Quantity_Scan_Product;
              console.log("originalArray", originalArray);
              for (var k = 0; k < products[1].quantity; k++) {
                ProductPidsEcoLite.push(originalArray[k]);
              }
            }
          }
        }
      }
    }
    ProductPidsEcoLite = [...new Set(ProductPidsEcoLite)];
    ProductPidsEcoSpark = [...new Set(ProductPidsEcoSpark)];
    console.log(
      "ProductPidsEcoSpark",
      ProductPidsEcoSpark,
      ProductPidsEcoLite,
      response
    );
    setProducts((prevArray) => {
      return prevArray.map((obj, index) => {
        // Update values based on index
        if (index === 0) {
          return { ...obj, OrderPids: ProductPidsEcoSpark };
        } else if (index === 1) {
          return { ...obj, OrderPids: ProductPidsEcoLite };
        } else {
          return obj; // No change for other indices
        }
      });
    });

    const Data = products.map((obj, index) => {
      if (index === 0) {
        return { ...obj, OrderPids: ProductPidsEcoSpark };
      } else if (index === 1) {
        return { ...obj, OrderPids: ProductPidsEcoLite };
      } else {
        return obj; // No change for other indices
      }
    });
    const FilterData = Data.filter((e) => e.quantity > 0);

    //  const Data = [
    //     { id: 0, name: "EcoSpark", price: 45000, quantity:parseInt(products[0].quantity) ||0, OrderPids:ProductPidsEcoSpark||[]},
    //     { id: 1, name: "EcoLite", price: 9000, quantity:  parseInt(products[1].quantity) ||0 ,OrderPids:ProductPidsEcoLite ||[]},
    //   ]

    return FilterData;
  };

  function getCurrentDate() {
    const today = new Date();
    const day = today.getDate().toString().padStart(2, "0");
    const month = (today.getMonth() + 1).toString().padStart(2, "0"); // Months are zero-based
    const year = today.getFullYear().toString().slice(-2); // Last two digits of the year
    return `${day}/${month}/${year}`;
  }
  const handleAddProductClick = async () => {
    // if (checkAvailabilityData.EcoSpark < products[0].quantity) {
    //   return alert("out of stock EcoSpark");
    // }
    // if (checkAvailabilityData.EcoLite < products[1].quantity) {
    //   return alert("out of Stock ecolite");
    // }
    const userSetProductData = getPidFromScanProduct();
    console.log("usersetproductdata", userSetProductData);

    // console.log("products king ", products);

    // const ProdutPidsName = AddPidToSendFormdata();
    // console.log("ProdutPidsNameProdutPidsName", ProdutPidsName);
    const TotalAmount = calculateTotalAmount(products);
    const SendformData = new FormData();
    userSetProductData.forEach((product, index) => {
      SendformData.append(`products[${index}]`, product);
    });
    SendformData.append("BuyerType", formData.BuyerType);
    SendformData.append("address", formData.address);
    SendformData.append("buyerName", formData.buyerName);
    SendformData.append("category", formData.category);
    SendformData.append("company", formData.company);
    SendformData.append("designation", formData.designation);
    SendformData.append("email", formData.email);
    SendformData.append("gstNumber", formData.gstNumber);
    SendformData.append("leadSource", formData.leadSource);
    SendformData.append("mobileNumber", formData.mobileNumber);
    SendformData.append("pdforderList", formData.pdforderList);
    SendformData.append("TotalAmount", TotalAmount);
    SendformData.append("status", "Order Created");

    try {
      const response1 = await axios.post(
        `${tssurl}/oders/radhe`,
        SendformData,
        { headers: { "Content-Type": "multipart/formdata" } }
      );
      // const response2 = await axios.post(
      //   `${tssurl}/scanpid/delete`,
      //   userSetProductData
      // );
      // window.print();
      console.log("response", response1);
    } catch (error) {
      console.log("error", error);
    }
    console.log(productQuantity);
    const productDetails = {
      productName: productName,
      productPrice: productPrice,
      productQuantity: productQuantity,
    };
    // setOrderSummary([...orderSummary, productDetails]);
    console.log(orderSummary);
    // Clear product form fields after adding to order summary
    // setProductName("");
    // setProductPrice("");
    // setProductQuantity("");
    // setShowBill(true);
  };
  const checkAvailability = (category, subcategory) => {
    if (!category || !subcategory) return;

    const data = AllProductData.filter((e) => {
      return (
        category.toLowerCase().trim() === e.category.toLowerCase().trim() &&
        subcategory.toLowerCase().trim() === e.sub_category.toLowerCase().trim()
      );
    });

    if (
      !data[0]?.Quantity_Scan_Product ||
      data[0]?.Quantity_Scan_Product.length == 0
    ) {
      setShowBill(false);
    }
    console.log("datadatadata", data);
    setSelectCategoryProducts(data);
    const modifiedData = data.map((item, index) => ({
      id: index,
      name: item.product_name,
      price: item.unit_price,
      OrderPids: [],
      quantity: 0,
    }));
    console.log("modifiedDatamodifiedData", modifiedData);
    setProducts(modifiedData);
    // console.log("dataincheckAvailabity", data);

    // let updatedAvailabilityData = { ...checkAvailabilityData };
    // if (data.length > 0) {
    //   data.forEach((item, index) => {
    //     if (item.product_name.toLowerCase() == "ecospark") {
    //       updatedAvailabilityData.EcoSpark =
    //         item.Quantity_Scan_Product.length;
    //     }

    //     if (item.product_name == "EcoLite") {
    //       updatedAvailabilityData.EcoLite = item.Quantity_Scan_Product.length;
    //     }

    //     // Add more conditions for other properties as needed
    //   });
    // }

    // setCheckAvailablity(updatedAvailabilityData);
  };

  const handleCheckboxChange = (checkboxNumber) => {
    if (checkboxNumber === 1) {
      setCheckbox1(!checkbox1);
      setFormData({ ...formData, BuyerType: "Company" });
      setCheckbox2(false); // Deselect checkbox2
    } else if (checkboxNumber === 2) {
      setCheckbox2(!checkbox2);
      setFormData({ ...formData, BuyerType: "Individual" });
      setCheckbox1(false); // Deselect checkbox1
    }
  };
  const calculateTotalAmount = (products) => {
    let totalAmount = 0;
    for (const product of products) {
      totalAmount += product.price * product.quantity;
    }
    return totalAmount;
  };

  const calculatePrice = (category) => {
    if (!category) {
      alert("Please select a category first");
      return;
    }

    const filterData = AllProductData.filter(
      (e) => e.category.trim().toLowerCase() === category.trim().toLowerCase()
    );
    if (filterData.length === 0) {
      console.log("No products found for the selected category");
      return;
    }

    const updatedProducts = products.map((product) => {
      const filterItem = filterData.find(
        (item) =>
          item.product_name.trim().toLowerCase() ===
          product.name.trim().toLowerCase()
      );
      if (filterItem && filterItem.unit_price !== null) {
        return {
          ...product,
          price: parseInt(filterItem.unit_price),
          quantity: 0,
        };
      }
      return { ...product, price: "XXXX", quantity: 0 };
    });

    setProducts(updatedProducts);
  };

  const UpdateBill = () => {
    // setOrderSummary([...orderSummary, productDetails]);
    setShowBill(true);
  };

  const handleChangeCategory = (e, field) => {
    const { value } = e.target;
    setFormData((prevFormData) => ({
      ...prevFormData,
      [field]: value,
    }));
  };

  useEffect(() => {
    if (!formData.category || !formData.subcategory) return;

    checkAvailability(formData.category, formData.subcategory);
    // const productData = dispatch()
  }, [formData.category, formData.subcategory]);

  useEffect(() => {}, []);
  return (
    <>
      <div className="w-full px-5" onClick={() => setShowMenuHide(false)}>
        {/* Left Form for Buyer Information */}
        <div className="">
          <TopHeader className="" head={head} />
          {showMenuHide && <Navclose_Header head={head} />}
          <div className="md:flex justify-center">
            <div className=" px-5 w-full" style={{ marginTop: "25px" }}>
              <div className="lg:flex gap-5">
                <div className="p-5 lg:w-2/3 border border-[#C8BFBF] rounded-xl bg-white">
                  <div className="text-lg text-[#6B6A6A] font-bold w-full">
                    Buyer Details
                  </div>
                  <div className="w-auto gap-5">
                    <div className="md:flex gap-5 md:my-8 ">
                      <div className="w-full mb-8 md:mb-0">
                        <TextInputs
                          type={"text"}
                          title={"Buyer’s Name"}
                          name="buyerName"
                          value={formData.buyerName}
                          placeholder="Enter your username"
                          onInputChange={(value) =>
                            handleInputchange("buyerName", value)
                          }
                          required={true}
                        />
                      </div>
                      <div className="w-full">
                        <TextInputs
                          type={"text"}
                          title={"Designation"}
                          name="designation"
                          value={formData.value}
                          placeholder="Designation"
                          onInputChange={(value) =>
                            handleInputchange("designation", value)
                          }
                        />
                      </div>
                    </div>
                    <div className="md:flex gap-5 my-8 md:my-8">
                      <div className="w-full mb-8 md:mb-0">
                        <TextInputs
                          type={"text"}
                          title={"Compony"}
                          name="company"
                          value={formData.company}
                          placeholder="Enter your username"
                          onInputChange={(value) =>
                            handleInputchange("company", value)
                          }
                        />
                      </div>
                      <div className="w-full ">
                        <TextInputs
                          type={"text"}
                          title={"Mobile Number"}
                          name="mobileNumber"
                          value={formData.mobileNumber}
                          placeholder="Enter your username"
                          onInputChange={(value) =>
                            handleInputchange("mobileNumber", value)
                          }
                        />
                      </div>
                    </div>
                    <div className="md:flex gap-5 my-8 md:my-8">
                      <div className="w-full mb-8 md:mb-0">
                        <TextInputs
                          type={"text"}
                          title={"Email"}
                          name="email"
                          value={formData.email}
                          placeholder="Enter your username"
                          onInputChange={(value) =>
                            handleInputchange("email", value)
                          }
                        />
                      </div>
                      <div className="w-full ">
                        <TextInputs
                          type={"text"}
                          title={"Address"}
                          name="address"
                          value={formData.address}
                          placeholder="Enter your username"
                          onInputChange={(value) =>
                            handleInputchange("address", value)
                          }
                        />
                      </div>
                    </div>

                    <div className="md:flex items-center gap-5 my-8 md:my-8">
                      <div className="relative w-full mb-8 md:mb-0">
                        <span className="px-2 rounded-xl bg-white text-[#118615] align-center absolute  -top-3 left-4">
                          Lead source
                        </span>
                        <select
                          value={formData.leadSource}
                          className="p-2 w-full border rounded-lg border-1 border-[#B1B1B1] text-[#6A6A6A] "
                          onChange={(e) =>
                            handleInputchange("leadSource", e.target.value)
                          }
                        >
                          {leadSourceOptions.map((items, index) => (
                            <option key={index} value={items.value}>
                              {items.label}
                            </option>
                          ))}
                        </select>
                      </div>
                      <div className="p-1 flex justify-between border border-[#B1B1B1] rounded-xl items-center w-full">
                        <div>
                          <span className="mr-2 ">Buyer Type :-</span>
                        </div>
                        <div>
                          <div>
                            <input
                              // type="checkbox"
                              type="checkbox"
                              checked={checkbox1}
                              onChange={() => handleCheckboxChange(1)}
                              name="Individual"
                              className="mr-2"
                            />
                            <span htmlFor="buyerType">Company</span>
                          </div>
                          <div>
                            <input
                              // type="checkbox"
                              // checked={buyerType}
                              // onChange={() => setBuyerType(!buyerType)}
                              type="checkbox"
                              checked={checkbox2}
                              onChange={() => handleCheckboxChange(2)}
                              name="Individual"
                              className="mr-2"
                            />
                            <span htmlFor="buyerType">Individual</span>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="relative w-full mb-8 md:mb-0">
                      <span className="px-2 rounded-xl bg-white text-[#118615] align-center absolute  -top-3 left-4">
                        Category
                      </span>
                      <select
                        value={formData.category}
                        className="p-2 w-full border rounded-lg border-1 border-[#B1B1B1] text-[#6A6A6A] "
                        onChange={(e) => {
                          handleChangeCategory(e, "category");
                        }}
                      >
                        <option>select Category</option>
                        {Array.isArray(CategoryData) &&
                        CategoryData.length > 0 ? (
                          CategoryData.map((items, index) => (
                            <option key={index} value={items.categoryName}>
                              {items.categoryName}
                            </option>
                          ))
                        ) : (
                          <option value="">No categories available</option>
                        )}
                      </select>
                    </div>
                    <br />
                    <div className="relative w-full mb-8 md:mb-0">
                      <span className="px-2 rounded-xl bg-white text-[#118615] align-center absolute  -top-3 left-4">
                        Sub Category
                      </span>
                      <select
                        value={formData.subcategory}
                        className="p-2 w-full border rounded-lg border-1 border-[#B1B1B1] text-[#6A6A6A] "
                        onChange={(e) => {
                          handleChangeCategory(e, "subcategory");
                        }}
                      >
                        <option>select Category</option>
                        {Array.isArray(uniqueSubCategories) &&
                        uniqueSubCategories.length > 0 ? (
                          uniqueSubCategories.map((item, index) => (
                            <option key={index} value={item.additionalField}>
                              {item.additionalField}
                            </option>
                          ))
                        ) : (
                          <option value="">No subcategory available</option>
                        )}
                      </select>
                    </div>
                    <br />

                    <br />
                    {/* <p>EcoSpark:-{checkAvailabilityData.EcoSpark}</p>
                    <p>EcoLite :-{checkAvailabilityData.EcoLite}</p> */}
                    {Array.isArray(SelectCategoryProducts) &&
                      SelectCategoryProducts.map((e) => (
                        <p>
                          {e.product_name}:-{" "}
                          {e.Quantity_Scan_Product?.length || "0"}
                        </p>
                      ))}
                    <div className="md:flex items-center gap-5 my-8 md:my-8">
                      <div className="w-full mb-8 md:mb-0 w-full">
                        <TextInputs
                          type={"text"}
                          title={"GST No"}
                          name="address"
                          value={formData.gstNumber}
                          placeholder="Enter your GST No"
                          onInputChange={(value) =>
                            handleInputchange("gstNumber", value)
                          }
                        />
                      </div>
                      <div className="md:w-1/2">
                        <label className="text-[green]" htmlFor="pdfInput">
                          purchase order LIst{" "}
                        </label>
                        <div className="mt-2">
                          <input
                            type="file"
                            id="pdfInput"
                            accept=".pdf"
                            onChange={(e) => {
                              setFormData({
                                ...formData,
                                pdforderList: e.target.files[0],
                              });
                            }}
                          />
                        </div>
                      </div>
                    </div>
                    {/* --------------------pdf upload--------------------- */}
                    <div className="md:flex items-center">
                      <div className="mt-3 mx-2 flex justify-center w-full">
                        <button
                          onClick={handleAddBuyerClick}
                          className="p-2 bg-[#118615] w-80 text-xl text-white rounded-md "
                        >
                          Add Buyer
                        </button>
                      </div>
                    </div>
                  </div>
                </div>
                {/* Right Form for Product Information */}
                {/* bank Details */}
                <div className=" ">
                  {/* flex flex-col justify-between p-5 lg:w-1/3 mt-4 lg:mt-0 border border-[#C8BFBF] rounded-xl */}
                  <div className=" flex flex-col justify-center p-5  border border-[#C8BFBF] rounded-xl bg-white h-full">
                    <div className="text-lg text-[#6B6A6A] font-bold w-full mb-4">
                      Bank Details
                    </div>
                    <div className="w-full space-y-8">
                      <div className="md:flex md:space-x-5">
                        <div className="w-full mb-8 md:mb-0">
                          <TextInputs
                            type={"text"}
                            title={"Bank Name"}
                            name="bankName"
                            required={true}
                          />
                        </div>
                        <div className="w-full">
                          <TextInputs
                            type={"text"}
                            title={"Account Number"}
                            name="accountNumber"
                          />
                        </div>
                      </div>
                      <div className="md:flex md:space-x-5">
                        <div className="w-full mb-8 md:mb-0">
                          <TextInputs
                            type={"text"}
                            title={"Account Holder Name"}
                            name="accountHolderName"
                          />
                        </div>
                        <div className="w-full">
                          <TextInputs
                            type={"text"}
                            title={"IFSC Code"}
                            name="ifscCode"
                          />
                        </div>
                      </div>
                      <div className="md:flex md:space-x-5">
                        <div className="w-full">
                          <TextInputs
                            type={"text"}
                            title={"Phone Number"}
                            name="phoneNumber"
                          />
                        </div>
                      </div>
                      <div>
                        <div className="text-lg text-[#6B6A6A] font-bold w-full mb-4">
                          Select Settlement Terms
                        </div>
                        <div className="flex space-x-4 justify-evenly">
                          <button className="px-4 py-2 bg-blue-500 text-white rounded hover:bg-blue-700">
                            T+7 Days
                          </button>
                          <button className="px-4 py-2 bg-green-500 text-white rounded hover:bg-green-700">
                            T+15 Days
                          </button>
                          <button className="px-4 py-2 bg-red-500 text-white rounded hover:bg-red-700">
                            T+30 Days
                          </button>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              <div className="flex flex-col justify-between  p-5 mt-4 border border-[#C8BFBF] rounded-xl ">
                {/* <div className="flex items-center  h-full">
                  <img
                    className=""
                    src="/images/ordericon.png"
                    alt="logo"
                    srcSet=""
                  />
                </div> */}

                <div className="w-full ">
                  <div className="p-2 text-lg text-[#6B6A6A] font-bold w-full">
                    Products List
                  </div>
                  <div className="max-h-96 border border-[#C8BFBF] rounded-2xl overflow-hidden shadow-sm">
                    <div className="grid grid-cols-3 border-b border-[#C8BFBF] text-center rounded-t-2xl text-[#118615] text-sm bg-[#fff]">
                      <span className="p-1">Products</span>
                      <span className="p-1 border-x border-[#C8BFBF]">
                        Price
                      </span>
                      <span className="p-1">Qty.</span>
                    </div>
                    <div className=" overflow-auto ">
                      {products.map((item, index) => (
                        <div
                          key={index}
                          className="grid grid-cols-3 text-center bg-[#EBF5EB] text-[#7E7E7E] h-8 rounded-xl my-2 mx-2"
                        >
                          <span className="flex justify-center items-center">
                            {item.name}
                          </span>
                          <span className="flex justify-center items-center">
                            {item.price}
                          </span>
                          <span className="flex justify-between items-center mx-2">
                            <button
                              onClick={() =>
                                handleQuantityChange(item.id, item.quantity - 1)
                              }
                              disabled={item.quantity === 0}
                              className={`px-1  bg-[#FF8686] text-white text-sm ${
                                item.quantity === 0
                                  ? "opacity-50 cursor-not-allowed"
                                  : "cursor-pointer"
                              }`}
                            >
                              -
                            </button>
                            <span className="px-1 mx-2">{item.quantity}</span>

                            <button
                              onClick={() =>
                                handleQuantityChange(item.id, item.quantity + 1)
                              }
                              className={`px-1 text-sm bg-green-500 text-white ${
                                item.quantity ===
                                SelectCategoryProducts[0]?.Quantity_Scan_Product
                                  .length
                                  ? "opacity-50 cursor-not-allowed"
                                  : "cursor-pointer"
                              }`}
                              disabled={
                                item.quantity ===
                                SelectCategoryProducts[0]?.Quantity_Scan_Product
                                  .length
                              }
                            >
                              +
                            </button>
                          </span>
                        </div>
                      ))}
                    </div>
                  </div>
                </div>
              </div>

              {/* xmcnd */}
              {/* <div className="p-5 mt-4 border border-[#C8BFBF] rounded-xl bg-white">
                <div className="text-lg text-[#6B6A6A] font-bold w-full mb-4">
                  Bank Details
                </div>
                <div className="w-full space-y-8">
                  <div className="md:flex md:space-x-5">
                    <div className="w-full mb-8 md:mb-0">
                      <TextInputs
                        type={"text"}
                        title={"Bank Name"}
                        name="bankName"
                        required={true}
                      />
                    </div>
                    <div className="w-full">
                      <TextInputs
                        type={"text"}
                        title={"Account Number"}
                        name="accountNumber"
                      />
                    </div>
                  </div>
                  <div className="md:flex md:space-x-5">
                    <div className="w-full mb-8 md:mb-0">
                      <TextInputs
                        type={"text"}
                        title={"Account Holder Name"}
                        name="accountHolderName"
                      />
                    </div>
                    <div className="w-full">
                      <TextInputs
                        type={"text"}
                        title={"IFSC Code"}
                        name="ifscCode"
                      />
                    </div>
                  </div>
                  <div className="md:flex md:space-x-5">
                    <div className="w-full">
                      <TextInputs
                        type={"text"}
                        title={"Phone Number"}
                        name="phoneNumber"
                      />
                    </div>
                  </div>
                  <div>
                    <div className="text-lg text-[#6B6A6A] font-bold w-full mb-4">
                      Select Settlement Terms
                    </div>
                    <div className="flex space-x-4 justify-evenly">
                      <button className="px-4 py-2 bg-blue-500 text-white rounded hover:bg-blue-700">
                        T+7 Days
                      </button>
                      <button className="px-4 py-2 bg-green-500 text-white rounded hover:bg-green-700">
                        T+15 Days
                      </button>
                      <button className="px-4 py-2 bg-red-500 text-white rounded hover:bg-red-700">
                        T+30 Days
                      </button>
                    </div>
                  </div>
                </div>
              </div> */}

              <div className="flex justify-between my-4">
                <div className="flex items-center mt-4">
                  <input
                    type="checkbox"
                    id="installationCheckbox"
                    name="installationCheckbox"
                    className="mr-2 h-4 w-4"
                  />
                  <label
                    htmlFor="installationCheckbox"
                    className="flex items-center text-xl"
                  >
                    Installation Charges
                  </label>
                </div>

                <div className="flex items-center my-2">
                  <label
                    htmlFor="transportationCharges"
                    className="mr-4 text-xl"
                  >
                    Transportation Charges :
                  </label>
                  <input
                    type="text"
                    id="transportationCharges"
                    name="transportationCharges"
                    placeholder="Enter charges"
                    className="px-4 py-2 drop-shadow-md rounded-md "
                    // w-1/2
                  />
                </div>
              </div>

              {/* -------------------- order summary------------------------- */}

              <div className="my-2">
                <h2 className="text-xl font-bold mb-2">Order Summary</h2>
                {
                  // products.length > 0
                  ShowBill && products[0]?.quantity != 0 ? (
                    <div className="">
                      <div className="max-h-96 border border-[#C8BFBF] rounded-2xl overflow-hidden shadow-sm">
                        <div className="grid grid-cols-4 border-b border-[#C8BFBF] text-center rounded-t-2xl text-[#118615] text-sm bg-[#fff]">
                          <span className="p-1">Products</span>
                          <span className="p-1 border-x border-[#C8BFBF]">
                            Price
                          </span>
                          <span className="p-1 ">Qty.</span>
                          <span className="p-1 border-l border-[#C8BFBF]">
                            Total
                          </span>
                        </div>
                        <div className=" overflow-auto ">
                          {products.map((item, index) => {
                            if (item.quantity > 0) {
                              return (
                                <div
                                  key={index}
                                  className="grid grid-cols-4 text-center bg-[#EBF5EB] text-[#7E7E7E] h-8 rounded-xl my-2 mx-2"
                                >
                                  <span className="flex justify-center items-center">
                                    {item.name}
                                  </span>
                                  <span className="flex justify-center items-center">
                                    {item.price}
                                  </span>
                                  <span className="flex justify-center items-center mx-2">
                                    {item.quantity}
                                  </span>
                                  <span className="flex justify-center items-center">
                                    ₹
                                    {isNaN(item.price * item.quantity)
                                      ? 0
                                      : item.price * item.quantity}
                                    /-
                                  </span>
                                </div>
                              );
                            } else {
                              // Return null or an empty fragment if you don't want to render anything when quantity is 0
                              return null;
                            }
                          })}
                        </div>
                        <div className="flex justify-between items-center mx-5 h-20">
                          <div className="md:ml-10 text-[#118615]">
                            {" "}
                            Final Amount
                          </div>
                          <div className="flex">
                            <div className="flex flex-col items-end md:mr-10 ">
                              <div>
                                <span className="text-[#118615]">
                                  Sub Total -{" "}
                                </span>
                                <span> ₹{AllaTotal}/-</span>
                              </div>
                              <div className="flex items-center">
                                <input
                                  type="checkbox"
                                  id="gstCheckbox"
                                  name="gstCheckbox"
                                  className="mr-2 bg-red-200"
                                />
                                <label
                                  htmlFor="gstCheckbox"
                                  className="flex items-center"
                                >
                                  <span className="text-[#118615]">
                                    Sub Total with 18% GST -{" "}
                                  </span>
                                  <span>
                                    ₹{AllaTotal + (AllaTotal * 18) / 100}/-
                                  </span>
                                </label>
                              </div>
                            </div>
                            <div>
                              <button
                                className="p-2 bg-[#118615]  text-xl text-white rounded-md "
                                onClick={() => {
                                  handleAddProductClick();
                                }}
                              >
                                Generate bill
                              </button>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  ) : (
                    <div className=" p-5 border border-[#C8BFBF]">
                      <p>No products added to the order yet.</p>
                    </div>
                  )
                }
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default CreateOrder;
