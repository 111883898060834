import React, { useEffect, useState } from "react";
import Card from "./Component/Card";
import { Active, Inactive, Charger, Usersicon } from "./Assets";
import Chart from "./Component/Chart";
import TopHeader from "../../UI/TopHeader/TopHeader";
import { Dashmap } from "./Component/Dashmap";
import SearchIcon from "@mui/icons-material/Search";
import Navclose_Header from "./Component/Navclose_Header";

import { useDispatch, useSelector } from "react-redux";
import {
  GetAllOrderProduct,
  updateDasboardMqttMessage,
} from "../User_Management/features/userSlice";
import mqtt from "mqtt";
import Table from "../../UI/CommonTable/Table";

const OrderStatus = ({ orderstatus }) => {
  return (
  
    <div className="flex flex-col">
        <p>&nbsp; {orderstatus?.status}</p>
        <p>&nbsp; ({orderstatus?.date})</p>
    </div>
  
    
  );
};


const Dashboard = ({ setShowMenuHide, showMenuHide, setsubMenu }) => {
  const head = "Dashboard";
  setsubMenu(null);
  const dispatch = useDispatch();
  const [pidDetails, setPidDetails] = useState(null);
  const [newDetails, setNewDetails] = useState({});
  const [mqttClient, setMqttClient] = useState(null);
  const [CheckmqttMessagesReceived, setCheckmqttMessagesReceived] = useState(
    {}
  );
  const [alldataArraySetOrderPids, setAlldataArrayOrderPids] = useState(true);

  const brokerUrl = "wss://mqtt.jouls.co.in/mqtt";
  const data =[] // dummy

  

  const [search, setserach] = useState();

  useEffect(() => {
    const fetchUserData = async () => {
      await dispatch(GetAllOrderProduct());
    };
    fetchUserData();
  }, [dispatch]);

  const allOrderedPids = useSelector(
    (state) => state.userManagement.GetAllOrderProduct
  );
  const userData = []
  let AllPidDataWithMessages = useSelector(
    (state) => state.userManagement.allOrderedPidsMqttMessages
  );
  const [alldata, setalldata] = useState([
    { pid: "PES-A1B2C3D4E5F6G7H8I9", message: "" },
  ]);
  console.log("allOrderedPids",allOrderedPids);
  const [status, setStatus] = useState("");
  // useEffect(() => {
  //   if (search) {
  //     const user = data.find((element) => element.id === search);
  //     if (user) {
  //       console.log(user);
  //       setalldata([user]);
  //     }
  //   } else {
  //     setalldata(data);
  //   }
  // }, [search]);

  const handaleSearch = () => {
    console.log(search);
    const user = data.find((element) => element.id === search);
    if (user === undefined) {
      console.log(user, "no data found");
    } else {
      console.log(user);
      setalldata([user]);
    }
  };

  useEffect(()=>{
    handleConnect()
  },[allOrderedPids])

  useEffect(() => {
    if (!Array.isArray(allOrderedPids)) {
      console.error("pidsData is not an array");
      return;
    }
    const applyApiLogic = (apiProducts) => {
      // Apply your logic here (for example, filtering or modifying products)
      return apiProducts.map((product) => ({
        pid: product?.ProductPid,
        status: "",
        chargername: product?.productname,
        address:product?.address,
        orderstatus:Array.isArray(product?.status) ? product?.status[product?.status?.length-1]:{},
      }));
    };

    // Apply API logic only if pidsData is an array
    const apiUpdatedProducts = applyApiLogic(allOrderedPids);
    console.log("apiUpdatedProducts", apiUpdatedProducts);

    // Dispatch the updated products to the Redux store
    dispatch(updateDasboardMqttMessage(apiUpdatedProducts));
  }, [allOrderedPids]);

  // useEffect(() => {
  //   if (!search) {
  //     // setalldata(data);
  //     setalldata(OrderPIds)
  //   }
  // }, [search]);

 const handleConnect = () => {
    const client = mqtt.connect(brokerUrl);
    // Connection successful
    client.on("connect", () => {
      console.log("Connected to MQTT broker");

      // Subscribe to PID topic
      if (allOrderedPids.length > 0) {
        console.log("in if revenuemangeagemn pidsData", allOrderedPids);
        allOrderedPids.forEach((e) => {
          const topics = [`${e.ProductPid}_Updates`, `${e.ProductPid}_Charging_Data`];
          topics.forEach((topic) => {
            client.subscribe(topic, (err) => {
              if (!err) {
                console.log("Subscribed to", topic);
              } else {
                console.error("Error subscribing to", topic, ":", err);
              }
            });
          });
        });
      } else {
        console.log("data nhi hai alldata me ", allOrderedPids);
      }
      setMqttClient(client);
    });
  };

  const applyMqttLogic = (topic, message) => {
    return AllPidDataWithMessages.map((obj) => {
      if (`${obj?.pid}_Charging_Data` === topic.toString()) {
        return { ...obj, status: "Charging Started" };
      }
      if (`${obj?.pid}_Updates` === topic.toString()) {
        // Handle logic for other topic
        if (message.toString() === "Idle") {
          return { ...obj, status: "Idle" };
        }
        if (message.toString() === "Door is open") {
          return { ...obj, status: "Charger Connected" };
        }
        if (message.toString() === "Charging Started") {
          return { ...obj, status: "Charging Started" };
        }
        if (message.toString() === "Charging Completed") {
          return { ...obj, status: "Charging Completed" };
        }
      } else {
        // For other objects, return as is
        return obj;
      }
    });
  };


  const handleMessageLogic = (topic, message) => {
    try {
      if (
        CheckmqttMessagesReceived.hasOwnProperty(topic) &&
        CheckmqttMessagesReceived[topic] === "Charging_Data"
      ) {
        // If the topic is "Charging_Data" and the message is "charging_data", return without updating the state
        return;
      }
      if (
        CheckmqttMessagesReceived.hasOwnProperty(topic) &&
        CheckmqttMessagesReceived[topic] === message.toString()
      ) {
        // If the topic and message exist, return without updating the state
        return;
      }
      let mqttUpdatedProducts;
      if (topic.includes("Charging_Data")) {
        mqttUpdatedProducts = applyMqttLogic(topic, message);
        console.log("mqttUpdatedProducts", mqttUpdatedProducts);
        dispatch(updateDasboardMqttMessage(mqttUpdatedProducts));
      } else {
        mqttUpdatedProducts = applyMqttLogic(topic, message);
        console.log("mqttUpdatedProducts", mqttUpdatedProducts);
        dispatch(updateDasboardMqttMessage(mqttUpdatedProducts));
      }
      const updatedMessage = topic.includes("Charging_Data")
        ? "Charging_Data"
        : message.toString();
      setCheckmqttMessagesReceived((prevMessages) => ({
        ...prevMessages,
        [topic]: updatedMessage,
      }));
    } catch (error) {
      console.error("Error parsing received message:", error);
    }
  };

  useEffect(() => {
    if (mqttClient) {
      mqttClient.removeAllListeners("message");
      mqttClient.on("message", handleMessageLogic);

      return () => {
        mqttClient.removeAllListeners("message");
      };
    }
  }, [mqttClient, AllPidDataWithMessages]);


  const columns = [
    {
      header: "S No.",
      accessor: "serialno",
    },
    {
      header: "PID",
      accessor: "pid",
    },
    {
      header: "Name",
      accessor: "chargername",
    },
    {
      header: "Status",
      accessor: "status",
    },
    {
      header: "Address",
      accessor: "address",
    },
    {
      header: "Order Status",
      accessor: "orderstatus",
    },

  ];

  const showTabledata = AllPidDataWithMessages.map((user,index) => {
    return {
      serialno: index+1,
      chargername: user?.chargername,
      pid: user?.pid,
      status: user?.status || (
        <div className="flex justify-center">
          <p>helo</p>
        </div>
      ),
      address: user?.address,
      orderstatus:<OrderStatus orderstatus={user?.orderstatus}/>
    };
  });

  // useEffect(() => {
  //   handleConnect(); // Automatically connect on component mount
  // }, [userData]);

  // useEffect(() => {
  //   //
  //   const applyMqttLogic = (topic, message) => {
  //     let updatedData = AllPidData.map((obj) => {
  //       if (obj.pid === topic) {
  //         // Create a new object with the same properties as obj, but with the updated message
  //         return {
  //           ...obj,
  //           message: message.toString(),
  //         };
  //       }
  //       return obj;
  //     });

  //     // Apply your additional logic if needed

  //     return updatedData;
  //   };

  //   if (mqttClient) {
  //     let messageReceived = false; // Flag to track if a message is received

  //     const handleMessage = (topic, message) => {
  //       console.log("Received message on topic:", topic);
  //       console.log("Message content:", message.toString());

  //       try {
  //         const mqttUpdatedProducts = applyMqttLogic(topic, message);
  //         console.log(mqttUpdatedProducts, "mqttUpdatedProducts");
  //         messageReceived = true; // Set the flag to true when a message is received
  //         if (message.toString() === "Idle") {
  //           setStatus("wifi connected");
  //         } else if (message.toString() === "Charging Started") {
  //           setStatus("charging started");
  //         } else if (message.toString() === "Charging Completed") {
  //           setStatus("charging stopped");
  //         }
  //         dispatch(updateData(mqttUpdatedProducts));
  //         // setalldata(updatedData);
  //         setAlldataArrayOrderPids(false);
  //       } catch (error) {
  //         console.error("Error parsing received message:", error);
  //       }
  //     };

  //     // Remove existing listeners before adding a new one
  //     // mqttClient.removeListener("message", handleMessage);

  //     mqttClient.on("message", handleMessage);

  //     const timeoutId = setTimeout(() => {
  //       if (!messageReceived) {
  //         setStatus("wifi Disconnected");
  //         // const updatedDataEmptyMessage = updatedData.map((obj) => ({
  //         //   ...obj,
  //         //   message: "",
  //         // }));

  //         setAlldataArrayOrderPids(false);
  //         // setalldata(updatedDataEmptyMessage);
  //       }
  //     }, 10000);

  //     return () => {
  //       clearTimeout(timeoutId); // Clear the timeout to prevent it from triggering after unmount
  //     };
  //   }
  // }, [mqttClient, status, AllPidData]);

  // useEffect(() => {
  //   const applyApiLogic = (apiProducts) => {
  //     // Apply your logic here (for example, filtering or modifying products)
  //     let OrderPIds = [];
  //     for (let i = 0; i < apiProducts.length; i++) {
  //       // OrderPIds.push(userData[i].products[j].OrderPids[k])
  //       for (let j = 0; j < apiProducts[i].products?.length; j++) {
  //         if (apiProducts[i].products[j].OrderPids) {
  //           for (
  //             let k = 0;
  //             k < apiProducts[i].products[j].OrderPids.length;
  //             k++
  //           ) {
  //             OrderPIds.push({
  //               pid: apiProducts[i].products[j].OrderPids[k],
  //               message: "",
  //             });
  //           }
  //         }
  //       }
  //       // setalldata(OrderPIds)
  //     }
  //     console.log("orderPids", OrderPIds);
  //     return OrderPIds;
  //   };
  //   // Apply API logic on products from the API
  //   const apiUpdatedProducts = applyApiLogic(userData);

  //   // Dispatch the updated products to the Redux store
  //   dispatch(updateData(apiUpdatedProducts));
  // }, [userData, dispatch]);

  // console.log("AllPidData", AllPidData);

  const HandleSearch = (e) => {
    // const value = e.target.value;
    // console.log("e.target.value", AllPidData);
    // const FilterData = AllPidData.filter((e) => e.pid.includes(value));
    // console.log("filterdata", FilterData);
  };
  // console.log(showMenuHide);
  return (
    <div
      className="w-full px-5"
      onClick={() => {
        setShowMenuHide(false);
      }}
    >
      <div className="flex">
        <TopHeader className="" head={head} />
      </div>
      <div className="my-10 w-full">
        {showMenuHide && <Navclose_Header head={head} />}

        <div className="flex flex-wrap gap-4 justify-center mt-4 md:justify-between">
          {/* grid md:grid-cols-2 2xl:grid-cols-4 justify-items-center */}
          <div className="mb-4">
            <Card
              title={"123"}
              subtitle={"Total Users"}
              icon={Usersicon}
              color={"bg-[#1807ad]"}
            />
          </div>
          <div className="mb-4">
            <Card
              title={[].length}
              subtitle={"Total Chargers"}
              icon={Charger}
              color={"bg-[#7307ad]"}
            />
          </div>
          <div className="mb-4">
            <Card
              title={"88"}
              subtitle={"Active Chargers"}
              icon={Active}
              color={"bg-[#7d7880]"}
            />
          </div>
          <div className="mb-4">
            <Card
              title={"74"}
              subtitle={"Inactive Chargers"}
              icon={Inactive}
              color={"bg-[#e0142f]"}
            />
          </div>
        </div>
        <div
          className="xl:flex w-full mt-5 xl:gap-8 xl:h-screen overflow-hidden"
          // style={{ height: "620px" }}
        >
          <div className="xl:w-1/2 rounded-3xl border border-1 border-[#118615]  bg-[#FFF]">
            <div className="flex  items-center h-16 relative ">
              <div className="ml-5 xl:ml-16 text-xm xl:text-xl  text-[#118615] font-bold ">
                <p>Overall Positions</p>
              </div>
              <div
                style={{ marginRight: -5, marginTop: -5 }}
                className="flex absolute right-0 top-0 h-12 rounded-2xl py-1 w-40 lg:w-64   bg-[#fff]  border-t-1 border-t-[#fff] border-r-1 border-r-[#fff] border border-1 border-[#118615]"
              >
                <div className="mx-auto h-8 rounded-xl overflow-hidden bg-[#118615]">
                  <input
                    className="pl-3 h-full w-20 lg:w-auto text-sm bg-[#C1E0C2] outline-none"
                    type="text"
                    // maxLength={5}
                    value={search}
                    onChange={HandleSearch}
                    placeholder="Search Particular"
                  />
                  <span
                    onClick={handaleSearch}
                    className="bg-[#118615] rounded-lg lg:w-10"
                  >
                    <SearchIcon className="text-[#fff] " />
                  </span>
                </div>
              </div>
            </div>
        <Table
        columns={columns}
        data={showTabledata}
        />
            {/* <div className="h-5/6 border-2 rounded-2xl overflow-hidden shadow-md m-5 mt-7  "> */}
              {/* max-h-96 */}
              {/* <div className="grid grid-cols-3 border-b border-[#C8BFBF] text-center rounded-t-2xl text-[#118615] text-md lg:text-xl bg-[#fff]">
                <span className="p-2">PID</span>
                <span className="p-2">Name</span>
                <span className="p-2 border-x border-[#C8BFBF]">Status</span>
                <span className="p-2">address</span>
              </div> */}
              {/* <div className="h-96 xl:h-full overflow-auto"> */}
                {/* h-80 */}
                {/* {AllPidData.map((item, index) => (
                  <div
                    key={index}
                    className="grid grid-cols-3 text-center bg-[#C1E0C2] text-xs text-[#575757] p-1 rounded-xl my-3 mx-5"
                  >
                    <span className="flex justify-center items-center">
                      {item.pid}
                    </span>
                    <span className="flex justify-center items-center">
                      {status}
                    </span>
                    <span className="flex justify-center items-center">
                      {item.message}
                    </span>
                  </div>
                ))} */}
              {/* </div> */}
            {/* </div> */}
          </div>
          <div className="xl:w-1/2 mt-5 xl:mt-0 rounded-xl border border-1 border-[#118615] bg-[#FFF] overflow-hidden ">
            <h1 className="text-[#118615] font-bold text-2xl mt-6 text-center">
              Distribution Across India
            </h1>
            <div className="w-full h-full flex items-center">
              <div className="w-full mx-6 border border-2 border-[#EBEBEB] shadow-lg rounded-3xl overflow-hidden">
                <Dashmap />
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Dashboard;
