// ErrorToast.js
import React from "react";
import { Snackbar, IconButton } from "@mui/material";
import { Close } from "@mui/icons-material";

const ErrorToast = ({ message, open, onClose }) => {
  return (
    <Snackbar
      open={open}
      autoHideDuration={3000}
      onClose={onClose}
      anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
      message={message}
      variant="bigTrouble"
      action={
        <IconButton size="small" aria-label="close" onClick={onClose}>
          <Close fontSize="small" />
        </IconButton>
      }
    />
  );
};

export default ErrorToast;
