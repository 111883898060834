import React, { useState, useEffect } from "react";
import TopHeader from "../../../UI/TopHeader/TopHeader";
import { useSelector } from "react-redux";
import { useDispatch } from "react-redux";
import { updateOrder } from "../../User_Management/features/userSlice";
import { Form, Link, useLocation, useNavigate } from "react-router-dom";
import Table from "../../../UI/CommonTable/Table";
import { Grid } from "react-loader-spinner";
import {
  VeiwMember,
  VeiwOrder,
} from "../../User_Management/features/userSlice";
import { Order_Table } from "../../User_Management/features/userSlice";
import { Reward_Table } from "../../User_Management/features/userSlice";
import { getUserLogin } from "../../User_Management/features/userSlice";
import Cookies from "js-cookie";
import Navclose_Header from "../../DashBoard_Screen/Component/Navclose_Header";

const Vieworder = ({ setActiveTab, setExpand, showMenuHide, setsubMenu }) => {
  const location = useLocation();
  const editData = location.state;

  const [products, setProducts] = useState([]);
  // if (editData.tab == "crm") {
  //   setActiveTab("customerRelationship");
  // } else {
  //   setActiveTab("transactionHistory");
  //   // setExpand("homeService");
  // }
  setActiveTab("orderManagement");
  setsubMenu("/View Order");

  const [loading, setLoading] = useState(false);

  const dispatch = useDispatch();
  const LuserData = useSelector((state) => state.userManagement.getUserLogin);
  useEffect(() => {
    async function name() {
      setLoading(true);
      await dispatch(
        getUserLogin(localStorage.getItem("uid") || Cookies.get("uid"))
      );
      await dispatch(VeiwOrder(editData.oid));
      setLoading(false);
    }
    name();
  }, [dispatch]);
  const Navigate = useNavigate();
  console.log(editData, "editData");

  const data1 = useSelector((state) => state.userManagement.VeiwOrder);
  console.log("Checking Data 1");
  console.log(data1);
  useEffect(() => {
    setProducts(data1.products);
  }, [data1.products]);
  const paymentOptions = ["Paid", "UnPaid", "Refunded"];

  const handleOptionChange1 = (e) => {
    setpaymentStatus(e.target.value);
  };
  const [paymentStatus, setpaymentStatus] = useState(editData.payment_status);
  const [deliveryStatus, setdeliveryStatus] = useState(
    editData.delivery_status
  );
  const [oid, setOid] = useState(editData.oid);
  const [tracking_id, setTracking_id] = useState(editData.tracking_id);

  // useEffect(() => {
  //   setpaymentStatus(data1.payment_status);
  //   setdeliveryStatus(data1.delivery_status);
  //   setTracking_id(data1.tracking_id);
  // }, [data1])

  const handleOptionChange = (e) => {
    setdeliveryStatus(e.target.value);
  };
  const head = "View Order";

  const handleSubmit = async (event) => {
    event.preventDefault();
    if (!deliveryStatus) {
      alert("please select status");
      return;
    }
    const oid = editData.oid || "";
    const formData = new FormData();
    formData.append("status", deliveryStatus);
    setLoading(true);
    await dispatch(updateOrder({ formData, oid }));
    setLoading(false);
    if (editData.tab == "crm") {
      // dispatch(VeiwMember(editData.mid));
      // dispatch(Order_Table(editData.mid));
      // dispatch(Reward_Table(editData.mid));
      // dispatch(Internal_Notes(editData.mid));
      // Navigate(`/home/memberDetails/${editData.mid}`, { state: editData.mid });
    } else {
      // Navigate(`/home/transactionHistory`);
    }
  };

  const columns = [
    {
      header: "S.No.",
      accessor: "sno",
    },
    {
      header: "Product Name",
      accessor: "productName",
    },
    {
      header: "Quantity",
      accessor: "quantity",
    },
    {
      header: "Units",
      accessor: "units",
    },
    {
      header: "Total",
      accessor: "total",
    },
  ];

  const data = [
    {
      sno: 1,
      productName: "Product A",
      quantity: 10,
      units: "pcs",
      total: "$100",
    },
    {
      sno: 2,
      productName: "Product B",
      quantity: 5,
      units: "pcs",
      total: "$50",
    },
    {
      sno: 3,
      productName: "Product C",
      quantity: 20,
      units: "pcs",
      total: "$200",
    },
    {
      sno: 4,
      productName: "Product D",
      quantity: 15,
      units: "pcs",
      total: "$150",
    },
    {
      sno: 5,
      productName: "Product E",
      quantity: 8,
      units: "pcs",
      total: "$80",
    },
    {
      sno: 6,
      productName: "Product F",
      quantity: 12,
      units: "pcs",
      total: "$120",
    },
    {
      sno: 7,
      productName: "Product G",
      quantity: 25,
      units: "pcs",
      total: "$250",
    },
    {
      sno: 8,
      productName: "Product H",
      quantity: 18,
      units: "pcs",
      total: "$180",
    },
    {
      sno: 9,
      productName: "Product I",
      quantity: 22,
      units: "pcs",
      total: "$220",
    },
    {
      sno: 10,
      productName: "Product J",
      quantity: 30,
      units: "pcs",
      total: "$300",
    },
  ];
  return (
    <>
      {/* {loading ? (
        <div className="fixed inset-0 bg-gray-700 opacity-80 flex justify-center items-center z-50">
          <Grid
            height="80"
            width="80"
            color="#4fa94d"
            ariaLabel="grid-loading"
            radius="12.5"
            wrapperStyle={{}}
            wrapperClass=""
            visible={true}
          />
        </div>
      ) : null} */}
      <div className="w-full px-5">
        <div className="flex" style={{}}>
          <TopHeader className="" head={head} />
        </div>
        <div className="mt-10 relative flex flex-col bg-[#fff]">
          {showMenuHide && <Navclose_Header head={head} />}

          {/* Top Section */}

          <div className=" md:flex gap-5">
            <div className="p-6 bg-[#EEEEEE] rounded-md shadow-md">
              <h2 className="text-xl font-bold mb-4">User Details</h2>
              <div className="flex h-full flex-wrap ">
                <div className="w-full md:w-1/2 mb-4 md:pr-2">
                  <div className="flex items-center justify-between">
                    <label htmlFor="firstName" className="block text-gray-700">
                      First Name:
                    </label>
                    <input
                      type="text"
                      id="firstName"
                      name="firstName"
                      className="mt-1 block w-[80%] h-7 rounded-md border-gray-300 shadow-sm focus:border-indigo-300 focus:ring focus:ring-indigo-200 focus:ring-opacity-50"
                      required
                    />
                  </div>
                </div>
                <div className="w-full md:w-1/2 mb-4 md:pl-2">
                  <div className="flex items-center justify-between">
                    <label htmlFor="lastName" className="block text-gray-700">
                      Last Name:
                    </label>
                    <input
                      type="text"
                      id="lastName"
                      name="lastName"
                      className="mt-1 block w-[80%] h-7 rounded-md border-gray-300 shadow-sm focus:border-indigo-300 focus:ring focus:ring-indigo-200 focus:ring-opacity-50"
                      required
                    />
                  </div>
                </div>
                <div className="w-full md:w-1/2 mb-4 md:pr-2">
                  <div className="flex items-center justify-between">
                    <label htmlFor="email" className="block text-gray-700">
                      Email:
                    </label>
                    <input
                      type="email"
                      id="email"
                      name="email"
                      className="mt-1 block w-[80%] h-7 rounded-md border-gray-300 shadow-sm focus:border-indigo-300 focus:ring focus:ring-indigo-200 focus:ring-opacity-50"
                      required
                    />
                  </div>
                </div>
                <div className="w-full md:w-1/2 mb-4 md:pl-2">
                  <div className="flex items-center justify-between">
                    <label htmlFor="password" className="block text-gray-700">
                      Contact:
                    </label>
                    <input
                      type="text"
                      className="mt-1 block w-[80%] h-7 rounded-md border-gray-300 shadow-sm focus:border-indigo-300 focus:ring focus:ring-indigo-200 focus:ring-opacity-50"
                      required
                    />
                  </div>
                </div>
                <div className="w-full md:w-1/2 mb-4 md:pr-2">
                  <div className="flex items-center justify-between">
                    <label htmlFor="password" className="block text-gray-700">
                      Order Code:
                    </label>
                    <input
                      type="text"
                      className="mt-1 block w-[80%] h-7 rounded-md border-gray-300 shadow-sm focus:border-indigo-300 focus:ring focus:ring-indigo-200 focus:ring-opacity-50"
                      required
                    />
                  </div>
                </div>
                <div className="w-full md:w-1/2 mb-4 md:pl-2">
                  <div className="flex items-center justify-between">
                    <label htmlFor="phone" className="block text-gray-700">
                      Customer:
                    </label>
                    <input
                      type="text"
                      className="mt-1 block w-[80%] h-7 rounded-md border-gray-300 shadow-sm focus:border-indigo-300 focus:ring focus:ring-indigo-200 focus:ring-opacity-50"
                      required
                    />
                  </div>
                </div>
              </div>
            </div>
            <div className="p-6 bg-[#EEEEEE]  md:w-[30%] rounded-md shadow-md">
              <h2 className="text-xl font-bold mb-4">info</h2>
              <form>
                <div className="mb-4">
                  {/* <label
                    htmlFor="dropdown1"
                    className="block text-gray-700 mb-2"
                  >
                    Dropdown 1:
                  </label> */}
                  <select
                    id="dropdown1"
                    name="dropdown1"
                    className="block w-full p-2 rounded-md border-gray-300 shadow-sm focus:border-indigo-300 focus:ring focus:ring-indigo-200 focus:ring-opacity-50"
                  >
                    <option value="">Select an option</option>
                    <option value="option1">Option 1</option>
                    <option value="option2">Option 2</option>
                    <option value="option3">Option 3</option>
                  </select>
                </div>

                <div className="mb-4">
                  {/* <label
                    htmlFor="dropdown2"
                    className="block text-gray-700 mb-2"
                  >
                    Dropdown 2:
                  </label> */}
                  <select
                    id="dropdown2"
                    name="dropdown2"
                    className="block w-full p-2 rounded-md border-gray-300 shadow-sm focus:border-indigo-300 focus:ring focus:ring-indigo-200 focus:ring-opacity-50"
                  >
                    <option value="">Select an option</option>
                    <option value="option1">Option 1</option>
                    <option value="option2">Option 2</option>
                    <option value="option3">Option 3</option>
                  </select>
                </div>

                <div className="">
                  <button
                    type="submit"
                    className="px-4 w-full mb-2 py-2 bg-blue-500 text-white rounded-md shadow-md hover:bg-blue-600 focus:outline-none focus:ring-2 focus:ring-blue-200"
                  >
                    Submit
                  </button>
                  <button
                    type="reset"
                    className="px-4 w-full py-2 bg-red-500 text-white rounded-md shadow-md hover:bg-red-600 focus:outline-none focus:ring-2 focus:ring-red-200"
                  >
                    Reset
                  </button>
                </div>
              </form>
            </div>
          </div>

          {/* table */}
          <div className="mt-3">
            <Table columns={columns} data={data} pageSize={5} />
          </div>

          {/* <div>
            <form onSubmit={handleSubmit}>
              <div className="bg-[#EEEEEE] mt-8 w-full drop-shadow-md border flex flex-row gap-28 px-3 py-4">
                <div>
                  <label>Payment Status</label>
                  <div className="mt-2">
                    <select
                      id="select"
                      value={paymentStatus}
                      onChange={handleOptionChange1}
                      className="w-full px-4 py-2 border rounded focus:outline-none "
                    >
                      <option value="paid">Paid</option>
                      <option value="unpaid">Unpaid</option>
                      <option value="refunded">Refunded</option>
                    </select>
                  </div>
                </div>
                <div>
                  <label>Status</label>
                  <div className="mt-2">
                    <select
                      id="select"
                      value={deliveryStatus}
                      onChange={handleOptionChange}
                      className="w-full px-4 py-2 border rounded focus:outline-none "
                      disabled={deliveryStatus === "Cancle"}
                    >
                      <option value="Pending">Order Created</option>
                      <option value="payment done">Payment Done</option>
                      <option value="product ready">Product Ready</option>
                      <option value="installation">Installation</option>
                      <option value="commissioning">Commissioning</option>
                    </select>
                  </div>
                </div>
                <div>
                  <label>Tracking Code (optional)</label>
                  <div className="mt-2">
                    <input
                      className="w-full px-4 py-2 border rounded focus:outline-none"
                      type="text"
                      value={tracking_id}
                      onChange={(event) => setTracking_id(event.target.value)}
                    />
                  </div>
                </div>

                {LuserData.role == "admin" || LuserData.role == "cr" ? (
                  <div className="flex items-center justify-center">
                    <button
                      className="bg-blue-500 hover:bg-blue-600 rounded-xl font-semibold mt-5"
                      style={{ height: "5vh", width: "15vh", color: "white" }}
                    >
                      Update
                    </button>
                  </div>
                ) : null}
              </div>
            </form>
          </div> */}

          {/* Order Summary Section */}
          {/* <div className="bg-[#EEEEEE] mt-8 w-full drop-shadow-md border flex flex-col gap-8 px-3 py-4">
            <h2 className="font-bold text-lg">Order Summary</h2>
            <div className="flex flex-row justify-between">
              <table className="w-full mr-5 mb-20">
                <tbody>
                  <tr>
                    <td className="border border-black px-6 py-4">
                      Order Code:
                    </td>
                    <td className="border border-black px-6 py-4">
                      {data1._id}
                    </td>
                  </tr>
                  <tr>
                    <td className="border border-black px-6 py-4">Customer:</td>
                    <td className="border border-black px-6 py-4">
                      {data1.uname}
                    </td>
                  </tr>
                  <tr>
                    <td className="border border-black px-6 py-4">Email:</td>
                    <td className="border border-black px-6 py-4">
                      {data1.email}
                    </td>
                  </tr>
                  <tr>
                    <td className="border border-black px-6 py-4">
                      Shipping address:
                    </td>
                    <td className="border border-black px-6 py-4">
                      {data1.shipping_addr}
                    </td>
                  </tr>
                </tbody>
              </table>
              <table className="w-full">
                <tbody>
                  <tr>
                    <td className="border border-black px-6 py-4">
                      Order date:
                    </td>
                    <td className="border border-black px-6 py-4">
                      {data1.date}
                    </td>
                  </tr>
                  <tr>
                    <td className="border border-black px-6 py-4">
                      Delivery status:
                    </td>
                    <td className="border border-black px-6 py-4">
                      {data1.delivery_status}
                    </td>
                  </tr>
                  <tr>
                    <td className="border border-black px-6 py-4">
                      Payment status:
                    </td>
                    <td className="border border-black px-6 py-4">
                      {data1.payment_status}
                    </td>
                  </tr>
                  <tr>
                    <td className="border border-black px-6 py-4">
                      Total order amount:
                    </td>
                    <td className="border border-black px-6 py-4">
                      {data1.amount}
                    </td>
                  </tr>
                  <tr>
                    <td className="border border-black px-6 py-4">Contact:</td>
                    <td className="border border-black px-6 py-4">
                      {data1.contact}
                    </td>
                  </tr>
                  <tr>
                    <td className="border border-black px-6 py-4">
                      Payment method:
                    </td>
                    <td className="border border-black px-6 py-4">
                      {data1.payment_mode}
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
          </div> */}
          {/* Order Details */}
          <div className="flex flex-row w-full gap-5">
            {/* <div className="bg-[#EEEEEE] mt-8 mb-10 w-[60%] drop-shadow-md border flex flex-col gap-8 px-3 py-4">
              <h2 className="font-bold text-lg">Order Details</h2>
              <div className="flex flex-row justify-between">
                <table className="w-full">
                  <thead>
                    <tr>
                      <th className="border border-black  p-2">#</th>
                      <th className="border border-black p-2">Photo</th>
                      <th className="border border-black p-2">Product</th>
                      <th className="border border-black p-2">Reward Points</th>
                      <th className="border border-black p-2">Quantity</th>
                      <th className="border border-black p-2">Price</th>
                    </tr>
                  </thead>
                  <tbody>
                    {products &&
                      products.length > 0 &&
                      products.map((product, index) => (
                        <tr key={product.pid}>
                          <td className="border border-black px-6 py-4">
                            {index + 1}
                          </td>
                          <td className="border border-black px-6 py-4">
                            <img src={product.photo} alt="photo" />
                          </td>
                          <td className="border border-black px-6 py-4">
                            {product.product_name}
                          </td>
                          <td className="border border-black px-6 py-4">
                            {product.reward_points}
                          </td>
                          <td className="border border-black px-6 py-4">
                            {product.count}
                          </td>
                          <td className="border border-black px-6 py-4">
                            {product.price}
                          </td>
                        </tr>
                      ))}
                  </tbody>
                </table>
              </div>
            </div> */}
            {/* Order Amount */}
            {/* <div className="bg-[#EEEEEE] mt-8 mb-10 w-[40%] drop-shadow-md border flex flex-col gap-8 px-3 py-4">
              <h2 className="font-bold text-lg">Order Amount</h2>
              <div className="flex flex-row justify-between">
                <table className="w-full">
                  <tbody>
                    <tr>
                      <td className="border border-black px-6 py-4">
                        Subtotal :
                      </td>
                      <td className="border border-black px-6 py-4">
                        {data1.subtotal}
                      </td>
                    </tr>
                    <tr>
                      <td className="border border-black px-6 py-4">
                        Shipping :
                      </td>
                      <td className="border border-black px-6 py-4">
                        {data1.shipping}
                      </td>
                    </tr>
                    <tr>
                      <td className="border border-black px-6 py-4">Tax :</td>
                      <td className="border border-black px-6 py-4">
                        {data1.tax}
                      </td>
                    </tr>
                    <tr>
                      <td className="border border-black px-6 py-4">
                        Coupon :
                      </td>
                      <td className="border border-black px-6 py-4">
                        {data1.coupon}
                      </td>
                    </tr>
                    <tr>
                      <td className="border border-black px-6 py-4">Total :</td>
                      <td className="border border-black text-xl text-red-800 px-6 py-4">
                        {data1.amount}
                      </td>
                    </tr>
                  </tbody>
                </table>
              </div>
            </div> */}
          </div>
        </div>
      </div>
    </>
  );
};

export default Vieworder;
