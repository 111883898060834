import React, { useState, useEffect } from "react";
import Table from "../../../UI/CommonTable/Table";
import { deleteIcon, Photo, View, Edit } from "./Assets/index";
import TopHeader from "../../../UI/TopHeader/TopHeader";
import { useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import {
  CRM,
  DeleteRelation,
  Order_Table,
  VeiwMember,
  Reward_Table,
  Internal_Notes,
  appcrm,
} from "../../User_Management/features/userSlice";
import { Grid } from "react-loader-spinner";
import axios from "axios";
import { Alert, AlertTitle, Button } from "@mui/material";
import cookie from "js-cookie";
import { getUserLogin } from "../../User_Management/features/userSlice";
import { tssurl, url1 } from "../../../UI/port";
import { useParams } from "react-router-dom";
import Navclose_Header from "../../DashBoard_Screen/Component/Navclose_Header";
// Component inside action column
const Action = ({ username, mid, walletBalance, email }) => {
  const Navigate = useNavigate();
  // const dispatch = useDispatch();
  const [showDeleteConfirmation, setShowDeleteConfirmation] = useState(false);
  const handleClick = () => {
    const sendData = {
      walletBalance,
      mid,
      email,
      username,
    };
    Navigate(`/home/memberDetails/${mid}`, { state: sendData });
    window.location.reload();
  };
  const dispatch = useDispatch();
  const handleDeleteClick = () => {
    setShowDeleteConfirmation(true);
  };
  useEffect(() => {
    dispatch(getUserLogin(localStorage.getItem("uid")));
  }, [dispatch]);
  const userData = useSelector((state) => state.userManagement.users);

  const handleConfirmDelete = () => {
    dispatch(DeleteRelation(mid))
      .then(() => {
        window.location.reload();
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const handleCancelDelete = () => {
    setShowDeleteConfirmation(false);
  };

  const roles = userData.role;

  return (
    <div className="justify-center h-6 flex gap-3 cursor-pointer">
      {/* <img src={deleteIcon} onClick={handleDeleteClick} alt="Delete" /> */}
      <img src={View} onClick={handleClick} alt="Edit" />
      {/* {roles === "admin" || roles === "editor" ? (
        <>
          <img src={deleteIcon} onClick={handleDeleteClick} alt="Delete" />
        </>
      ) : null} */}
      {showDeleteConfirmation && (
        <div className="fixed top-0 left-0 w-screen h-screen bg-gray-800 bg-opacity-50 flex justify-center items-center z-50">
          <div className="bg-white p-5 rounded shadow">
            <Alert severity="warning">
              <AlertTitle>Confirmation</AlertTitle>
              <p className="pt-5">
                Are you sure you want to delete {username}?
              </p>
              <div className="p-5">
                <Button onClick={handleConfirmDelete} color="error" autoFocus>
                  Delete
                </Button>
                <Button onClick={handleCancelDelete} color="inherit">
                  Cancel
                </Button>
              </div>
            </Alert>
          </div>
        </div>
      )}
    </div>
  );
};

const ProfilePhoto = ({ picUrl }) => {
  return (
    <div className="flex justify-center">
      <img className="w-12 h-12 rounded-full" src={picUrl} alt="photo" />
    </div>
  );
};

const Allmembers = ({ setActiveTab, setExpand, showMenuHide }) => {
  const head = "App Users";
  setActiveTab("AppUsers");
  // setExpand("customerRelationship");
  const dispatch = useDispatch();
  const [loading, setLoading] = useState(true);
  const memberData1 = useSelector((state) => state.userManagement.appcrm);
  const memberData = useSelector((state) => state.userManagement.crm);
  console.log(memberData1, "ssdsjdhdsjshd");
  const { page } = useParams(); // Get the page parameter from the route
  const currentPage = parseInt(page);
  console.log(currentPage);

  useEffect(() => {
    fetchUserData();
  }, [page]); // Refetch data when currentPage changes

  const fetchUserData = async () => {
    setLoading(true);
    await dispatch(appcrm(page)); // Pass currentPage to CRM action creator
    setLoading(false);
  };
  // useEffect(() => {
  //   const fetchUserData = async () => {
  //     setLoading(true);
  //     await dispatch(CRM());
  //     setLoading(false);
  //   };
  //   fetchUserData();
  // }, [dispatch]);

  const columns = [
    {
      header: "Photo",
      accessor: "photo",
    },
    {
      header: "Username",
      accessor: "username",
    },
    {
      header: "Wallet Balance",
      accessor: "paymenthistory",
    },
    {
      header: "Charging History",
      accessor: "purchasehistory",
    },
    {
      header: "Contact Info.",
      accessor: "contact",
    },
    {
      header: "Action",
      accessor: "action",
    },
  ];
  console.log("This is memeber Data :", memberData);

  const data = memberData1?.map((user) => ({
    photo: <ProfilePhoto picUrl={user?.pic_url} />,
    username: user.name,
    rewardpoints: user.reward_points,
    paymenthistory: `₹ ${user.walletBallence}`,
    purchasehistory: `${user.chargingHistory.length} Sessions`,
    contact: `${user.email}`,
    // console.log(user);
    action: (
      <Action
        mid={user.mid}
        username={user?.name || "user"}
        walletBalance={user?.walletBallence || "0"}
        email={user.email || "not available"}
      />
    ),
  }));

  const pageSize = 5;
  const greenButtonText = "Export All";
  // const blackButtonText = "Add Members";

  // const handleButtonClick = () => {
  //   axios.get('http://139.59.236.50:8000/exportcustomers')
  //   .then((response)=>{
  //     console.log(response)
  //   }).catch((err)=>{
  //     console.log('Error to call API', err)
  //   })
  // }
  const navigate = useNavigate();
  // const blackClicked = () => {
  // navigate("/home/addMembers");
  // };

  return (
    <div className="w-full px-5">
      <div className="flex">
        <TopHeader className="" head={head} />
      </div>
      {loading ? (
        <>
          {/* <div className="fixed inset-0 bg-gray-700 opacity-80 flex justify-center items-center z-50">
          <Grid
            height="80"
            width="80"
            color="#4fa94d"
            ariaLabel="grid-loading"
            radius="12.5"
            wrapperStyle={{}}
            wrapperClass=""
            visible={true}
          />
        </div> */}
        </>
      ) : null}
      <div className="mt-10">
        {showMenuHide && <Navclose_Header head={head} />}
        {memberData.length > 0 ? (
          <Table
            columns={columns}
            data={data}
            pageSize={pageSize}
            greenButtonText={
              <a href={`${tssurl}/user/exportUsers`}>{greenButtonText}</a>
            }
            // blackButtonText={blackButtonText}
            // blackClicked={blackClicked}
          />
        ) : (
          <>
            <Table
              columns={columns}
              data={data}
              pageSize={pageSize}
              greenButtonText={
                <a href={`${tssurl}/user/exportUsers`}>{greenButtonText}</a>
              }
              // blackButtonText={blackButtonText}
              // blackClicked={blackClicked}
            />
            {!data && (
              <div className="flex ml-5 justify-center w-full mt-40">
                <h2 className="text-4xl font-bold text-gray-500">No Data!</h2>
              </div>
            )}
          </>
        )}
      </div>
    </div>
  );
};

export default Allmembers;
