import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faLocationDot, faCalendarDays, faUser, faPeopleArrows, faClock } from '@fortawesome/free-solid-svg-icons';

// Import your document images
import document1 from './document1.png';
import document2 from './document2.png';
import document3 from './Group.png';
import name from './name.png';
import jsPDF from 'jspdf';

function Card({ card ,index}) {
    const dateTime = new Date(card?.timestamp);

  // Format date and time
  const formattedDate = `${dateTime.toLocaleDateString()} `;
  const formattedTime = `${dateTime.toLocaleTimeString()} `;


const handleDocumentDownload = (documentType, documentContent) => {
    if (documentType === 'text') {
      // For text documents
      const blob = new Blob([documentContent], { type: 'text/plain' });
      const anchor = document.createElement('a');
      anchor.download = `document.txt`;
      anchor.href = URL.createObjectURL(blob);
      document.body.appendChild(anchor);
      anchor.click();
      document.body.removeChild(anchor);
    } else if (documentType === 'pdf') {
      // For PDF documents
      // You need to provide the PDF content or a direct download link to the PDF file
      // For example, you can use a library like jsPDF to generate PDFs from HTML content
      // Or, you can provide a direct download link to the PDF file
      // Replace 'pdfContent' with the actual PDF content or the URL of the PDF file
    //   const pdfContent = 'Replace with PDF content or URL';
    const pdf = new jsPDF();

    // Add text content to the PDF
    pdf.text(documentContent, 10, 10);

    // Save the PDF
    pdf.save('document.pdf');
    }
  };
  
//   console.log(formattedDateTime,"askchjbhb");
  return (
    <div className="bg-white text-black shadow-md rounded-md p-6">
      <div className="flex items-center justify-between">
        <div className="flex items-center">
          <img src={document3} alt="Document 1" className="w-5 h-5 mr-2" />
          <h5 className="font-bold">Version {index}</h5>
        </div>
       
      </div>
      <div className="flex items-center mt-4">
        <FontAwesomeIcon icon={faCalendarDays} className="mr-2" />
        <span className="truncate text-gray-500">{formattedDate}</span>
        <div className="flex-grow" />
        <FontAwesomeIcon icon={faClock} className="ml-2" />
        <span className="truncate font-bold" style={{ maxWidth: '200px' }}>{formattedTime}</span>
      </div>
      <div className="flex items-center justify-between mt-4">
        <b><span className="truncate text-gray-500">{card?.userName}</span></b>
        <div className="flex items-center text-gray-500">
          {/* <FontAwesomeIcon icon={faLocationDot} className="mr-2" /> */}
          <span className="ml-2">{card?.role}</span>
        </div>
      </div>
      <h5 className="font-bold mt-6">Code</h5>
      <p className="mt-2 line-clamp-2">{card?.code}</p>
      <h5 className="font-bold mt-6">Downloads</h5>
      <div className="flex items-center">
        <a href="#" onClick={() => handleDocumentDownload('text',card?.code)}>
          <img src={document2} alt="Document 1" className="w-4 h-5 mr-2" />
        </a>
        {/* <a href="#" onClick={() => handleDocumentDownload('pdf',card?.code)}>
          <img src={document1} alt="Document 1" className="w-4 h-5 mr-2" />
        </a> */}
        <a href="#" onClick={() => handleDocumentDownload('pdf',card?.code)}>
          <img src={document1} alt="Document 1" className="w-4 h-5 mr-2" />
        </a>
      </div>
    </div>
  );
}

export default Card;
