import React, { useEffect, useRef } from "react";
import Chart from "chart.js/auto";

const GraphComponent = ({ xLabelData, ylabelData, ylabelName }) => {
  const chartRef = useRef(null);
  let myChart = null;

  useEffect(() => {
    if (chartRef.current) {
      if (myChart) {
        myChart.destroy(); // Destroy previous chart instance
      }

      const ctx = chartRef.current.getContext("2d");
      myChart = new Chart(ctx, {
        type: "bar",
        data: {
          labels: xLabelData,
          datasets: [
            {
              label: ylabelName || "Power",
              data: ylabelData,
              backgroundColor: [
                "rgba(255, 99, 132, 0.2)",
                "rgba(54, 162, 235, 0.2)",
                "rgba(255, 206, 86, 0.2)",
                "rgba(75, 192, 192, 0.2)",
                "rgba(153, 102, 255, 0.2)",
                "rgba(255, 159, 64, 0.2)",
              ],
              borderColor: [
                "rgba(255, 99, 132, 1)",
                "rgba(54, 162, 235, 1)",
                "rgba(255, 206, 86, 1)",
                "rgba(75, 192, 192, 1)",
                "rgba(153, 102, 255, 1)",
                "rgba(255, 159, 64, 1)",
              ],
              borderWidth: 1,
            },
          ],
        },
        options: {
          scales: {
            x: {
              title: {
                display: true,
                text: "Time",
              },
            },
            y: {
              title: {
                display: true,
                text: ylabelName || "Power",
              },
              beginAtZero: true,
            },
          },
        },
      });
    }

    return () => {
      if (myChart) {
        myChart.destroy(); // Cleanup on component unmount
      }
    };
  }, [ylabelData, xLabelData, ylabelName]);

  return (
    <div className="w-full">
      <canvas ref={chartRef} />
    </div>
  );
};

export default GraphComponent;
