import React, { useState, useEffect } from "react";
import "./Login.css";
import axios from "axios";
import { Link, useNavigate } from "react-router-dom";
import TextInputs from "./TextInputs";
import CircleComponent from "./CircleComponent";
import { GoogleLogin } from "react-google-login";
import { gapi } from "gapi-script";
import SuccessToast from "../../Toast/SuccessToast";
import ErrorToast from "../../Toast/ErrorToast";
import { tssurl } from "../../../UI/port";
import { getUserLogin } from "../../User_Management/features/userSlice";
import { useDispatch } from "react-redux";

const New_login = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [result, setResult] = useState(false);
  const [showSuccessToast, setShowSuccessToast] = useState(false);
  const [showErrorToast, setShowErrorToast] = useState(false);
  const [toastMessage, setToastMessage] = useState("");
  const [formData, setFormData] = useState({
    uname: "",
    pass: "",
  });

  const handleInputchange = (fieldName, value) => {
    setFormData((prevData) => ({
      ...prevData,
      [fieldName]: value,
    }));
  };
  console.log(formData);
  useEffect(() => {
    if (result == true) {
      let login = document.getElementById("login");
      setTimeout(() => {
        login.style.transition = "0.5s";
        login.style.opacity = "0";
      }, 1400);
      setTimeout(() => {
        login.style.display = "none";
        setResult(false);
      }, 1500);
    }
  }, [result]);
  // const handleLoginClick = (e) => {
  //   e.preventDefault();

  //   console.log("login button clicked", formData);

  //   setFormData({
  //     email: "",
  //     password: "",
  //   });
  // };

  useEffect(() => {
    if (localStorage.getItem("jwt")) {
      navigate("/home");
    }
  }, []);

  const handleLoginClick = async (e) => {
    e.preventDefault();
  
    try {
      const response = await axios.post(`${tssurl}/admin/login`, formData);
  
      console.log(response);
      const token = response.data.authToken;
      localStorage.setItem("jwt", token);
      localStorage.setItem("uid", response.data.uid);
      await dispatch(getUserLogin(response.data.uid));
      setResult("Logged in Successfully");
      setToastMessage("Login successful");
      setShowSuccessToast(true);
      setTimeout(() => {
        setShowSuccessToast(false);
        navigate("/home"); // Navigate to dashboard after login
        sessionStorage.setItem("jwt", token);
      }, 3000);
  
      // Clear form data
      setFormData({
        uname: "",
        pass: "",
      });
    } catch (error) {
      console.error("Login error:", error);
      setToastMessage("Login failed. Please try again.");
      setShowErrorToast(true);
      setTimeout(() => {
        setShowErrorToast(false);
      }, 3000);
    }
  };
  

  useEffect(() => {
    const loadGoogleSignInScript = () => {
      const script = document.createElement("script");
      script.src = "https://accounts.google.com/gsi/client";
      script.async = true;
      script.defer = true;
      script.onload = () => initializeGoogleSignIn();
      document.body.appendChild(script);
    };

    const initializeGoogleSignIn = () => {
      window.onSignInSuccess = (response) => {
        console.log("Signed in successfully:", response);
        const idToken = response.getAuthResponse().id_token;
        axios.defaults.headers.common["Authorization"] = `Bearer ${idToken}`;
        // Perform any additional actions after successful sign-in, such as navigating to another page.
      };

      window.onSignInFailure = (error) => {
        console.error("Error signing in:", error);
        // Handle sign-in failure as needed.
      };

      window.google.accounts.id.initialize({
        client_id:
          "156987578910-u2mg62hrg7dk6d2deunerts475sr59mb.apps.googleusercontent.com",
        callback: {
          on_success: window.onSignInSuccess,
          on_failure: window.onSignInFailure,
        },
      });

      window.google.accounts.id.renderButton(
        document.getElementById("signInDiv"),
        { theme: "outline", size: "large" }
      );
    };

    loadGoogleSignInScript();
  }, []);
  // const handleGoogleLoginSuccess = (response) => {
  //   console.log('Google login success:', response);
  //   // Perform actions like saving user data or navigating to another page
  //   const token = response.tokenId;
  //   // Example: Send token to backend
  //   fetch('http://localhost:5200/client/google-login', {
  //     method: 'POST',
  //     headers: {
  //       'Content-Type': 'application/json'
  //     },
  //     body: JSON.stringify({ token })
  //   })
  //   .then(response => response.json())
  //   .then(data => {
  //     // Handle response from backend
  //     console.log('Backend response:', data);
  //   })
  //   .catch(error => console.error('Error:', error))
  // };
  const handleGoogleLoginSuccess = (response) => {
    console.log("Google login success:", response);

    const tokenId = response.tokenId;
    // Make a POST request to your backend API
    axios
      .post(`${tssurl}/login`, { tokenId })
      .then(async (response) => {
        // setUsername("");
        // setPassword("");
        console.log(response);
        const token = response.data.authToken;
        localStorage.setItem("jwt", token);
        localStorage.setItem("uid", response.data.uid);
        await dispatch(getUserLogin(response.data.uid));
        setResult("Logged in Successfully");
        sessionStorage.setItem("jwt", token);
        navigate("/home");
      })
      .catch((error) => {
        console.error("Error:", error);
        // Handle errors as needed
      });
  };

  const handleGoogleLoginFailure = (error) => {
    console.error("Google login failure:", error);
    // Handle errors as needed
  };
  useEffect(() => {
    function start() {
      gapi.client.init({
        clientId:
          "156987578910-u2mg62hrg7dk6d2deunerts475sr59mb.apps.googleusercontent.com",
        scope: "email",
      });
    }

    gapi.load("client:auth2", start);
  }, []);

  return (
    <>
      <div className="flex justify-center items-center w-full h-screen ">
        <div className="flex w-2/3 h-3/4 border border-[#CACACA] rounded-xl overflow-hidden ">
          <div className="w-1/2 relative overflow-hidden bg-[#C1E0C2]">
            <div className="absolute top-5 left-5 z-20 text-xl text-[#118615] font-bold">
              Welcome!
            </div>
            <div className="absolute flex flex-col justify-center items-center w-full h-full ">
              <div className="w-44 h-20 rounded-xl bg-white z-30">
                <img className="cover" src="/images/logoj.png" alt="logo" />
              </div>
              <h1 className="text-xs text-[#5E5E5E] mt-2">
                Energizing Greener Future
              </h1>
            </div>
            <SuccessToast
            
            message={toastMessage}
            open={showSuccessToast}
            onClose={() => setShowSuccessToast(false)}
          />
          <ErrorToast
            message={toastMessage}
            open={showErrorToast}
            onClose={() => setShowErrorToast(false)}
          />
            <div className="absolute top-0 bottom-0 flex -ml-28 items-center">
              <CircleComponent />
            </div>
            <div className="absolute h-full right-0 flex flex-col  justify-between -mr-24">
              <div className="-rotate-45 -mt-28 ">
                <CircleComponent mw={20} />
              </div>
              <div className="rotate-45 -mb-28">
                <CircleComponent />
              </div>
            </div>
          </div>

          <div className="flex justify-center items-center p-5 w-1/2   ">
            <div className="p-5 w-3/4  bg-white">
              <div className="flex justify-center items-center text-lg text-[#6B6A6A] font-bold w-full">
                <div className="w-full border-b border-[#CACACA]"></div>

                <span className="mx-5"> Login</span>
                <div className="w-full border-b border-[#CACACA]"></div>
              </div>
              <div className="w-auto ">
                <form onSubmit={handleLoginClick}>
                  <div className="my-8 ">
                    <div className="w-full mb-8 ">
                      <TextInputs
                        type={"text"}
                        title={"Username "}
                        name="uname"
                        value={formData.uname}
                        placeholder="Enter your username"
                        onInputChange={(value) =>
                          handleInputchange("uname", value)
                        }
                      />
                    </div>
                    <div className="w-full">
                      <TextInputs
                        type={"password"}
                        title={"Password"}
                        name="pass"
                        value={formData.pass}
                        placeholder="Password"
                        onInputChange={(value) =>
                          handleInputchange("pass", value)
                        }
                      />
                    </div>
                    <div className="flex justify-end">
                      <span className="text-[#118615] underline text-sm">
                        Forgot Password
                      </span>
                    </div>
                  </div>

                  <div className="md:flex items-center">
                    <div className="flex justify-center w-full">
                      <button
                        // onClick={handleLoginClick}
                        type="submit"
                        className="w-full p-1 bg-[#118615] text-xl text-white rounded-md "
                      >
                        Login
                      </button>
                    </div>
                  </div>
                </form>
                <GoogleLogin
                  clientId="156987578910-u2mg62hrg7dk6d2deunerts475sr59mb.apps.googleusercontent.com"
                  buttonText="Login with Google"
                  onSuccess={handleGoogleLoginSuccess}
                  onFailure={handleGoogleLoginFailure}
                  cookiePolicy={"single_host_origin"}
                />
                <div className="text-sm text-center my-2 text-[#666666]">
                  Don’t have an account?
                  <Link
                    to={"/newregister"}
                    className="text-[#118615] underline "
                  >
                    Sign up
                  </Link>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default New_login;
