import React, { useEffect, useState } from "react";

import TopHeader from "../../../UI/TopHeader/TopHeader";
import { useDispatch, useSelector } from "react-redux";
import { useLoaderData, useLocation, useParams } from "react-router-dom";
import Tabs from "./Tabs";

import {
  getAppuserChargerHistory,
  getUserLogin,
} from "../../User_Management/features/userSlice";

import Navclose_Header from "../../DashBoard_Screen/Component/Navclose_Header";
import CMSDetailsCard from "../UserDetails/CMSDetailsCard";
import {
  calculateTotalAppUserChargingCost,
  calculateTotalAppUserUsedEnergy,
  formatToThreeDecimalPlaces,
} from "../../../utility";
import {
  calculateTimeDifference,
  calculateTimeDifferenceStartTimeEndTime,
} from "../../calculations/Totalenergy";

const ShowChargerNamePid = (chargername, pid) => {
  if (!chargername || !pid) return;
  return (
    <>
      <p>{chargername}</p>
      <p className="m-1">({pid})</p>
    </>
  );
};

const MemberDetails = ({
  setActiveTab,
  setExpand,
  showMenuHide,
  setsubMenu,
  setShowMenuHide,
}) => {
  setActiveTab("AppUsers");
  setExpand("customerRelationship");
  setsubMenu("/User Details");
  const head = "User Details";
  const location = useLocation();
  const userData = location.state;
  const { mid } = useParams();
  console.log("mimimimid", mid, userData);
  const dispatch = useDispatch();
  const populateChargerHistoryData = true;
  const getAppuserChargerHistoryData = useSelector(
    (state) => state.userManagement.getAppuserChargerHistory
  );

  const [username, setusername] = useState(userData?.username);
  const [email, setemail] = useState(userData?.email);
  const [AppUserData, setAppUserData] = useState([]);
  const [showChargingCost, setShowChargingCost] = useState(0);
  const [showChargingEnergy, setShowChargingEnergy] = useState(0);
  const [showTotalUtiliazation, setShowTotalUtiliazation] = useState("0");

  useEffect(() => {
    dispatch(getAppuserChargerHistory({ mid, populateChargerHistoryData }));
  }, [mid]);
  console.log(
    "getAppuserChargerHistoryDatagetAppuserChargerHistoryData",
    getAppuserChargerHistoryData
  );

  useEffect(() => {
    if (
      !getAppuserChargerHistoryData ||
      !getAppuserChargerHistoryData.length ||
      !Array.isArray(getAppuserChargerHistoryData)
    )
      return;
    setShowTotalUtiliazation(
      calculateTimeDifference(getAppuserChargerHistoryData)
    );
    setShowChargingCost(
      calculateTotalAppUserChargingCost(getAppuserChargerHistoryData)
    );
    setShowChargingEnergy(
      calculateTotalAppUserUsedEnergy(getAppuserChargerHistoryData)
    );

    const dataforTable = getAppuserChargerHistoryData
      .slice()
      .reverse()
      .map((user, index) => ({
        serialno: index + 1,
        date: user?.Date,
        paymentid: user?.paymentId,
        chargername: ShowChargerNamePid(user?.ChargerName, user?.pid), // build component
        inputamount: user?.inputCost,
        starttime: user?.StartTime,
        endtime: user?.EndTime,
        charingcost: formatToThreeDecimalPlaces(user?.chargingCost),
        energy: user?.EnergyUsed,
        chargingtime: calculateTimeDifferenceStartTimeEndTime(
          user?.StartTime,
          user?.EndTime
        ),
      }));

    setAppUserData(dataforTable);
  }, [getAppuserChargerHistoryData]);

  return (
    <>
      <div className="w-full">
        <div
          className="w-full px-5"
          onClick={() => {
            setShowMenuHide(false);
          }}
        >
          <div className="" style={{}}>
            <TopHeader className="mb-10" head={head} />
          </div>
          {showMenuHide && <Navclose_Header head={head} />}

          <div className="flex mt-4 ">
            <div className="w-2/3 p-5 bg-stone-200  overflow-hidden">
              <p className="text-xl text-gray-900 mb-1">User information</p>
              <hr className="bg-gray-400 rounded-sm h-0.5" />
              <div className=" flex flex-col justify-evenly h-full">
                <div className=" space-y-4">
                  {/* flex flex-col justify-evenly h-full */}
                  <div className="flex flex-row items-center justify-between mb-2">
                    <label className="me-1 flex-shrink-0">User ID :</label>
                    <input
                      className="px-4 py-2 drop-shadow-md rounded-md w-[75%]"
                      type="text"
                      placeholder="Enter User ID"
                      required
                      name="uid"
                      value={mid}
                      disabled={true}
                    />
                  </div>
                  <div className="flex flex-row items-center justify-between mb-2">
                    <label className="me-1 flex-shrink-0">Name :</label>
                    <input
                      className="px-4 py-2 drop-shadow-md rounded-md w-[75%]"
                      type="text"
                      placeholder="Enter Name"
                      required
                      name="name"
                      value={username}
                      onChange={(e) => {
                        setusername(e.target.value);
                      }}
                    />
                  </div>
                  <div className="flex flex-row items-center justify-between mb-2">
                    <label className="me-1 flex-shrink-0">Email id :</label>
                    <input
                      className="px-4 py-2 drop-shadow-md rounded-md w-[75%]"
                      type="text"
                      placeholder="Enter Email"
                      required
                      name="email"
                      value={email}
                      onChange={(e) => {
                        setemail(e.target.value);
                      }}
                    />
                  </div>
                  <div className="flex flex-row items-center justify-between mb-2">
                    <label className="me-1 flex-shrink-0">
                      Wallet Balance :
                    </label>
                    <input
                      className="px-4 py-2 drop-shadow-md rounded-md w-[75%]"
                      type="text"
                      placeholder="Enter Wallet Balance"
                      required
                    />
                  </div>
                </div>
                <div className="mb-4">
                  <button className="w-full px-4 py-2 bg-blue-500 text-white rounded hover:bg-blue-700">
                    Update
                  </button>
                </div>
              </div>
            </div>

            <div className="w-1/3">
              {/* First Row */}
              <div className="h-full flex justify-evenly flex-wrap items-center">
                <CMSDetailsCard
                  title={"Total Energy"}
                  data={showChargingEnergy}
                />
                <CMSDetailsCard
                  title={"Total Utilization"}
                  data={showTotalUtiliazation}
                />
                <CMSDetailsCard
                  title={"Total Charging Cost"}
                  data={showChargingCost}
                />
                <CMSDetailsCard
                  title={"Wallet Balance"}
                  data={Math.floor((userData?.walletBalance || 0) * 100) / 100}
                />
              </div>
            </div>
          </div>

          <div className="" style={{ marginTop: "20px", zIndex: -1 }}>
            <Tabs mid={mid} AppUserData={AppUserData} />
          </div>
        </div>
      </div>
    </>
  );
};

export default MemberDetails;
