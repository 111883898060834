import React from "react";
import Table from "../../../UI/CommonTable/Table";
import { deleteIcon, images } from "../Assets/index";
import TopHeader from "../../../UI/TopHeader/TopHeader";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { useEffect, useState } from "react";
import { Grid } from "react-loader-spinner";
import {
  HSM_transaction,
  VeiwOrderHSM,
} from "../../User_Management/features/userSlice";
import { tssurl } from "../../../UI/port";
import Navclose_Header from "../../DashBoard_Screen/Component/Navclose_Header";

const ProfilePhoto = ({ picUrl }) => {
  return (
    <div>
      <img className="w-12 h-12 rounded-full" src={picUrl} alt="photo" />
    </div>
  );
};

const OrderDetails = ({
  oid,
  payment_status,
  delivery_status,
  tracking_id,
}) => {
  const navigate = useNavigate();
  const data = {
    payment_status: payment_status,
    oid: oid,
    delivery_status: delivery_status,
    tracking_id: tracking_id,
    tab: "hsm",
  };
  const viewOrder = () => {
    console.log(oid);
    // dispatch(VeiwOrder(oid))
    navigate("/home/viewOrder", { state: data });
  };
  // console.log(oid)
  return (
    <button
      className="bg-blue-500 px-2 py-2 rounded-l text-white coursor-pointer hover:bg-blue-600"
      onClick={viewOrder}
    >
      View Order
    </button>
  );
};

const Purchases = ({ setActiveTab, setExpand, showMenuHide }) => {
  setExpand("orderManagement");
  setActiveTab("orderManagement");
  const dispatch = useDispatch();
  const head = "Order Management";
  const [loading, setLoading] = useState(true);

  const transactionData = useSelector(
    (state) => state.userManagement.hsm_transaction
  );

  console.log("transactionData",transactionData);
  useEffect(() => {
    const fetchUserData = async () => {
      setLoading(true);
      await dispatch(HSM_transaction());
      setLoading(false);
    };
    fetchUserData();
  }, [dispatch]);

  const Buyer_details = ({ name, mobileno }) => {
    return (
      // {productData.map((data)=>(
      <div>
        <div className="flex flex-row">
          <label className="text-[#c91b0e]">Name : </label>
          <p>&nbsp; {name ? name : "no"}</p>
        </div>
        <div className="flex flex-row">
          <label className="text-[#c91b0e]">Mobile No : </label>
          <p>&nbsp; {mobileno ? mobileno : "no"}</p>
        </div>
        {/* <div className="flex flex-row">
          <label className="text-[#c91b0e]">Rating : </label>
          <p>&nbsp; {rating ? rating : "N/A"} </p>
        </div> */}
      </div>
      // ))}
    );
  };

  const ProductPurchased = ({ products }) => {
    console.log("product_purchased", products);
    return (
      <>
        {products?.map((e, index) => (
          <div key={index}>
            <div className="flex flex-row">
              <label className="text-[#c91b0e]">{e.name} : </label>
              <p>&nbsp; {e.OrderPids ? e.OrderPids.length : "0"}</p>
            </div>
          </div>
        ))}
      </>
    );
  };

  const columns_order = [
    {
      header: "S.No.",
      accessor: "serialno",
    },
    {
      header: "Date",
      accessor: "date",
    },
    {
      header: "Order ID",
      accessor: "order_id",
    },
    {
      header: "Company",
      accessor: "company",
    },
    {
      header: "Buyer Details",
      accessor: "buyer_details",
    },
    {
      header: "Product Purchased",
      accessor: "product_purchased",
    },
    {
      header: "Total Bill",
      accessor: "amount",
    },
    {
      header: "Status",
      accessor: "status",
    },
    {
      header: "Action",
      accessor: "action",
    },
  ];

  let count = 1;
  const data_order = transactionData.map((user) => ({
    serialno: count++,
    pic_url: <ProfilePhoto picUrl={user.pic_url} />,
    order_id: user._id,
    amount: user.TotalAmount ? `${user.TotalAmount}+ GST` : `0 + GST`,
    payment_method: user.payment_mode,
    status:
      Array.isArray(user.status) && user.status.length > 0
        ? user.status[user.status.length - 1].status
        : "no",
    date: user.date,
    company: user?.company,
    buyer_details: (
      <Buyer_details mobileno={user.mobileNumber} name={user.buyerName} />
    ),
    product_purchased: <ProductPurchased products={user?.products || []} />,
    action: (
      <OrderDetails
        // mid={mid}
        oid={user._id}
        payment_status={user.payment_status}
        delivery_status={user.delivery_status}
        tracking_id={user.tracking_id}
      />
    ),
  }));

  // Number of Pages to be display on a single page.
  const greenButtonText = "Export All";
  const pageSize = 4;

  return (
    <div className="w-full px-5">
      <div className="flex">
        <TopHeader className="fixed" head={head} />
      </div>

      {loading ? (
        <div className="fixed inset-0 bg-gray-700 opacity-80 flex justify-center items-center z-50">
          <Grid
            height="80"
            width="80"
            color="#4fa94d"
            ariaLabel="grid-loading"
            radius="12.5"
            wrapperStyle={{}}
            wrapperClass=""
            visible={true}
          />
        </div>
      ) : null}

      <div className="mt-10">
        {showMenuHide && <Navclose_Header head={head} />}
        {transactionData.length > 0 ? (
          <Table
            columns={columns_order}
            data={data_order}
            pageSize={pageSize}
            greenButtonText={
              <a href={`${tssurl}/oders/export`}>{greenButtonText}</a>
            }
          />
        ) : (
          <>
            <Table
              columns={columns_order}
              data={data_order}
              pageSize={pageSize}
              greenButtonText={
                <a href={`${tssurl}/oders/export`}>{greenButtonText}</a>
              }
            />
            <div className="flex ml-5 justify-center w-full mt-40">
              <h2 className="text-4xl font-bold text-gray-500">No Data!</h2>
            </div>
          </>
        )}
      </div>
    </div>
  );
};

export default Purchases;
