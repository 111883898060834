import React from "react";

function Navclose_Header(props) {
  return (
    <div className="text-lg md:text-xl lg:text-2xl py-1 font-semibold text-[#605C5C] hidden xl:block">
      {props.head}
    </div>
  );
}

export default Navclose_Header;
