import mqtt from "mqtt";
const brokerUrl = "wss://mqtt.jouls.co.in/mqtt";


export const applyApiLogic = (apiProducts) => {
    // Apply your logic here (for example, filtering or modifying products)
    return apiProducts.map((product) => ({
      pid: product?.pid,
      remark: "",
      status: "",
      AppUsername: "No User",
      chargername: product?.ChargerName,
    }));
  };


  export const getLastAppUsernameByPid = (data, pid) => {
    if (!Array.isArray(data)) {
      return "Data Must Be Array";
    }
    const filteredArray = data.filter((item) => item.pid === pid);
  
    if (filteredArray.length === 0) {
      return { chargerStatus: "", UsedBy: "" };
    }
  
    const lastObject = filteredArray[filteredArray.length - 1];
    return {
      UsedBy: lastObject.UsedBy,
      chargerStatus: lastObject.chargerStatus,
    };
  }; 

 
 export  const handleConnect = (data) => {
    const client = mqtt.connect(brokerUrl);
    // Connection successful
    client.on("connect", () => {
      console.log("Connected to MQTT broker");

      // Subscribe to PID topic
      if (data.length > 0) {
        data.forEach((e) => {
          const topics = [`${e.pid}_Updates`, `${e.pid}_Charging_Data`];
          topics.forEach((topic) => {
            client.subscribe(topic, (err) => {
              if (!err) {
                console.log("Subscribed to", topic);
              } else {
                console.error("Error subscribing to", topic, ":", err);
              }
            });
          });
        });
      } else {
        console.log("data nhi hai alldata me ");
      }
    });
    return client
  }; 


 export const applyMqttLogic = async (AllPidDataWithMessages,topic, message,setCheckmqttMessagesReceivedCounter,fetchData) => {
    return await Promise.all(
      AllPidDataWithMessages.map(async (obj) => {
        if (`${obj?.pid}_Charging_Data` === topic.toString()) {
          try {
            const data = await fetchData(obj?.pid);
            console.log("datdatadaata", data);
            // If the API call is successful, return the data to be set
            // setApiCalledChargingData(true);
            return {
              ...obj,
              status: "Charging Started",
              AppUsername: data?.UsedBy || "Error",
            };
          } catch (error) {
            // If there's an error in the API call, handle it here
            console.error("Error in API call:", error);
            // You can return a default value or handle the error according to your needs
            return {
              ...obj,
              status: "Charging Started",
              AppUsername: "Unknown",
            };
          }
        }
        if (`${obj?.pid}_Updates` === topic.toString()) {
          // Handle logic for other topic
          if (message.toString() === "Idle") {
            setCheckmqttMessagesReceivedCounter({});
            return {
              ...obj,
              status: message.toString(),
              AppUsername: "No User",
            };
          }
          if (message.toString() === "Door is open") {
            setCheckmqttMessagesReceivedCounter({});
            return {
              ...obj,
              status: "Charger Connected",
              AppUsername: "No User",
            };
          }
          if (message.toString() === "Charging Started") {
            return {
              ...obj,
              status: "Charging Started",
              AppUsername: "Radhe ajmer",
            };
          }
          if (message.toString() === "Charging Completed") {
            setCheckmqttMessagesReceivedCounter({});
            return {
              ...obj,
              status: "Charging Completed",
              AppUsername: "No User",
            };
          }
        } else {
          // For other objects, return as is
          return obj;
        }
      })
    );
  }; 


  export function formatToThreeDecimalPlaces(number) {
    try {
      // Convert the input to a number if it's a string
      number = parseFloat(number);
  
      // Check for NaN or undefined
      if (isNaN(number) || number === undefined) {
        return "0.000";
      }
  
      const factor = Math.pow(10, 3);
      const result = Math.floor(number * factor) / factor;
  
      // Ensure the result has three decimal places
      return result.toFixed(3);
    } catch (error) {
      return "0.000";
    }
  }

  
  export function calculateTotalAppUserChargingCost(chargingData) {
    if (!Array.isArray(chargingData)) {
      return 0;
    }
    const totalCost = chargingData.reduce((sum, current) => {
      const cost = parseFloat(current.chargingCost) || 0;
      return sum + cost;
    }, 0);
    
    return parseFloat(totalCost.toFixed(3));
  }

  export function calculateTotalAppUserUsedEnergy(chargingData) {
    if (!Array.isArray(chargingData)) {
      return 0;
    }
    const totalCost = chargingData.reduce((sum, current) => {
      const cost = parseFloat(current.EnergyUsed) || 0;
      return sum + cost;
    }, 0);
    
    return parseFloat(totalCost.toFixed(3));
  }
  
  