import React, { useEffect, useState } from "react";
import { Link, NavLink, useLocation, useNavigate } from "react-router-dom";
import SpeedIcon from "@mui/icons-material/Speed";
import PersonOutlineOutlinedIcon from "@mui/icons-material/PersonOutlineOutlined";
import PeopleAltOutlinedIcon from "@mui/icons-material/PeopleAltOutlined";
import InsertDriveFileOutlinedIcon from "@mui/icons-material/InsertDriveFileOutlined";
import HomeOutlinedIcon from "@mui/icons-material/HomeOutlined";
import SettingsOutlinedIcon from "@mui/icons-material/SettingsOutlined";
import AddOutlinedIcon from "@mui/icons-material/AddOutlined";
import RemoveIcon from "@mui/icons-material/Remove";
import { HardwareOutlined } from "@mui/icons-material";
import MenuIcon from "@mui/icons-material/Menu";
import WidgetsOutlinedIcon from "@mui/icons-material/WidgetsOutlined";
import StorefrontOutlinedIcon from "@mui/icons-material/StorefrontOutlined";
import TurnedInNotOutlinedIcon from "@mui/icons-material/TurnedInNotOutlined";
import AssessmentIcon from "@mui/icons-material/Assessment";
import axios from "axios";
import cookie from "js-cookie";
import {
  HelpCenterOutlined,
  LocalShippingOutlined,
  SupportAgentOutlined,
} from "@mui/icons-material";
import { useSelector } from "react-redux";

function SideNavBar({
  expand,
  setExpand,
  activeTab,
  setsubMenu,
  subMenu,
  setActiveTab,
  setShowMenuHide,
  showMenuHide,
}) {
  const activeMenu = true;
  // const [subMenu, setSubMenu] = useState(false);
  console.log("activeTab", activeTab);
  console.log("expand", expand);
  const navigate = useNavigate();
  console.log(showMenuHide, "showmenuhideradhe");

  const LuserData = useSelector((state) => state.userManagement.getUserLogin);
  const pic = LuserData.pic_url;
  console.log(LuserData);

  const handleLogout = () => {
    try {
      localStorage.clear();
      navigate("/");
      window.location.reload();
      console.log("Logged Out sucessfully");
    } catch (error) {
      console.log(error);
      console.log("Not submitting data");
    }
  };

  const laocation = useLocation();

  useEffect(() => {
    setsubMenu(null);
  }, [laocation]);

  return (
    <>
      {!showMenuHide && (
        <div className="flex w-full items-center justify-between absolute h-28 md:h-16 bg-white px-2 z-20">
          <div className="flex items-center">
            {/* <MenuIcon color="success" sx={{ fontSize: 45 }} /> */}
            <img
              src="/images/Union.png"
              alt="Notification"
              className="h-8 ml-3"
              onClick={() => setShowMenuHide(true)}
            />
            <div className="text-lg md:text-xl lg:text-2xl m-2 font-semibold text-[#605C5C] capitalize">
              {/* {activeTab.replace(/([A-Z])/g, " $1").trim()} */}
              {activeTab.replace(/([A-Z])(?=[a-z])/g, " $1").trim()}
              <span className="text-[#605C1C] text-sm md:text-base">
                {subMenu}
              </span>
            </div>
          </div>
          <div className="absolute top-0 bottom-0 right-0 left-0 flex flex-col justify-center items-center  -z-10">
            <img
              className="object-contain w-56 -mt-8 hidden md:block"
              src="/images/logoj.png"
              alt="logo"
              srcSet=""
            />
            <p className="text-xs text-center -mt-10 hidden md:block">
              Energizing Greener Future
            </p>
          </div>
          <div className="py-2 pr-5">
            <Link
              to="/home/Profile"
              className="flex items-center justify-evenly cursor-pointer"
            >
              <div className="text-lg md:text-xl text-[#118615] font-bold">
                {LuserData.name}
              </div>
              <img
                className="h-11 w-11 ml-3 rounded-full"
                src={
                  pic === "undefined" || !pic
                    ? "https://placekitten.com/100/100"
                    : pic
                }
                alt="profile"
              />
              <div></div>
            </Link>
          </div>
        </div>
      )}

      <div
        className={`${
          showMenuHide
            ? "translate-x-0 fixed xl:static w-72 xl:w-96 duration-300 bg-[#C1E0C2] h-screen xl:h-auto overflow-auto z-20"
            : "-translate-x-96 fixed overflow-auto"
        } `}
      >
        <div className="flex items-center h-32 justify-center px-2 z-20 ">
          <div>
            <div className="flex px-2 py-1 bg-white shadow-lg w-40 h-16 mt-3 overflow-hidden rounded-xl items-center justify-center">
              <img
                className="object-contain "
                src="/images/logoj.png"
                alt="logo"
                srcSet=""
              />
            </div>

            {/* <p className="flex text-xs mt-3 justify-center">
              Energizing Greener Future
            </p> */}
          </div>
        </div>
        <div className="px-4 text:xs md:text-base">
          {activeMenu && (
            <>
              {/* <div className=" font-semibold mt-14">MENU</div> */}
              {/* Dashboard */}
              <div className="mt-10 text-gray-500 ">
                <NavLink
                  style={{
                    color: activeTab === "dashboard" ? "#fff" : "#6B6A6A",
                    fontWeight: activeTab === "dashboard" ? "bold" : "inherit",
                    backgroundColor:
                      activeTab === "dashboard" ? "green" : "inherit",
                    padding:
                      activeTab === "dashboard" ? "10px 0 10px 5px" : "inherit",
                    borderRadius: 15,
                  }}
                  activeclassname="active"
                  to="/home"
                  // className={`flex items-center  ${
                  //   activeTab === "dashboard" ? "  py-2" : "inharit"
                  // } `}
                  className="flex items-center"
                  onClick={() => {
                    setActiveTab("dashboard");
                    setExpand("dashboard");
                    setShowMenuHide(false);
                  }}
                >
                  <SpeedIcon style={{ transform: "scale(0.65)" }} />
                  <span className="pl-1">Dashboard</span>
                </NavLink>
              </div>

              {/* Live Product Management */}
              <div className="mt-4 text-gray-500">
                <NavLink
                  to="/home/productdashboard"
                  style={{
                    color: expand === "Livedashboard" ? "#fff" : "#6B6A6A", //"#171616" : "#545e6f",
                    fontWeight: expand === "Livedashboard" ? "bold" : "inherit", //"bold" : "inherit",
                    backgroundColor:
                      expand === "Livedashboard" ? "green" : "inherit",
                    padding: expand === "Livedashboard" ? "10px 0 10px 5px" : 0,
                    borderRadius: 15,
                  }}
                  onClick={() => {
                    if (expand === "Livedashboard") {
                      setExpand(null); // close if already open
                      // setShowMenuHide(false);
                    } else {
                      setExpand("Livedashboard"); // open if closed
                      // setActiveTab("userManagement");
                      // setShowMenuHide(false);
                    }
                  }}
                  activeclassname="active"
                  className="flex items-center justify-between"
                >
                  <div className="flex items-center">
                    <PersonOutlineOutlinedIcon
                      style={{ transform: "scale(0.65)" }}
                    />
                    <span className="pl-1">Live Products</span>
                  </div>

                  <div
                    style={{
                      transform: "scale(0.65)",
                      position: "relative",
                      // left: "86px",
                    }}
                  >
                    {expand === "Livedashboard" ? (
                      <RemoveIcon />
                    ) : (
                      <AddOutlinedIcon />
                    )}
                  </div>
                </NavLink>

                {expand === "Livedashboard" && (
                  <>
                    <div className="flex flex-col items-center text-sm">
                      <div className=" mt-2 ">
                        <NavLink
                          style={{
                            color:
                              activeTab === "productDashboard"
                                ? "black"
                                : "#545e6f",
                            fontWeight:
                              activeTab === "productDashboard"
                                ? "bold"
                                : "inherit",
                          }}
                          to="/home/productdashboard"
                          onClick={() => {
                            setActiveTab("product dashboard");
                            setExpand("Livedashboard");
                            setShowMenuHide(false);
                          }}
                        >
                          Product Dashboard
                        </NavLink>
                      </div>
                      <div className=" mt-2 ">
                        <NavLink
                          style={{
                            color:
                              activeTab === "productHistory"
                                ? "black"
                                : "#545e6f",
                            fontWeight:
                              activeTab === "productHistory"
                                ? "bold"
                                : "inherit",
                          }}
                          to="/home/producthistory"
                          onClick={() => {
                            setActiveTab("productHistory");
                            setExpand("Livedashboard");
                            setShowMenuHide(false);
                          }}
                        >
                          Product History
                        </NavLink>
                      </div>
                    </div>
                  </>
                )}
              </div>

              {/* Customer Relationship */}
              <div className="mt-4 text-gray-500 ">
                <NavLink
                  style={{
                    color:
                      expand === "customerRelationship" ? "#fff" : "#6B6A6A", //"#171616" : "#545e6f",
                    fontWeight:
                      expand === "customerRelationship" ? "bold" : "inherit",
                    backgroundColor:
                      expand === "customerRelationship" ? "green" : "inherit",
                    padding:
                      expand === "customerRelationship"
                        ? "10px 0 10px 5px"
                        : "inherit",
                    borderRadius: 15,
                  }}
                  // activeclassname="active"
                  to="/home/customerRelationship/page=0"
                  // onClick={() => {
                  //   setActiveTab("AppUsers");
                  //   setExpand("customerRelationship");
                  // }}
                  onClick={() => {
                    if (expand === "customerRelationship") {
                      setExpand(null);
                    } else {
                      setExpand("customerRelationship");
                      // setActiveTab("userManagement");
                    }
                  }}
                  className="flex items-center justify-between"
                >
                  <div className="flex items-center">
                    <PeopleAltOutlinedIcon
                      style={{ transform: "scale(0.65)" }}
                    />
                    <span className="pl-1">Customer Relationship</span>
                  </div>

                  <div
                    style={{
                      transform: "scale(0.65)",
                      position: "relative",
                      // left: "32px",
                    }}
                  >
                    {expand === "customerRelationship" ? (
                      <RemoveIcon />
                    ) : (
                      <AddOutlinedIcon />
                    )}
                  </div>
                </NavLink>
                {expand === "customerRelationship" && (
                  <>
                    <div className="flex flex-col items-center text-sm">
                      <div className=" mt-2">
                        <NavLink
                          style={{
                            color:
                              activeTab === "AppUsers" ? "black" : "#545e6f",
                            fontWeight:
                              activeTab === "AppUsers" ? "bold" : "inherit",
                          }}
                          // to="/home/customerRelationship"
                          to="/home/customerRelationship/page=0"
                          onClick={() => {
                            setActiveTab("AppUsers");
                            // setExpand("customerRelationship");
                            setShowMenuHide(false);
                          }}
                        >
                          App Users
                        </NavLink>
                      </div>
                      <div className=" mt-2">
                        <NavLink
                          style={{
                            color:
                              activeTab === "CMSUsers" ? "black" : "#545e6f",
                            fontWeight:
                              activeTab === "CMSUsers" ? "bold" : "inherit",
                          }}
                          to="/home/CMSUsers"
                          onClick={() => {
                            setActiveTab("CMSUsers");
                            // setExpand("customerRelationship");
                            setShowMenuHide(false);
                          }}
                        >
                          CMS Users
                        </NavLink>
                      </div>
                    </div>
                  </>
                )}
              </div>

              {/* cu */}
              <div className="mt-4 text-gray-500 ">
                <NavLink
                  style={{
                    color: activeTab === "orderManagement" ? "#fff" : "#6B6A6A", //"#171616" : "#545e6f",
                    fontWeight:
                      activeTab === "orderManagement" ? "bold" : "inherit",
                    backgroundColor:
                      activeTab === "orderManagement" ? "green" : "inherit",
                    padding:
                      activeTab === "orderManagement"
                        ? "10px 0 10px 5px"
                        : "inherit",
                    borderRadius: 15,
                  }}
                  activeclassname="active"
                  to="/home/transactionHistory"
                  className="flex items-center"
                  onClick={() => {
                    setActiveTab("orderManagement");
                    setShowMenuHide(false);
                  }}
                >
                  <LocalShippingOutlined style={{ transform: "scale(0.65)" }} />
                  <span className="pl-1">Order Management</span>
                </NavLink>
              </div>

              <div className="mt-4 text-gray-500 ">
                <NavLink
                  to="/home/productList"
                  style={{
                    color: expand === "homeService" ? "#fff" : "#6B6A6A", //"#171616" : "#545e6f",
                    fontWeight: expand === "homeService" ? "bold" : "inherit",
                    backgroundColor:
                      expand === "homeService" ? "green" : "inherit",
                    padding:
                      expand === "homeService" ? "10px 0 10px 5px" : "inherit",
                    borderRadius: 15,
                  }}
                  activeclassname="active"
                  onClick={() => {
                    // setExpand("homeService");
                    if (expand === "homeService") {
                      setExpand(null); // close if already open
                    } else {
                      setExpand("homeService"); // open if closed
                    }
                  }}
                  className="flex items-center justify-between"
                >
                  <div className="flex items-center">
                    <HomeOutlinedIcon style={{ transform: "scale(0.65)" }} />
                    <span className="pl-1">Inventory Management</span>
                  </div>

                  <div
                    style={{
                      transform: "scale(0.65)",
                      position: "relative",
                      left: "2px",
                    }}
                  >
                    {expand === "homeService" ? (
                      <RemoveIcon />
                    ) : (
                      <AddOutlinedIcon />
                    )}
                  </div>
                </NavLink>
                {expand === "homeService" && (
                  <>
                    <div className="flex flex-col items-center text-sm">
                      <div className="mt-2">
                        <NavLink
                          style={{
                            color:
                              activeTab === "productList" ? "black" : "#545e6f",
                            fontWeight:
                              activeTab === "productList" ? "bold" : "inherit",
                          }}
                          to="/home/productList"
                          onClick={() => {
                            setActiveTab("productList");
                            setExpand("homeService");
                            setShowMenuHide(false);
                          }}
                        >
                          Product List
                        </NavLink>
                      </div>

                      <div className="mt-2">
                        <NavLink
                          style={{
                            color:
                              activeTab === "promotionManagement"
                                ? "black"
                                : "#545e6f",
                            fontWeight:
                              activeTab === "promotionManagement"
                                ? "bold"
                                : "inherit",
                          }}
                          to="/home/promotionManagement"
                          onClick={() => {
                            setActiveTab("promotionManagement");
                            setExpand("homeService");
                            setShowMenuHide(false);
                          }}
                        >
                          Offers and Deals
                        </NavLink>
                      </div>

                      <div className="mt-2">
                        <NavLink
                          style={{
                            color:
                              activeTab === "categoryList"
                                ? "black"
                                : "#545e6f",
                            fontWeight:
                              activeTab === "categoryList" ? "bold" : "inherit",
                          }}
                          to="/home/categoryList"
                          onClick={() => {
                            setActiveTab("categoryList");
                            setExpand("homeService");
                            setShowMenuHide(false);
                          }}
                        >
                          Category List
                        </NavLink>
                      </div>
                      <div className="mt-2">
                        <NavLink
                          style={{
                            color:
                              activeTab === "productCategoryList"
                                ? "black"
                                : "#545e6f",
                            fontWeight:
                              activeTab === "productCategoryList"
                                ? "bold"
                                : "inherit",
                          }}
                          to="/home/productCategoryList"
                          onClick={() => {
                            setActiveTab("productCategoryList");
                            setExpand("homeService");
                            setShowMenuHide(false);
                          }}
                        >
                          Sub Category List
                        </NavLink>
                      </div>

                      <div className="mt-2">
                        <NavLink
                          style={{
                            color:
                              activeTab === "reviewManagement"
                                ? "black"
                                : "#545e6f",
                            fontWeight:
                              activeTab === "reviewManagement"
                                ? "bold"
                                : "inherit",
                          }}
                          to="/home/reviewManagement"
                          onClick={() => {
                            setActiveTab("reviewManagement");
                            setExpand("homeService");
                            setShowMenuHide(false);
                          }}
                        >
                          Review Management
                        </NavLink>
                      </div>
                    </div>
                  </>
                )}
              </div>

              {/* User Management */}
              <div className="mt-4 text-gray-500">
                <NavLink
                  to="/home/allUsers"
                  style={{
                    color: expand === "userManagement" ? "#fff" : "#6B6A6A", //"#171616" : "#545e6f",
                    fontWeight:
                      expand === "userManagement" ? "bold" : "inherit", //"bold" : "inherit",
                    backgroundColor:
                      expand === "userManagement" ? "green" : "inherit",
                    padding:
                      expand === "userManagement" ? "10px 0 10px 5px" : 0,
                    borderRadius: 15,
                  }}
                  onClick={() => {
                    if (expand === "userManagement") {
                      setExpand(null);
                    } else {
                      setExpand("userManagement");
                      // setActiveTab("userManagement");
                    }
                  }}
                  activeclassname="active"
                  className="flex items-center justify-between"
                >
                  <div className="flex items-center">
                    <PersonOutlineOutlinedIcon
                      style={{ transform: "scale(0.65)" }}
                    />
                    <span className="pl-1">User Management</span>
                  </div>
                  <div
                    style={{
                      transform: "scale(0.65)",
                      position: "relative",
                      // left: "42px",
                    }}
                  >
                    {expand === "userManagement" ? (
                      <RemoveIcon />
                    ) : (
                      <AddOutlinedIcon />
                    )}
                  </div>
                </NavLink>

                {expand === "userManagement" && (
                  <>
                    <div className="flex flex-col items-center text-sm">
                      <div className="mt-2  ">
                        <NavLink
                          style={{
                            color:
                              activeTab === "allUsers" ? "black" : "#545e6f",
                            fontWeight:
                              activeTab === "allUsers" ? "bold" : "inherit",
                          }}
                          to="/home/allUsers"
                          onClick={() => {
                            setActiveTab("allUsers");
                            setExpand("userManagement");
                            setShowMenuHide(false);
                          }}
                        >
                          All Users
                        </NavLink>
                      </div>
                      <div className="mt-2 ">
                        <NavLink
                          style={{
                            color:
                              activeTab === "suspendUsers"
                                ? "black"
                                : "#545e6f",
                            fontWeight:
                              activeTab === "suspendUsers" ? "bold" : "inherit",
                          }}
                          to="/home/suspendUsers"
                          onClick={() => {
                            setActiveTab("suspendUsers");
                            setExpand("userManagement");
                            setShowMenuHide(false);
                          }}
                        >
                          Suspend Users
                        </NavLink>
                      </div>
                      <div className="mt-2 ">
                        <NavLink
                          style={{
                            color:
                              activeTab === "department" ? "black" : "#545e6f",
                            fontWeight:
                              activeTab === "department" ? "bold" : "inherit",
                          }}
                          to="/home/department"
                          onClick={() => {
                            setActiveTab("department");
                            setExpand("userManagement");
                            setShowMenuHide(false);
                          }}
                        >
                          Department
                        </NavLink>
                      </div>
                      <div className="mt-2 ">
                        <NavLink
                          style={{
                            color:
                              activeTab === "permission" ? "black" : "#545e6f",
                            fontWeight:
                              activeTab === "permission" ? "bold" : "inherit",
                          }}
                          activeclassname="active"
                          to="/home/permission"
                          className="flex items-center"
                          onClick={() => {
                            setActiveTab("permission");
                            setExpand("userManagement");
                            setShowMenuHide(false);
                          }}
                        >
                          {/* <TurnedInNotOutlinedIcon style={{ transform: "scale(0.65)" }} /> */}
                          Permission and Role
                        </NavLink>
                      </div>
                    </div>
                  </>
                )}
              </div>

              <div className="mt-4 text-gray-500 ">
                <NavLink
                  style={{
                    color: activeTab === "helpDesk" ? "#fff" : "#6B6A6A", //"#171616" : "#545e6f",
                    fontWeight: activeTab === "helpDesk" ? "bold" : "inherit",
                    backgroundColor:
                      activeTab === "helpDesk" ? "green" : "inherit",
                    padding:
                      activeTab === "helpDesk" ? "10px 0 10px 5px" : "inherit",
                    borderRadius: 15,
                  }}
                  activeclassname="active"
                  to="/home/helpDesk"
                  className="flex items-center"
                  onClick={() => {
                    setActiveTab("helpDesk");
                    setShowMenuHide(false);
                  }}
                >
                  <SupportAgentOutlined style={{ transform: "scale(0.65)" }} />
                  <span className="pl-1">Support</span>
                </NavLink>
              </div>

              {/* Content Management */}
              <div className="mt-4 text-gray-500 ">
                <NavLink
                  to="/home/header"
                  style={{
                    color: expand === "contentManagement" ? "#fff" : "#6B6A6A", //"#171616" : "#545e6f",
                    fontWeight:
                      expand === "contentManagement" ? "bold" : "inherit",
                    backgroundColor:
                      expand === "contentManagement" ? "green" : "inherit",
                    padding:
                      expand === "contentManagement"
                        ? "10px 0 10px 5px"
                        : "inherit",
                    borderRadius: 15,
                  }}
                  onClick={() => {
                    if (expand === "contentManagement") {
                      setExpand(null); // close if already open
                    } else {
                      setExpand("contentManagement"); // open if closed
                    }
                  }}
                  activeclassname="active"
                  className="flex items-center justify-between"
                >
                  <div className="flex items-center">
                    <InsertDriveFileOutlinedIcon
                      style={{ transform: "scale(0.65)" }}
                    />
                    <span className="pl-1">Content Management</span>
                  </div>

                  <div
                    style={{
                      transform: "scale(0.65)",
                      position: "relative",
                      // left: "10px",
                    }}
                  >
                    {expand === "contentManagement" ? (
                      <RemoveIcon />
                    ) : (
                      <AddOutlinedIcon />
                    )}
                  </div>
                </NavLink>

                {expand === "contentManagement" && (
                  <>
                    <div className="flex flex-col items-center text-sm">
                      <div className=" mt-2">
                        <NavLink
                          style={{
                            color: activeTab === "header" ? "black" : "#545e6f",
                            fontWeight:
                              activeTab === "header" ? "bold" : "inherit",
                          }}
                          to="/home/header"
                          onClick={() => {
                            setActiveTab("header");
                            setExpand("contentManagement");
                            setShowMenuHide(false);
                          }}
                        >
                          Header
                        </NavLink>
                      </div>
                      <div className=" mt-2">
                        <NavLink
                          style={{
                            color: activeTab === "footer" ? "black" : "#545e6f",
                            fontWeight:
                              activeTab === "footer" ? "bold" : "inherit",
                          }}
                          to="/home/footer"
                          onClick={() => {
                            setActiveTab("footer");
                            setExpand("contentManagement");
                            setShowMenuHide(false);
                          }}
                        >
                          Footer
                        </NavLink>
                      </div>
                      <div className=" mt-2">
                        <NavLink
                          style={{
                            color:
                              activeTab === "catalogue" ? "black" : "#545e6f",
                            fontWeight:
                              activeTab === "catalogue" ? "bold" : "inherit",
                          }}
                          to="/home/catalogue"
                          onClick={() => {
                            setActiveTab("catalogue");
                            setExpand("contentManagement");
                            setShowMenuHide(false);
                          }}
                        >
                          Catalog
                        </NavLink>
                      </div>
                      <div className=" mt-2">
                        <NavLink
                          style={{
                            color: activeTab === "pages" ? "black" : "#545e6f",
                            fontWeight:
                              activeTab === "pages" ? "bold" : "inherit",
                          }}
                          to="/home/pages"
                          onClick={() => {
                            setActiveTab("pages");
                            setExpand("contentManagement");
                            setShowMenuHide(false);
                          }}
                        >
                          Pages
                        </NavLink>
                      </div>
                      <div className=" mt-2">
                        <NavLink
                          style={{
                            color:
                              activeTab === "promocard" ? "black" : "#545e6f",
                            fontWeight:
                              activeTab === "promocard" ? "bold" : "inherit",
                          }}
                          to="/home/promocard"
                          onClick={() => {
                            setActiveTab("promocard");
                            setExpand("contentManagement");
                            setShowMenuHide(false);
                          }}
                        >
                          Promo Card
                        </NavLink>
                      </div>
                      <div className=" mt-2">
                        <NavLink
                          style={{
                            color:
                              activeTab === "generalConfig"
                                ? "black"
                                : "#545e6f",
                            fontWeight:
                              activeTab === "generalConfig"
                                ? "bold"
                                : "inherit",
                          }}
                          to="/home/generalConfig"
                          onClick={() => {
                            setActiveTab("generalConfig");
                            setExpand("contentManagement");
                            setShowMenuHide(false);
                          }}
                        >
                          General Configurations
                        </NavLink>
                      </div>
                    </div>
                  </>
                )}
              </div>

              <div className="mt-4 text-gray-500 ">
                <NavLink
                  to="/home/visitors"
                  style={{
                    color: expand === "reports" ? "#fff" : "#6B6A6A", //"#171616" : "#545e6f",
                    fontWeight: expand === "reports" ? "bold" : "inherit",
                    backgroundColor: expand === "reports" ? "green" : "inherit",
                    padding:
                      expand === "reports" ? "10px 0 10px 5px" : "inherit",
                    borderRadius: 15,
                  }}
                  activeclassname="active"
                  onClick={() => {
                    // setExpand("homeService");
                    if (expand === "reports") {
                      setExpand(null); // close if already open
                    } else {
                      setExpand("reports"); // open if closed
                    }
                  }}
                  className="flex items-center justify-between"
                >
                  <div className="flex items-center">
                    <AssessmentIcon style={{ transform: "scale(0.65)" }} />
                    <span className="pl-1">Reports</span>
                  </div>

                  <div
                    style={{
                      transform: "scale(0.65)",
                      position: "relative",
                      // left: "128px",
                    }}
                  >
                    {expand === "reports" ? (
                      <RemoveIcon />
                    ) : (
                      <AddOutlinedIcon />
                    )}
                  </div>
                </NavLink>
                {expand === "reports" && (
                  <>
                    <div className="flex flex-col items-center text-sm">
                      <div className="mt-2">
                        <NavLink
                          style={{
                            color:
                              activeTab === "visitors" ? "black" : "#545e6f",
                            fontWeight:
                              activeTab === "visitors" ? "bold" : "inherit",
                          }}
                          to="/home/visitors"
                          onClick={() => {
                            setActiveTab("visitors");
                            setExpand("reports");
                            setShowMenuHide(false);
                          }}
                        >
                          Visitors
                        </NavLink>
                      </div>

                      <div className="mt-2">
                        <NavLink
                          style={{
                            color: activeTab === "sales" ? "black" : "#545e6f",
                            fontWeight:
                              activeTab === "sales" ? "bold" : "inherit",
                          }}
                          to="/home/sales"
                          onClick={() => {
                            setActiveTab("sales");
                            setExpand("reports");
                            setShowMenuHide(false);
                          }}
                        >
                          Sales
                        </NavLink>
                      </div>

                      <div className="mt-2">
                        <NavLink
                          style={{
                            color:
                              activeTab === "productPerformace"
                                ? "black"
                                : "#545e6f",
                            fontWeight:
                              activeTab === "productPerformace"
                                ? "bold"
                                : "inherit",
                          }}
                          to="/home/ProPerformance"
                          onClick={() => {
                            setActiveTab("productPerformace");
                            setExpand("reports");
                            setShowMenuHide(false);
                          }}
                        >
                          Products Performance
                        </NavLink>
                      </div>
                      <div className="mt-2">
                        <NavLink
                          style={{
                            color:
                              activeTab === "activityLogs"
                                ? "black"
                                : "#545e6f",
                            fontWeight:
                              activeTab === "activityLogs" ? "bold" : "inherit",
                          }}
                          to="/home/ActivityLogs"
                          onClick={() => {
                            setActiveTab("activityLogs");
                            setExpand("reports");
                            setShowMenuHide(false);
                          }}
                        >
                          Activity logs
                        </NavLink>
                      </div>
                    </div>
                  </>
                )}
              </div>

              {/* hardware */}
              <div className="mt-4 text-gray-500 ">
                <NavLink
                  style={{
                    color:
                      activeTab === "hardwareManagement" ? "#fff" : "#6B6A6A", //"#171616" : "#545e6f",
                    fontWeight:
                      activeTab === "hardwareManagement" ? "bold" : "inherit",
                    backgroundColor:
                      activeTab === "hardwareManagement" ? "green" : "inherit",
                    padding:
                      activeTab === "hardwareManagement"
                        ? "10px 0 10px 5px"
                        : "inherit",
                    borderRadius: 15,
                  }}
                  activeclassname="active"
                  to="/home/hardware"
                  className="flex items-center"
                  onClick={() => {
                    setActiveTab("hardwareManagement");
                    setExpand("hardwareManagement");
                    setShowMenuHide(false);
                  }}
                >
                  <HardwareOutlined style={{ transform: "scale(0.65)" }} />
                  <span className="pl-1">Hardware Management</span>
                </NavLink>
              </div>

              {/* Settings */}
              <div className="mt-4 text-gray-500 ">
                <NavLink
                  style={{
                    color: activeTab === "settings" ? "#fff" : "#6B6A6A", //"#171616" : "#545e6f",
                    fontWeight: activeTab === "settings" ? "bold" : "inherit",
                    backgroundColor:
                      activeTab === "settings" ? "green" : "inherit",
                    padding:
                      activeTab === "settings" ? "10px 0 10px 5px" : "inherit",
                    borderRadius: 15,
                  }}
                  activeclassname="active"
                  to="/home/settings"
                  className="flex items-center"
                  onClick={() => {
                    setActiveTab("settings");
                    setExpand("settings");
                    setShowMenuHide(false);
                  }}
                >
                  <SettingsOutlinedIcon style={{ transform: "scale(0.65)" }} />
                  <span className="pl-1">Settings</span>
                </NavLink>
              </div>

              <div
                className="flex justify-center mb-10"
                style={{ marginTop: "80px" }}
              >
                <button onClick={handleLogout}>
                  <img src="/images/logout.png" alt="logout" srcSet="" />
                </button>
              </div>
            </>
          )}
        </div>
      </div>
    </>
  );
}

export default SideNavBar;
