import React, { useEffect, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { HSM_category, ProductCategories } from "../../User_Management/features/userSlice";

const getUniqueSubCategories = (subCategories) => {
  const uniqueAdditionalFields = new Set();
  const uniqueSubCategories = [];

  subCategories.forEach((item) => {
    if (!uniqueAdditionalFields.has(item.additionalField)) {
      uniqueAdditionalFields.add(item.additionalField);
      uniqueSubCategories.push(item.additionalField);
    }
  });

  return uniqueSubCategories;
};
const getUniqueProductName = (AllProduct, category, subCategory) => {
  if (!AllProduct || !category || !subCategory) return;

  const uniqueSubCategories = [];
  const uniqueAdditionalFields = new Set();

  const lowerCategory = category.toLowerCase();
  const lowerSubCategory = subCategory.toLowerCase();

  AllProduct.forEach(({ product_name, category, sub_category }) => {
    if (
      !uniqueAdditionalFields.has(product_name) &&
      category.toLowerCase() === lowerCategory &&
      sub_category.toLowerCase() === lowerSubCategory
    ) {
      uniqueAdditionalFields.add(product_name);
      uniqueSubCategories.push(product_name);
    }
  });

  return uniqueSubCategories;
};



const AddChargerMadal = ({ closeModal,handleSubmitCallback }) => {
  const dispatch = useDispatch();
  const [selectedOption, setSelectedOption] = useState("");
  const [inputValue, setInputValue] = useState("");
  const [inputValues,setInputValues]=useState({
    category:"",
    subcategory:"",
    chargername:"",
    pid:""
  })
  const modalRef = useRef(null);
  useEffect(() => {
    const fetchUserData = async () => {
      await dispatch(ProductCategories());
      await dispatch(HSM_category());
    };
    fetchUserData();
  }, [dispatch]);
  

  useEffect(() => {
    const handleOutsideClick = (event) => {
      if (modalRef.current && !modalRef.current.contains(event.target)) {
        closeModal();
      }
    };

    document.addEventListener("mousedown", handleOutsideClick);

    return () => {
      document.removeEventListener("mousedown", handleOutsideClick);
    };
  }, [closeModal]);

  const CategoryData = useSelector(
    (state) => state.userManagement.hsm_category
  );
  const SubCategoryData = useSelector(
    (state) => state.userManagement.ProductCategories
  );
  const AllProductData = useSelector(
    (state) => state?.userManagement?.hsm_allproducts
  );
  const uniqueSubCategories = getUniqueSubCategories(SubCategoryData);

  const uniqueProductName=getUniqueProductName(AllProductData,inputValues.category,inputValues.subcategory)
  console.log("CategoryData",CategoryData,uniqueSubCategories,AllProductData,uniqueProductName);
  
  const handleDropdownChange = (e) => {
    const { name, value } = e.target;
    setInputValues((prevState) => ({
      ...prevState,
      [name]: value
    }));
  };

  const handleInputChange = (event) => {
    setInputValue(event.target.value);
  };

  const handleSubmit = (event) => {
    event.preventDefault();
    console.log("Selected Option:", selectedOption);
    console.log("Input Value:", inputValue);
    if(!inputValues.category || !inputValues.subcategory || !inputValues.chargername || !inputValues.pid){
      console.log("please select field");
      return
    }
    handleSubmitCallback(inputValues.category,inputValues.subcategory,inputValues.chargername,inputValues.pid)
    closeModal();
  };

  return (
    <div className="fixed inset-0 flex items-center justify-center bg-gray-500 bg-opacity-75 z-20">
      <div ref={modalRef} className="bg-white p-8 w-96 rounded-lg shadow-lg">
        <h2 className="text-lg font-bold mb-4">Add Charger</h2>
        <form onSubmit={handleSubmit}>
          <div className="mb-4">
            <label
              htmlFor="charger-type"
              className="block text-sm font-medium text-gray-700"
            >
              Charger Category:
            </label>
            <select
              id="charger-type"
              required
              aria-label="Charger Type (required)"
              className="mt-1 block w-full focus:border-green-600 focus:ring focus:ring-green-600 rounded-md shadow-sm focus:outline-none"
              value={inputValues.category}
              onChange={handleDropdownChange}
              name="category"
            >
              <option value="">Select  Category</option>
             {Array.isArray(CategoryData) && CategoryData.length > 0 ? (
                          CategoryData.map((items, index) => (
                             <option key={index} value={items.categoryName}>
                                     {items.categoryName}
                                </option>
                              ))
                            ) : (
                            <option value="">No categories available</option>
                            )} 
            </select>
          </div>
          <div className="mb-4">
            <label
              htmlFor="charger-type"
              className="block text-sm font-medium text-gray-700"
            >
              Charger SubCategory:
            </label>
            <select
              id="charger-type"
              required
              aria-label="Charger Type (required)"
              className="mt-1 block w-full focus:border-green-600 focus:ring focus:ring-green-600 rounded-md shadow-sm focus:outline-none"
              value={inputValues.subcategory}
              onChange={handleDropdownChange}
              name="subcategory"
            >
              <option value="">Select Sub Category</option>
              {Array.isArray(uniqueSubCategories) && uniqueSubCategories.length > 0 ? (
                          uniqueSubCategories.map((item, index) => (
                          <option key={index} value={item}>
                               {item}
                               </option>
                                          ))
                                          ) : (
                              <option value="">No subcategory available</option>
                                )}
            </select>
          </div>
          <div className="mb-4">
            <label
              htmlFor="charger-type"
              className="block text-sm font-medium text-gray-700"
            >
              Charger Name:
            </label>
            <select
              id="charger-type"
              required
              aria-label="Charger Type (required)"
              className="mt-1 block w-full focus:border-green-600 focus:ring focus:ring-green-600 rounded-md shadow-sm focus:outline-none"
              value={inputValues.chargername}
              onChange={handleDropdownChange}
              name="chargername"
            >
              <option value="">Select Charger Name</option>
              {Array.isArray(uniqueProductName) && uniqueProductName.length > 0 ? (
                          uniqueProductName.map((item, index) => (
                          <option key={index} value={item}>
                               {item}
                               </option>
                                          ))
                                          ) : (
                              <option value="">No subcategory available</option>
                                )}
            </select>
          </div>
          <div className="mb-4">
            <label
              htmlFor="charger-name"
              className="block text-sm font-medium text-gray-700"
            >
              Charger Id:
            </label>
            <input
              type="text"
              required
              id="charger-name"
              aria-label="Charger Name (required)"
              className="mt-1 block w-full border border-gray-500 focus:border-green-600 focus:ring focus:ring-green-600 focus:outline-none rounded-md shadow-sm"
              value={inputValues.pid}
              onChange={handleDropdownChange}
              name="pid"
            />
          </div>
          <div className="flex justify-end">
            <button
              type="button"
              className="mr-2 bg-gray-300 text-gray-700 px-4 py-2 rounded-md hover:bg-gray-400"
              onClick={closeModal}
            >
              Cancel
            </button>
            <button
              type="submit"
              className="bg-[#118615] text-white px-4 py-2 rounded-md hover:bg-green-600"
            >
              Add Charger
            </button>
          </div>
        </form>
      </div>
    </div>
  );
};

export default AddChargerMadal;
