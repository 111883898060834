const url1 = "https://apis.devcorps.in"; //5550
const url2 = "https://apis.devcorps.in"; //5002
const url3 = "https://apis.devcorps.in"; //5552
const url4 = "https://apis2.devcorps.in"; //8080

// const tssurl = "https://tssapis.devcorps.in/admin";
const tssurl1 = "https://tssapis.devcorps.in/client";
// const tssurl = "http://localhost:5200/admin";
// const tssurl = "http://165.22.223.26:5000/admin";
// const tssurl = "https://backend.jouls.co.in/admin";
const tssurl = "https://344r3mo7vl.execute-api.us-east-1.amazonaws.com/dev/admin";


export { url1, url2, url3, url4 , tssurl };