import React from "react";
import { Snackbar, IconButton } from "@mui/material";
import { Close } from "@mui/icons-material";

const SuccessToast = ({ message, open, onClose }) => {
  return (
    <Snackbar
      open={open}
      autoHideDuration={1000}
      onClose={onClose}
      message={message}
      variant="trouble"
      anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
      className="success-toast" // Apply CSS class
      action={
        <IconButton size="small" aria-label="close" onClick={onClose}>
          <Close fontSize="small" />
        </IconButton>
      }
    />
  );
};

export default SuccessToast;
